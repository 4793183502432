/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import {
  Avatar, Box, Typography, Grid
} from '@material-ui/core';
import Tab from './Tab';

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 240,
  },
  avatar: {
    height: 64,
    width: 64
  },
  urole: {
    fontSize: '0.75rem',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: 2.5,
    textTransform: 'uppercase',
    fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
    color: 'rgb(101, 116, 139)',
  },
  uname: {
    fontWeight: 600,
    fontSize: '1.0625rem',
    lineHeight: 1.375,
    fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  },
  actionButton: {
    margin: 2,
  }
});

export default function MediaCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
        <CardMedia
            className={classes.media}
            image="https://images.pexels.com/photos/573130/pexels-photo-573130.jpeg?cs=srgb&dl=pexels-zulian-yuliansyah-573130.jpg&fm=jpg"
            title="Contemplative Reptile"
        />
        <CardContent className={classes.cardcontent}>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
          >
            <Grid
              className={classes.statsItem}
              item
            >
              <Box
                alignItems="left"
                display="flex"
                flexDirection="row"
              >
                <Avatar
                  className={classes.avatar}
                  id="profile_pic"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuQVDZzmYwKrMZsWyCUCfRqP-QK7vHJqTl0gCCqkuu0g&s"
                  alt="profile picture"
                />
                <Box>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    className={classes.urole}
                  >
                    Advisor in BMS Global
                  </Typography>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    className={classes.uname}
                  >
                    Vaibhav Bharti
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              className={classes.statsItem}
              item
            >
              <Box
                alignItems="right"
                display="flex"
                flexDirection="row"
              >
                <Button variant="outlined" size="small" color="primary" className={classes.actionButton}>
                  Connect
                </Button>
                <Button variant="contained" size="small" color="primary" className={classes.actionButton}>
                  Send Message
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Tab />
    </Card>
  );
}
