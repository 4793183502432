/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import {
  Grid, TextField, Button, MenuItem
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const ParticipantFilter = ({ onFilter, filters }) => {
  const [dateFilter, setDateFilter] = useState(filters.date);
  const [participantType, setParticipantType] = useState(filters.participantType);

  const handleDateChange = (date) => {
    setDateFilter(date);
  };

  const handleParticipantTypeChange = (event) => {
    setParticipantType(event.target.value);
  };

  const handleFilterClick = () => {
    // Pass the filter criteria to the parent component
    onFilter({ dateFilter, participantType });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} xl={3} lg={2}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Select Date"
            format="dd MMM, yyyy"
            value={dateFilter}
            onChange={handleDateChange}
            maxDate={new Date()} // This line restricts future dates
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            style={{ fontSize: 12 }}
            InputProps={{ style: { fontSize: 12 } }} // Apply font size to input field
          />
        </Grid>
        <Grid item xs={4} xl={3} lg={2}>
          <TextField
            fullWidth
            select
            label="Participant"
            value={participantType}
            onChange={handleParticipantTypeChange}
            style={{ fontSize: 12 }}
          >
            <MenuItem value="my">My Candidates</MenuItem>
            <MenuItem value="all">All Candidates</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <Button size="small" variant="contained" color="primary" onClick={handleFilterClick}>
            Search
          </Button>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>

  );
};

export default ParticipantFilter;
