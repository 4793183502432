import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import {
  Box,
  Button,
  // Typography,
  // Card,
  // CardContent,
  makeStyles
} from '@material-ui/core';
import Create from './Create';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
    fontSize: '12px'
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, groupId, ...rest }) => {
  const classes = useStyles();
  const [copySuccess, setCopySuccess] = useState('');

  async function handleJoiningLinkCopy(gid) {
    navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/groups/join/${gid}`);
    setCopySuccess(' Copied!');
  }
  // const loggidinUser = inLocalStorageJwt.getLoggedinUserData();
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        {/* <Button
          className={classes.importButton}
          variant="contained"
          color="primary"
        >
          E-WALLET
        </Button> */}
        {/* <Button className={classes.exportButton}>
          Export
        </Button> */}
        <Button
          className={classes.importButton}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => handleJoiningLinkCopy(groupId)}
        >
          Copy Joining Link
        </Button>
        {copySuccess}
        <Create groupId={groupId} />
      </Box>
      {/* <Box mt={3}>
        <Card>
          <CardContent>
            <Typography
              variant="h1"
              component="h2"
            >
              Share and earn upto 50,000 INR.
            </Typography>
            <Typography
              variant="h5"
              component="h5"
            >
              {`Your referral code / BMS sponsor code : ${loggidinUser.email}`}
            </Typography>
          </CardContent>
        </Card>
      </Box> */}
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  groupId: PropTypes.string,
  fetchGroups: PropTypes.func
};

export default Toolbar;
