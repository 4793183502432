import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import SchoolIcon from '@material-ui/icons/School';
import HomeIcon from '@material-ui/icons/Home';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    marginTop: '20px'
  },
  jobtitle: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    color: 'black',
  },
  oldjobtitle: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    color: 'rgb(101, 116, 139)',
  },
  icons: {
    color: 'rgb(107, 114, 128)'
  },
  containerG: {
    marginTop: '10px'
  }
}));

export default function AutoGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <LocalMallIcon className={classes.icons} />
        </Grid>
        <Grid item xs={10}>
          <Typography className={classes.jobtitle} color="textSecondary" gutterBottom>
            Product Designer at Devias IO
          </Typography>
          <Typography className={classes.oldjobtitle} color="textSecondary" gutterBottom>Past:UX Designer Focus Aesthetic Dynamics</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} className={classes.containerG}>
        <Grid item xs={2}>
          <SchoolIcon className={classes.icons} />
        </Grid>
        <Grid item xs={10}>
          <Typography className={classes.jobtitle} color="textSecondary" gutterBottom>
            BCA in DAV college Ambala City
          </Typography>
          <Typography className={classes.oldjobtitle} color="textSecondary" gutterBottom>
            10+2 in MP Inter College GKP
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} className={classes.containerG}>
        <Grid item xs={2}>
          <HomeIcon className={classes.icons} />
        </Grid>
        <Grid item xs={10}>
          <Typography className={classes.jobtitle} color="textSecondary" gutterBottom>
            Lives in Chandigarh
          </Typography>
          <Typography className={classes.oldjobtitle} color="textSecondary" gutterBottom>
            Originally from Rm. Valcea
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} className={classes.containerG}>
        <Grid item xs={2}>
          <EmailIcon className={classes.icons} />
        </Grid>
        <Grid item xs={10}>
          <Typography className={classes.jobtitle} color="textSecondary" gutterBottom>
            vaibhavbharti39@gmail.com
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
