import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, Select, MenuItem, Button, Grid
} from '@material-ui/core';

const MeetingFilter = ({ onSearch, filters, setFilters }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = () => {
    // Call API with filters
    onSearch(filters);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <InputLabel>Type</InputLabel>
          <Select
            value={filters.type}
            onChange={handleChange}
            name="type"
          >
            <MenuItem value="recruitment">Recruitment</MenuItem>
            <MenuItem value="retention">Retention</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <InputLabel>Mode</InputLabel>
          <Select
            value={filters.mode}
            onChange={handleChange}
            name="mode"
          >
            <MenuItem value="online">Online</MenuItem>
            <MenuItem value="offline">Offline</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <InputLabel>Frequency</InputLabel>
          <Select
            value={filters.frequency}
            onChange={handleChange}
            name="frequency"
          >
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button variant="contained" style={{ fontSize: '12px' }} size="small" color="primary" onClick={handleSearch} fullWidth>
          Search Meetings
        </Button>
      </Grid>
    </Grid>
  );
};

MeetingFilter.propTypes = {
  onSearch: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default MeetingFilter;
