import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import moment from 'moment';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import axios from 'axios';
import { toast } from 'react-toastify';
// import placeholder from '../src/static/images/avatars/avatar_6.png';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import UserContext from 'src/contexts/UserContext';

const placeholder = '/static/images/avatars/user-logo-avtar.png';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  hideInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    opacity: '0'
  }
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const loggidinUser = inLocalStorageJwt.getLoggedinUserData();
  const [isDpSet, SetIsDpSet] = useState(!!loggidinUser.profile_pic);
  const [{ alt, src }, setImg] = useState({
    src: loggidinUser.profile_pic
      ? (loggidinUser.profile_pic) : placeholder,
    alt: 'Upload an Image'
  });
  const user = {
    avatar: src,
    country: 'USA',
    jobTitle: loggidinUser.level,
    name: loggidinUser.name,
  };
  useEffect(() => {
    user.avatar = src;
  }, [src]); // Or [] if effect doesn't need props or state
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  // const loggidinUser = inLocalStorageJwt.getLoggedinUserData();
  const AuthToken = AuthTokenObject.authToken;
  function onProfileChange(event) {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
    // console.log(event);
    if (event.target.files[0]) {
      const formData = new FormData();
      setImg({
        src: URL.createObjectURL(event.target.files[0]),
        alt: event.target.files[0].name
      });
      // formData.append('file', event.target.files[0]);
      // const postData = {
      //   'profile_pic' : event.target.files[0]
      // }
      formData.append('profile_pic', event.target.files[0]);
      axios.patch(`${process.env.REACT_APP_API_BASE_URL}/users/updateDp/${loggidinUser.id}`, formData, options)
        .then(async (res) => {
          // setReportList(res.data.dsreports);
          // console.log(res.data.user);
          inLocalStorageJwt.setLoggedinUserData(res.data.user);
          userContext.userData = res.data.user;
          SetIsDpSet(true);
          loggidinUser.profile_pic = res.data.user.profile_pic;
          toast.success(`${res.data.message}`, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // navigate('/app/dailyReports', { replace: true });
        })
        .catch((error) => {
          console.log(error);
          // setSubmitting(false);
          if (!error.response) {
            console.log(error);
            return;
          }
          toast.error(`${error.response.data.message}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }

  function removeProfilePic() {
    // console.log(userId);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const options = {
              headers: {
                'Content-Type': 'Application/json',
                Authorization: `Bearer ${AuthToken}`,
              }
            };
            axios.delete(`${process.env.REACT_APP_API_BASE_URL}/users/removedp/${loggidinUser.id}`, options)
              .then(async () => {
                // console.log(userContext.userData);
                SetIsDpSet(false);
                userContext.userData.profile_pic = null;
                inLocalStorageJwt.setLoggedinUserData(userContext.userData);
                setImg({
                  src: placeholder,
                  alt: 'avtar'
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            id="profile_pic"
            src={user.avatar}
            alt={alt}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.name}
          </Typography>
          {/* <Typography
            color="textSecondary"
            variant="body1"
          >
            {`${user && user.address && user.address[0].city} ${user}`}
          </Typography> */}
          {/* <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')} ${user.timezone}`}
          </Typography> */}
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          fullWidth
          variant="text"
        >
          <input
            id="imageButton"
            accept=".png, .jpg, .jpeg"
            className={classes.hideInput}
            type="file"
            onChange={(e) => { onProfileChange(e); }}
          />
          Upload picture
        </Button>
      </CardActions>
      <Divider />
      {
        isDpSet && (
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={(e) => { removeProfilePic(e); }}
          >
            Remove picture
          </Button>
        </CardActions>
        )
      }
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
