/* eslint-disable */
import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import AppUserListView from 'src/views/AppUsers/AppUserListView/';

import Blogs from 'src/views/Blogs/';
import Coupons from 'src/views/Coupons/';
import BlogForm from 'src/views/Blogs/BlogForm';

import CustomerCreateView from 'src/views/customer/CustomerCreate/index';
import CustomerProfileView from 'src/views/customer/CustomerListView/Profile';
import MyProfileView from 'src/views/account/MyProfile/index';
import MeetingRoom from 'src/components/Calling/Calling';
import DailyReportsListView from 'src/views/DailyReports/DsrListView';
import DailyReportCreate from 'src/views/DailyReports/DsrCreate';
import DailyReportUpdate from 'src/views/DailyReports/DsrUpdate';

import TaskListView from 'src/views/task/TaskListView';
import TaskCreate from 'src/views/task/TaskCreate';
import TaskDetails from 'src/views/task/TaskListView/TaskDetails';
import ViewerDetails from 'src/views/trainings/TrainingDetailsView/ViewerDetails';

import PublicView from 'src/views/trainings/PublicView/';

import Terms from 'src/views/StaticPages/Terms';
import PrivacyPolicy from 'src/views/StaticPages/PrivacyPolicy';
import SubscriptionPolicy from 'src/views/StaticPages/SubscriptionPolicy';
import Solutions from 'src/views/StaticPages/Solutions';

import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import LogOutComp from 'src/views/auth/LogOutComp';

import Lms from 'src/views/Lms';

import NotFoundView from 'src/views/errors/NotFoundView';
import Trainings from 'src/views/trainings/TrainingListView/index';
import Categories from 'src/views/categories/CategoryListView/index';
import TrainingCreate from 'src/views/trainings/TrainingCreate/';
import TrainingDetails from 'src/views/trainings/TrainingDetailsView/';
import TrainingPublicView from 'src/views/trainings/TrainingDetailsView/TrainingPublicView2';

import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import ForgotPwdView from 'src/views/auth/ForgotPwdView';
import ResetPwdView from 'src/views/auth/ResetPwdView';
import ForgotPasswordSuccess from 'src/views/auth/ForgotPasswordSuccess';

import VerifyemailPhoneView from 'src/views/auth/VerifyemailPhoneView';
import VerifyPhoneView from 'src/views/auth/VerifyPhoneView';

import SettingsView from 'src/views/settings/SettingsView';
import UserNameIndex from 'src/views/settings/SettingsView/UserName/Index';

import LandingPage from 'src/views/LandingPage/LandingPage';
import Pricing from 'src/views/Pricing/Index';
import Internship from 'src/views/Internship/Index';
import InternshipDetails from 'src/views/Internship/InternshipDetails/Index';

import Faq from 'src/views/faq/Index';
import Testimonies from 'src/views/Testimonies/Index';

import ApplicationForm from 'src/views/workApplication/ApplicationForm';
import PaymentForm from 'src/views/Payment/PaymentForm';
import QuickPaymentForm from 'src/views/Payment/Quick/PaymentForm';
import Investment from 'src/views/Investment/Quick/PaymentForm';

import ApplicationList from 'src/views/workApplication/List';

import BookListView from 'src/views/Books/BookListView';
import SocialMedia from 'src/views/SocialMedia/profile/index';
import Share from 'src/views/Share';
import Meetings from 'src/views/Meetings';
import MeetingDetails from 'src/views/Groups/Offices/Meetings/MeetingDetails';

import MeetingPass from 'src/views/MeetingPass';
import Groups from 'src/views/Groups';
import JoinGroupRequest from 'src/views/Groups/Members/JoinRequest';
import GroupOffice from 'src/views/Groups/Offices/index';
import OfficeDetails from 'src/views/Groups/Offices/OfficeDetails';
import AdminOfficeView from 'src/views/Groups/Admin/OfficeView';

import OfflineWorking from 'src/views/Groups/Offices/OfflineWorking';

import Profiles from 'src/views/Profiles';
import GroupMembers from 'src/views/Groups/Members/GroupMembers';

// import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';

// const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
const adminRole = ['admin'];
const SuperRoles = ['paid_user', 'admin', 'bms_promotor'];
const LearnersRole = ['learner'];
const dsrPermissions = localStorage.getItem('dsr_permissions')? JSON.parse(localStorage.getItem('dsr_permissions')):{};
const routes = (isLoggedIn, userData) => [
  {
    path: 'app',
    element: isLoggedIn && userData.is_verified ? <DashboardLayout /> : (isLoggedIn && !userData.is_verified ? <Navigate to="/auth/verify-phone" /> : <Navigate to="/login" />),
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'blogs', element: SuperRoles.indexOf(userData.role) > -1 ? <Blogs /> : <Navigate to="/404" /> },
      { path: 'coupons', element: adminRole.indexOf(userData.role) > -1 ? <Coupons /> : <Navigate to="/404" /> },
      { path: 'blog/create', element: SuperRoles.indexOf(userData.role) > -1 ? <BlogForm /> : <Navigate to="/404" /> },
      { path: 'customers', element: SuperRoles.indexOf(userData.role) > -1 ? <CustomerListView /> : <Navigate to="/404" /> },
      { path: 'app-users', element: (userData.role === 'admin') ? <AppUserListView /> : <Navigate to="/404" /> },
      { path: 'customer/create', element: SuperRoles.indexOf(userData.role) > -1 ? <CustomerCreateView /> : <Navigate to="/404" /> },
      { path: 'customer/view/:uid', element: SuperRoles.indexOf(userData.role) > -1 ? <CustomerProfileView /> : <Navigate to="/404" /> },
      { path: 'my-profile/view/', element: SuperRoles.indexOf(userData.role) > -1 || userData.role === 'user' ? <MyProfileView /> : <Navigate to="/404" /> },
      { path: 'dailyReports', element: SuperRoles.indexOf(userData.role) > -1 || (Object.prototype.hasOwnProperty.call(dsrPermissions, 'read')  && dsrPermissions.read === true) ? <DailyReportsListView /> : <Navigate to="/404" /> },
      { path: 'dailyReport/create', element: SuperRoles.indexOf(userData.role) > -1 || (Object.prototype.hasOwnProperty.call(dsrPermissions, 'create')  && dsrPermissions.create === true)? <DailyReportCreate /> : <Navigate to="/404" /> },
      { path: 'dailyReport/update', element: SuperRoles.indexOf(userData.role) > -1 || (Object.prototype.hasOwnProperty.call(dsrPermissions, 'update')  && dsrPermissions.update === true)? <DailyReportUpdate /> : <Navigate to="/404" /> },
      { path: 'tcategories/:catId/trainings', element: SuperRoles.indexOf(userData.role) > -1 ? <Trainings /> : <Navigate to="/404" /> },
      { path: 'tcategories', element: SuperRoles.indexOf(userData.role) > -1 ? <Categories /> : <Navigate to="/404" /> },
      { path: 'tcategories/:catId/trainings/create', element: SuperRoles.indexOf(userData.role) > -1 ? <TrainingCreate /> : <Navigate to="/404" /> },
      { path: 'training/update', element: SuperRoles.indexOf(userData.role) > -1 ? <TrainingCreate /> : <Navigate to="/404" /> },
      { path: 'training/details-page/:trainingId', element: SuperRoles.indexOf(userData.role) > -1 ? <TrainingDetails /> : <Navigate to="/404" /> },
      { path: 'work-application/list', element: SuperRoles.indexOf(userData.role) > -1 ? <ApplicationList /> : <Navigate to="/404" /> },
      { path: 'lms', element: SuperRoles.indexOf(userData.role) > -1 ? <Lms /> : <Navigate to="/404" /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'books', element: LearnersRole.indexOf(userData.role) > -1 || SuperRoles.indexOf(userData.role) > -1 ? <BookListView /> : <Navigate to="/404" /> },
      { path: 'share', element: <Share /> },
      { path: 'meetings', element: SuperRoles.indexOf(userData.role) > -1 ? <Meetings /> : <Navigate to="/404" /> },
      { path: 'meeting-passes', element: SuperRoles.indexOf(userData.role) > -1 ? <MeetingPass /> : <Navigate to="/404" /> },
      { path: 'groups', element: adminRole.indexOf(userData.role) > -1 ? <Groups /> : <Navigate to="/404" /> },
      { path: 'group/:groupId', element: adminRole.indexOf(userData.role) > -1 ? <GroupMembers /> : <Navigate to="/404" /> },
      { path: 'group-details/:groupId/', element: SuperRoles.indexOf(userData.role) > -1 ? <GroupOffice /> : <Navigate to="/404" /> },
      { path: 'meeting-details/:meetingId/', element: SuperRoles.indexOf(userData.role) > -1 ? <MeetingDetails /> : <Navigate to="/404" /> },
      { path: 'admin/office-details/:officeId/', element: adminRole.indexOf(userData.role) > -1 ? <AdminOfficeView /> : <Navigate to="/404" /> },
      { path: 'office-details/:officeId/', element: SuperRoles.indexOf(userData.role) > -1 ? <OfficeDetails /> : <Navigate to="/404" /> },
      { path: 'office-details/:officeId/daily-working', element: SuperRoles.indexOf(userData.role) > -1 ? <OfflineWorking /> : <Navigate to="/404" /> },
      { path: ':username', element: SuperRoles.indexOf(userData.role) > -1 ? <Profiles /> : <Navigate to="/404" /> },
      { path: 'paytm/callback', function(req){
        console.log('req');
      }},
      // { path: 'meeting-room/:callerId/:receiverId', element: <MeetingRoom /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings/security', element: <SettingsView /> },
      { path: 'settings/username', element: <UserNameIndex /> },
      { path: 'tasks', element:  <TaskListView /> },
      { path: 'task/create', element: SuperRoles.indexOf(userData.role) > -1 ? <TaskCreate /> : <Navigate to="/404" /> },
      { path: 'task/update', element: SuperRoles.indexOf(userData.role) > -1 ? <TaskCreate /> : <Navigate to="/404" /> },
      { path: 'task/view/:taskId', element: SuperRoles.indexOf(userData.role) > -1 ? <TaskDetails /> : <Navigate to="/404" /> },
      { path: 'training/viewer/:trainingId/:viewerId', element: SuperRoles.indexOf(userData.role) > -1 ? <ViewerDetails /> : <Navigate to="/404" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: ':username',
    element: isLoggedIn ? <Profiles /> : <Navigate to="/login" />,
    children: [
      { path: 'room', element: <Profiles /> },
    ]
  },
  {
    path: '/meeting',
    element: isLoggedIn ? '' : <Navigate to="/login" />,
    children: [
      { path: 'room', element: <MeetingRoom /> },
    ]
  },
  {
    path: '/social',
    element: <DashboardLayout />,
    children: [
      { path: 'profile', element: <SocialMedia />},
      { path: 'feed', element: <SocialMedia />},
    ]
  },
  {
    path: '/',
    element: '',
    children: [
      { path: '/', element: <LandingPage /> },
      { path: 'landing-page', element: <LandingPage /> },
      { path: 'groups/join/:groupId', element: <JoinGroupRequest />},
      { path: 'pricing', element: <Pricing /> },
      { path: 'industrial-trainings', element: <Internship /> },
      { path: 'industrial-trainings/:tId', element: <InternshipDetails /> },
      { path: 'faq', element: <Faq /> },
      { path: 'testimonies', element: <Testimonies /> },
      { path: 'payment/success', function() { console.log('success'); } },
      { path: 'payment/failure', function() { console.log('failure'); } },
      { path: 'terms-and-conditions', element: <Terms /> },
      { path: 'privacy-policy', element: <PrivacyPolicy /> },
      { path: 'subscription-policy', element: <SubscriptionPolicy /> },
      { path: 'solutions', element: <Solutions /> },
      { path: 'work-application/:userId', element: <ApplicationForm /> },
      { path: 'payment/:userId', element: <PaymentForm /> },
      { path: 'payment/quick', element: <QuickPaymentForm /> },
      { path: 'investment', element: <Investment /> },
      { path: 'training/details-page/:trainingId', element: <TrainingPublicView /> },
      { path: 'training/pubview/:trainingId', element: <PublicView /> },
      { path: '404', element: <NotFoundView /> },
    ]
  },
  {
    path: '/',
    element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'logout', element: <LogOutComp /> },
      { path: 'register', element: <RegisterView /> },
      { path: 'forgot-password', element: <ForgotPwdView /> },
      { path: 'reset-password', element: <ResetPwdView /> },
      { path: 'forgot-password-success/:message', element: <ForgotPasswordSuccess /> },
      { path: 'verify', element: <VerifyemailPhoneView /> },
      { path: '404', element: <NotFoundView /> },
      // { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'auth',
    element: isLoggedIn && !userData.is_verified ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'verify-phone', element: <VerifyPhoneView /> },
    ]
  }
];

export default routes;
