/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GroupApiCall from 'src/utils/GroupApiCall';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditOffice from './EditOffice';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const OfficeDataTable = ({ groupId }) => {
  const classes = useStyles();
  const [offices, setOffices] = useState([]);
  const [name, SetName] = useState('');
  const [address, SetAddress] = useState('');
  const [desc, Setdesc] = useState('');
  const [skip, SetSkip] = useState(0);
  const [limit, SetLimit] = useState(10);
  const navigate = useNavigate();

  const handleEditClick = async (officeId) => {
    console.log('Edit button clicked', officeId);
  }
  const handleViewClick = async (officeId) => {
    console.log('View button clicked', officeId);
    navigate(`/app/admin/office-details/${officeId}`);
  }
  // console.log(offices);
  const officeList = async () => {
    try {
      const resp = await GroupApiCall.officeList(groupId, { skip, limit, name, address, desc  });
      if (resp.status === 200 && resp.offices) {
        setOffices(resp.offices);
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    }
  };

  useEffect(() => {
    officeList();
  }, []); 
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offices.map((office) => (
            <TableRow key={office.id}>
              <TableCell>{office.name}</TableCell>
              <TableCell>{office.desc}</TableCell>
              <TableCell>{office.address}</TableCell>
              <TableCell>{office.status}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleViewClick(office.id)}>
                  <VisibilityIcon />
                </IconButton>
                <EditOffice office={office} officeList={officeList} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OfficeDataTable.propTypes = {
  groupId: PropTypes.string.isRequired
};

export default OfficeDataTable;
