/* eslint-disable */
import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import List from './List';
import Myearnings from './Myearnings';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const ProductList = () => {
  const classes = useStyles();
  return (
      <Page
        className={classes.root}
        title="Share & Earn"
      >
        <Container maxWidth={false}>
          <Box mt={3}>
            <Grid
              container
              spacing={1}
            >
                <Grid
                  item
                  key='mm-1'
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Toolbar />
                </Grid>
                <Grid
                  item
                  key='mm-2'
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <List />
                </Grid>
                <Grid
                  item
                  key='mm-3'
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Myearnings />
                </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
  );
};

export default ProductList;
