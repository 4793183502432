import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import {
  Eye as ShowPassword,
  EyeOff as HidePassword
} from 'react-feather';
import Page from 'src/components/Page';
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ResetPwdView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const tokenString = query.get('token');
  const [secondsPhone, setSecondsPhone] = React.useState(120);
  const [resendPhoneCount, SetResendPhoneCount] = React.useState(0);
  const options = {
    headers: {
      'Content-Type': 'application/json',
    }
  };
  React.useEffect(() => {
    if (secondsPhone > 0) {
      setTimeout(() => setSecondsPhone(secondsPhone - 1), 1000);
    } else {
      setSecondsPhone('Resend Phone OTP');
    }
  });
  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/verify-pwdreset-token`, { pwdresetToken: tokenString }, options)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          toast.error('Link is expired or broken, try with forgot password to verify your account.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate('/forgot-password', { replace: true });
        }
      });
  }, []);

  const reSendPhoneOtp = () => {
    SetResendPhoneCount(resendPhoneCount + 1);
    setSecondsPhone(120);
    // console.log('send phone otp', resendPhoneCount);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/resend-phone-otp`, { verifyToken: tokenString, type: 'resetPassword' }, options)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Page
      className={classes.root}
      title="Reset Password"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              phone_otp: '',
              password: '',
              confirm_password: ''
            }}
            validationSchema={Yup.object().shape({
              phone_otp: Yup.number().typeError('OTP must be number.')
                .required('OTP is required'),
              password: Yup.string().max(255).required('password is required').matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                'Password must contain at least 8 characters, one uppercase, one number and one special case character'
              ),
              confirm_password: Yup.string().when('password', {
                is: (val) => (val && val.length > 0),
                then: Yup.string().oneOf(
                  [Yup.ref('password')],
                  'Both password need to be the same'
                )
              }).required('Confirm password is required')
            })}
            onSubmit={(values, { setSubmitting }) => {
              const postData = {
                token: tokenString,
                phone_otp: values.phone_otp,
                password: values.password,
                confirm_password: values.confirm_password,
              };
              axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`, postData, options)
                .then((res) => {
                  // const respData = res.data;
                  // console.log(res);
                  // console.log(res.data.data.tokens);
                  if (res.data.status === true) {
                    toast.success(`${res.data.message}`, {
                      position: 'top-right',
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    navigate('/login', { replace: true });
                  }
                })
                .catch((error) => {
                  // what now?
                  // console.log(error.response.data.message);
                  setSubmitting(false);
                  // Error
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error(`${error.response.data.message}`, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest
                    // in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error);
                  }
                });
              // navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Verify the Mobile OTP & Enter new Password
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    .
                  </Typography>
                </Box>
                <Box
                  mt={3}
                  mb={1}
                >
                  {/* <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                  </Typography> */}
                </Box>
                <TextField
                  error={Boolean(touched.phone_otp && errors.phone_otp)}
                  fullWidth
                  helperText={touched.phone_otp && errors.phone_otp}
                  label="Enter OTP sent on your Mobile Number"
                  margin="normal"
                  name="phone_otp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone_otp}
                  variant="outlined"
                />
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {/* Not received the otp?
                  {' '} */}
                  {
                    resendPhoneCount < 3
                    && (
                      <Link
                        to="/"
                        component={RouterLink}
                        // className="disabledCursor"
                        cursor="pointer"
                        onClick={(event) => { event.preventDefault(); reSendPhoneOtp(); }}
                      >
                        {secondsPhone}
                        {secondsPhone > 0 ? ' seconds' : ''}
                      </Link>
                    )
                  }
                  {
                    resendPhoneCount === 3
                    && (
                      <small>Maximum Limit Reached, Please try after some time.</small>
                    )
                  }
                </Typography>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="New Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
                  InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <ShowPassword /> : <HidePassword />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  error={Boolean(touched.confirm_password && errors.confirm_password)}
                  fullWidth
                  helperText={touched.confirm_password && errors.confirm_password}
                  label="Confirm Password"
                  margin="normal"
                  name="confirm_password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirm_password}
                  variant="outlined"
                />
                {/* <PhoneInput
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  label="Phone Number"
                  margin="normal"
                  name="phone"
                  country="us"
                  value={values.phone}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  inputClass={classes.root}
                />
                <PhoneInput
                  placeholder="Enter phone number"
                  value={values.phone}
                  onChange={handleChange}
                  inputComponent={CustomPhoneNumber}
                /> */}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Set Password
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default ResetPwdView;
