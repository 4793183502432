import axios from 'axios';
import { toast } from 'react-toastify';
import inLocalStorageJwt from './inLocalStorageJwt';

const addJoinMemberRequest = async (groupId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/group-members/join-req/${groupId}`, {}, options);
    toast.success(`${response.data.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 200, groupmember: response.data.groupmember, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, groupmember: '', message: error.response.data.message };
  }
};

const changeGMStatus = async (gmId, gmStatus) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  const postBody = {
    gmId,
    gmStatus
  };
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/group-members/change-status`, postBody, options);
    toast.success(`${response.data.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 200, member: response.data.member, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, member: '', message: error.response.data.message };
  }
};

const myGroups = async () => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/group-members/mygroups`, options);
    // toast.success(`${response.data.message}`, {
    //   position: 'top-right',
    //   autoClose: 10000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
    return { status: 200, mygroups: response.data.mygroups, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, mygroups: '', message: error.response.data.message };
  }
};
const groupDetails = async (groupId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/groups/view/${groupId}`, options);
    // toast.success(`${response.data.message}`, {
    //   position: 'top-right',
    //   autoClose: 10000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
    return { status: 200, group: response.data.group, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, group: '', message: error.response.data.message };
  }
};

const myOffices = async (groupId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/group-members/${groupId}/offices`, options);
    // toast.success(`${response.data.message}`, {
    //   position: 'top-right',
    //   autoClose: 10000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
    return { status: 200, myOffices: response.data.offices, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, myOffices: '', message: error.response.data.message };
  }
};

const officeDetails = async (officeId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/offices/view/${officeId}`, options);
    return { status: 200, office: response.data.office, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, office: '', message: error.response.data.message };
  }
};
export default {
  addJoinMemberRequest,
  changeGMStatus,
  myGroups,
  groupDetails,
  myOffices,
  officeDetails
};
