/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Container,
  makeStyles,
  Link,
  Button,
  Grid
} from '@material-ui/core';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/Visibility';
import Page from 'src/components/Page';
// import DsrList from './DsrList';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { toast } from 'react-toastify';
// import data from './data';
import { NavLink as RouterLink } from 'react-router-dom';
// import { DesktopDatePicker } from '@material-ui/pickers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DeleteIcon from '@material-ui/icons/Delete';
import EjectIcon from '@material-ui/icons/Eject';
import Countdown from 'react-countdown';
import EditIcon from '@material-ui/icons/Edit';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  },
  formExpndCard: {
    minWidth: 275,
    margin: '15px'
  },
  respHeader: {
    margin:'10px',
    padding: '10px'
  },
  dataBlock: {
    marginTop: '10px',
    paddingTop: '10px'
  },
  statsItem: {
    alignItems: 'center',
    display: 'inline'
  },
}));
// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return true;
  } else {
    // Render a countdown
    return (
      <span>
        {days}:{hours}:{minutes}:{seconds}
      </span>
    );
  }
};
const List = () => {
  const classes = useStyles();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  function getDatefromid(id){
    const timestamp = id.toString().substring(0,8);
    const date = new Date( parseInt( timestamp, 16 ) * 1000 );
    return date;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  const columns = [
    {
      name: <b style={{ fontSize: '15px'  }}>Date</b>,
      selector: 'id',
      sortable: true,
      center: true,
        cell: (row) => (<div style={{fontSize: '15px'}}>{moment(getDatefromid(row._id)).format('DD/MM/YYYY hh:mm A')}</div>)
    },
    {
      name: <b style={{ fontSize: '15px' }}>User</b>,
      selector: 'user',
      sortable: true,
      // right: true,
      cell: (row) => row.user.map(usr=> {
          return usr.fname;
        })
    },
    {
      name: <b style={{ fontSize: '15px' }}>Source</b>,
      selector: 'source',
      sortable: true,
    //   right: true,
      cell: (row) => row.source.plan.price
    },
    {
      name: <b style={{ fontSize: '15px' }}>Source Type</b>,
      selector: 'sourceType',
      sortable: true,
      center: true,
    },
    {
      name: <b style={{ fontSize: '15px' }}>Deposit Amount</b>,
      selector: 'depositAmount',
      sortable: true,
      center: true,
      cell: (row) => row.depositAmount.toFixed(2)
    },
    {
      name: <b style={{ fontSize: '15px' }}>Withdrawl Amount</b>,
      selector: 'withdrawlAmount',
      sortable: true,
      center: true,
      cell: (row) => row.withdrawlAmount.toFixed(2)
    },
    {
      name: <b style={{ fontSize: '15px' }}>Closing Amount</b>,
      selector: 'closingBalance',
      sortable: true,
      center: true,
      cell: (row) => row.closingBalance.toFixed(2)
    },
    {
      name: <b style={{ fontSize: '15px' }}>Currency</b>,
      selector: 'currency',
      sortable: true,
      center: true,
    },
    // {
    //   name: <b style={{ fontSize: '15px' }}>Action</b>,
    //   center: true,
    //   // cell: (row) => <Link color="primary"
    //   // component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>Edit</Link>
    //   cell: (row) => (
    //     <Grid
    //       container
    //       spacing={3}
    //     >
    //       <Grid
    //         item
    //         className={classes.statsItem}
    //       >
    //         <Tooltip title="View Profile">
    //           <IconButton color="primary" component={RouterLink} to={`/app/customer/view/${row.id}`}>
    //             <ViewIcon />
    //           </IconButton>
    //         </Tooltip>
    //       </Grid>
    //     </Grid>
    //   )
    // }
  ];
  const fetchMyearnings = async (page) => {
    const offset = (page - 1) * perPage;
    setLoading(true);
    // const postData = {
    //   createdBy: getLoggedinUserData.id,
    //   skip: offset,
    //   limit: perPage
    // };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    var filters = `?skip=${offset}&limit=${perPage}`;
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/myearnings/all${filters}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data.result);
        setData(res.data.result.myearnings);
        setTotalRows(res.data.result.totalmyearnings);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    fetchMyearnings(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    // await fetchMyearnings(page);
    // setData(response.data.data);
  };

  useEffect(() => {
    // console.log("perpage", perPage);
    fetchMyearnings(1);
  }, [perPage]);

  const ExpandedRow = (row) => {
    // console.log(row);
    const applicationOb = row.data;
    const bull = <span className={classes.bullet}>•</span>;
    return (<div className={classes.root}>
           <Grid container spacing={3}>
              <Card className={classes.formExpndCard}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className={classes.respHeader}>Email</Th>
                      <Th className={classes.respHeader}>Phone</Th>
                      <Th className={classes.respHeader}>Account Status</Th>
                      <Th className={classes.respHeader}>Type</Th>
                      {/* <Th className={classes.respHeader}>Notes</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td className={classes.respHeader}>{applicationOb.email}</Td>
                      <Td className={classes.respHeader}><a href={`tel:+91-${applicationOb.phone}`}>{applicationOb.phone}</a></Td>
                      <Td className={classes.respHeader}>{ applicationOb.is_verified ? 
                      (<Typography
                        color="textSecondary"
                        display="inline"
                        variant="body2"
                        style={{backgroundColor:'#159932',borderRadius:'5px', margin:'3px', padding: '5px', color: 'white',textAlign:'center'}}
                      >
                        Verified
                      </Typography>)  : (
                        <Typography
                          color="textSecondary"
                          display="inline"
                          variant="body2"
                          style={{backgroundColor:'#ff9933',borderRadius:'5px', margin:'3px', padding: '5px', color: 'white', textAlign:'center'}}
                        >
                          Not-Verified
                        </Typography>
                      )}</Td>
                      <Td className={classes.respHeader}>{applicationOb.role === 'paid_user'? 'Premium User':(applicationOb.role==='learner'? 'Learner' : 'Free User')}</Td>
                      {/* <Td className={classes.respHeader}>{applicationOb.notes}</Td> */}
                    </Tr>
                  </Tbody>
                </Table>
              </Card>
           </Grid>
       </div>);
  };
  const tableData = {
    columns,
    data
  };
  return (
      <Card className={classes.dataBlock}>
        {/* <DsrList dsrList={dsrList} /> */}
        <DataTableExtensions {...tableData}>
          <DataTable
            title="My Earnings"
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            filter
            paginationServer
            paginationTotalRows={totalRows}
            // selectableRows
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover
            // expandableRows
            // expandableRowDisabled={row => row.disabled}
            // expandableRowsComponent={<ExpandedRow />}
          />
        </DataTableExtensions>
      </Card>
  );
};

export default List;
