/* eslint-disable */
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Header from "src/components/Header/Header.js";
import Footer from "src/components/Footer/Footer.js";
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import Button from "src/components/CustomButtons/Button.js";
import HeaderLinks from "src/components/Header/HeaderLinks.js";
import Parallax from "src/components/Parallax/Parallax.js";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

import styles from "src/assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import CourseHighlights from "./Sections/CourseHighlights.js";
import Certifications from "./Sections/Certifications.js";
import Enrolment from "./Sections/Enrolment.js";
import HowTrainingWork from "./Sections/HowTrainingWork.js";
import PlacementAssistant from "./Sections/PlacementAssistant.js";

const dashboardRoutes = [];
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [course, setCourse] = useState({});
  let { tId } = useParams();
  const { ...rest } = props;
  const options1 = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  useEffect(() => {
    async function getCourse(tId) {
      // SetPricingData({ id: trainingId });
      // console.log(tiers);
      await axios.get(`${process.env.REACT_APP_API_BASE_URL}/courses/${tId}`, options1)
        .then(async (res) => {
          setCourse(res.data.course);
          // console.log(pricingData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getCourse(tId);
  }, []); // Or [] if effect doesn't need props or state
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="BMS GLOBAL"
        rightLinks={ <HeaderLinks /> }
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={ course && course.image }>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
            <h1 className={classes.title} style={{ 'fontSize': '4em' }}>{course.name}</h1>
              {/* <h4 style={{ 'fontSize': '1.125rem', 'fontWeight': '300', 'fontFamily': '"Roboto", "Helvetica", "Arial", sans-serif'}}>
                {course.description}
              </h4> */}
              <br />
              <Button
                color="danger"
                size="lg"
                // href="/payment/quick"
                // target="_blank"
                onClick={()=>{navigate('/payment/quick')}}
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Quick Enrolment
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              {Object.keys(course).length !== 0 && <Enrolment currentCourse={{...course}} />}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <CourseHighlights />
          <Certifications {...course}/>
          <PlacementAssistant />
          <HowTrainingWork />
        </div>
      </div>
      <Footer />
    </div>
  );
}
