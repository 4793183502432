/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import Group from "@material-ui/icons/Group";

import MenuBookIcon from '@material-ui/icons/MenuBook';
import BusinessIcon from '@material-ui/icons/Business';
import ListAlt from "@material-ui/icons/ListAlt";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import AirplayIcon from '@material-ui/icons/Airplay';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import GetAppIcon from '@material-ui/icons/GetApp';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// core components
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import InfoArea from "src/components/InfoArea/InfoArea.js";

import styles from "src/assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>How will your training work?</h2>
          {/* <h5 className={classes.description}>
            The complete solution of Business Management including task, team, products, content, customers.
            you can store and manage them in a single place here very smoothly.
          </h5> */}
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Learning Phase"
              description="You will learn from live sessions and video backups."
              icon={MenuBookIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="1:1 Doubt Sessions"
              description="Clear your doubt from experts or forum within 24 hours."
              icon={AirplayIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Testing Phase"
              description="Test your knowledge through quizzes & module tests at regular intervals."
              icon={QueryBuilderIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Practice Time"
              description="Work on Live projects and assignments."
              icon={MeetingRoomIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Final Exam"
              description="Complete your training by taking the final exam."
              icon={QueryBuilderIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Certificate of training"
              description="Get certified upon successful completion of training."
              icon={CardMembershipIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
