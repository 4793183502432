import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactPlayer from 'react-player';
import {
  // Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
  capitalize
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { isMobile } from 'react-device-detect';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  dropdown: {
    backgroundColor: 'white',
    color: 'black',
    padding: theme.spacing(2)
  }
}));
// axios.all([
//   axios.get('https://api.github.com/users/hacktivist123'),
//   axios.get('https://api.github.com/users/adenekan41')
// ])
//   .then((response) => {
//     console.log('Date created: ', response[0].data.created_at);
//     console.log('Date created: ', response[1].data.created_at);
//   });
const ProductCard = ({
  className, testimony, ...rest
}) => {
  const classes = useStyles();
  const [openTraining, setOpenTraining] = React.useState(!isMobile);

  const handleTrainingClick = () => {
    setOpenTraining(!openTraining);
  };
  useEffect(() => {
    console.log('testimony:', testimony);
  }, []);
  return (
    <>
      <ListItem className={classes.dropdown} button onClick={handleTrainingClick}>
        <ListItemIcon>
          <OndemandVideoIcon />
        </ListItemIcon>
        <ListItemText
          primary={capitalize(testimony.data ? testimony.data.name : testimony.name)}
        />
        {openTraining ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openTraining} timeout="auto" unmountOnExit>
        <Card
          className={clsx(classes.root, className)}
          {...rest}
        >
          <div className="player-wrapper">
            <ReactPlayer
              width="100%"
              height="365px"
              // light="/static/images/video-thumbnail.jpg"
              onContextMenu={(e) => { e.preventDefault(); }}
              muted={!false}
              controls={!false}
              url={testimony.data ? testimony.data.video_link : testimony.video_link}
              // url="https://bmsglobal.s3.ap-south-1.amazonaws.com/uploads/trainings/Introductory+Session.mp4"
              config={{
                file: { attributes: { controlsList: 'nodownload' } },
                youtube: {
                  playerVars: {
                    showinfo: 0,
                    enablejsapi: 0,
                    origin: process.env.REACT_APP_BASE_URL
                  },
                  events: {
                    onStateChange: () => { console.log('hhhh'); }
                  }
                }
              }}
            />
          </div>
          <CardContent>
            <Typography
              align="center"
              color="textPrimary"
              variant="body1"
            >
              {`By ${testimony.data ? testimony.data.trainer : testimony.trainer}`}
            </Typography>
            <Typography
              align="center"
              color="textPrimary"
              variant="body1"
            >
              {testimony.data ? testimony.data.notes : testimony.notes}
            </Typography>
          </CardContent>
          <Box flexGrow={1} />
          <Divider />
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-format="fluid"
            data-ad-layout-key="-f3-d+1d-41+70"
            data-ad-client="ca-pub-4645266536536108"
            data-ad-slot="6734199001"
          />
          <script>
            (adsbygoogle = window.adsbygoogle || []).push(
            {}
            );
          </script>
        </Card>
      </Collapse>
    </>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  testimony: PropTypes.object.isRequired
};

export default ProductCard;
