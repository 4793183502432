/*eslint-disable*/
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import { toast } from 'react-toastify';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ApiCall from 'src/utils/ApiCall';
import { v4 as uuidv4 } from 'uuid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid } from "@material-ui/core";
import { useNavigate, useLocation } from 'react-router-dom';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import { CheckoutProvider, Checkout } from 'paytm-blink-checkout-react';
import config from 'src/components/paytm/mocks/merchant-config.js';
const useStateRef = require('react-usestateref');

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  payButton:{
    marginTop: '5px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  actionBar: {
      backgroundColor: '#f2f2f2'
  }
}));

const RecipeReviewCard = ( course ) => {

  const classes = useStyles();
  const { currentCourse } = course;
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const query = useQuery();
  const callBack = query.get('callback');
  const transactionStatus = query.get('status');
  const messageResp = query.get('message');
 //   const [pricingData, SetPricingData] = useStateRef([]);
  const [duration, setDuration] = React.useState('');
  const handleChange = (event) => {
    setDuration(event.target.value);
  };
  const [openBlink, SetOpenBlink] = useStateRef(false);
  const [isloading, Setisloading] = useStateRef(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleDetailsClick = (tId) => {
    navigate(`/industrial-trainings/${tId}`);
  };
  config.handler = {
    "notifyMerchant": function (eventName, data) {
      console.log("notifyMerchant handler function called");
      console.log("eventName => ", eventName);
      if(eventName === 'APP_CLOSED'){
        console.log('closed');
        Setisloading(false);
        ApiCall.deleteALlIncompleteOrder();
      }
      console.log("data => ", data);
    }
  };
  useEffect(()=>{
    if (callBack === 'true') {
        if (transactionStatus === 'TXN_SUCCESS') {
          toast.success(`${messageResp}`, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (transactionStatus === 'TXN_FAILURE') {
          toast.error(`${messageResp}`, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
  }, []);

  useEffect(()=> {
    console.log(openBlink);
  }, [openBlink]);
  return (
    <Card className={classes.root}>
       <CardContent>
          <Grid container >
            <Grid item xs={12} ms={12} sm={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Enroll Now </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={duration}
                  onChange={handleChange}
                  label="Duration"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {currentCourse.duration.map((dur, ind) => (
                    <MenuItem key={uuidv4()} value={dur}>
                      {dur.days} days &nbsp;
                      <span style={{ textDecoration: "line-through", color:'red'}}>-{dur.oldPrice} ₹</span>&nbsp;
                      <span style={{color:'green'}}>{dur.newPrice} ₹</span> &nbsp;&nbsp;
                      {[0,4].includes(ind)  && <span style={{fontSize:'15px', float:'right', color: 'red'}}>filling fast</span>}
                      {[1,2].includes(ind)  &&<span style={{fontSize:'15px', float:'right', color: 'orange'}}>Almost Full</span>}
                      {[3].includes(ind)  &&<span style={{fontSize:'15px', float:'right', color: 'green'}}>Available</span>}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={28}>1 Month</MenuItem>
                  <MenuItem value={20}>2 Months</MenuItem>
                  <MenuItem value={30}>3 Months</MenuItem>
                  <MenuItem value={30}>3 Months</MenuItem>
                  <MenuItem value={30}>3 Months</MenuItem>
                  <MenuItem value={30}>3 Months</MenuItem> */}

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} ms={12} sm={12}>
            {duration &&
          <Button
            fullWidth
            className={classes.payButton}
            variant="contained"
            disabled={isloading}
            key={uuidv4()}
            onClick={async () => {
                SetOpenBlink(false);
                Setisloading(true);
                // alert("We are working on it. please wait. we will update you.");
                const isAuth = await inLocalStorageJwt.checkAuth();
                // // console.log("api caled", isAuth);
                if (!isAuth) {
                // alert("login before buying.");
                toast.info('Please login before placing your order.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate('/login?redirect=industrial-trainings');
                return;
                }
                const checksum = await ApiCall.makeCoursePurchase(currentCourse.id, duration._id);
                // console.log(checksum);
                const tempData = {
                orderId: checksum.body.orderId,
                amount: checksum.body.txnAmount.value,
                token: checksum.body.txnToken,
                tokenType: 'TXN_TOKEN',
                userDetail: checksum.body.userInfo
                };
                config.data = tempData;
                SetOpenBlink(true);
            }}
            color="primary"
            >
            {isloading? 'Loading': 'Avail'}
            </Button>
          }
            </Grid>
          </Grid>
        </CardContent>
        {
        (openBlink === true)  &&
        (<CheckoutProvider config={config} env={process.env.REACT_APP_PAYTM_ENV}>
            <Checkout />
        </CheckoutProvider>
        )}
    </Card>
  );
}

RecipeReviewCard.propTypes = {
    className: PropTypes.string,
    course: PropTypes.object
  };
  
export default RecipeReviewCard;