/* eslint-disable */
import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Page from 'src/components/Page';
import {
  Container,
  Avatar,
  Button,
  // Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { Link as RouterLink , useNavigate, useParams} from 'react-router-dom';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import BusinessIcon from '@material-ui/icons/Business';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import Breadcrumbs from './BreadCrumbs';
import Meetings from './Meetings';
import GroupMemberApiCall from 'src/utils/GroupMemberApiCall';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  spacer: {
    marginBottom:  '5px'
  },
  OfficeBtn: {
    margin: '5px',
    backgroundColor: 'aqua'
  },
  OnlineBtn: {
    margin: '5px',
    backgroundColor: 'gold'
  }
}));

const Offices = () => {
  const classes = useStyles();
  const { officeId } = useParams();
  const [officeData, setOfficeData] = useState({});

  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [itemsArray, setItemsArray] = React.useState([{page: 'Home', href: '/app/dashboard'}]); 

  const office = {address: 'sec-4, block 6A, Bhilai'};
  const navigate = useNavigate();

  // const itemsArray = [
  //   {page: 'Home', href: '/app/dashboard'},
  //   {page: 'Super-5', href: '/app/group-details/66118dccf137e33dbd3ee93b'},
  //   {page: 'Sec-4, office', href: '/app/office-details/66118dccf137e33dbd3ee93b'}
  // ];
  const officeDetails = async () => {
    try {
      const resp = await GroupMemberApiCall.officeDetails(officeId);
      if (resp.status === 200 && resp.office) {
        setOfficeData(resp.office);
        const ofc = resp.office;
        setItemsArray([
          ...itemsArray,
          {page: ofc && ofc.groupId.gname, href: `/app/group-details/${ofc && ofc.groupId.id}`},
          {page: ofc && ofc.name, href: `/app/office-details/${ofc && ofc.id}`}
        ]);
        // console.log(resp.mygroups);
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    }
  };
  React.useEffect(() => {
    officeDetails();
  }, []);
  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Breadcrumbs itemsArray = {itemsArray} className={classes.spacer} lastPage = {{page:'Daily Working'}}/>
        <Grid
          container
          spacing={3}
        >
          <>
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card>
                <CardContent>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                  >
                    <>
                      <Grid item key={uuid()}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h3"
                        >
                          Daily Working
                        </Typography>
                        {/* <Typography
                          color="textPrimary"
                          variant="h6"
                        >
                          Great office
                        </Typography> */}
                      </Grid>
                      {/* <Grid item>
                        <Avatar className={classes.avatar}>
                          <BusinessIcon />
                        </Avatar>
                      </Grid> */}
                      <Grid
                        item 
                        lg={12}
                        md={12}
                        xl={12}
                        xs={12}
                      >
                        <Meetings officeId={officeId}  />
                      </Grid>
                    </>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </>
        </Grid>
      </Container>
    </Page>
  );
};

export default Offices;
