import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  // Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { Link as RouterLink } from 'react-router-dom';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const Groups = ({ className, mygroups, ...rest }) => {
  const classes = useStyles();
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();

  return (
    mygroups.map((group) => (
      <RouterLink to={getLoggedinUserData.role !== 'user' && group.status !== 'pending' ? `/app/group-details/${group.groupId.id}` : '#'}>
        <Card
          className={clsx(classes.root, className)}
          {...rest}
        >
          <CardContent>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <>
                <Grid item key={uuid()}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                  >
                    {group.groupId.gname}
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >
                    Status: &nbsp;
                    <span style={{ color: group.status === 'pending' ? 'red' : 'green' }}>
                      {group.status === 'pending' && 'Pending' }
                      {group.status === 'accepted' && 'Approved' }
                    </span>
                  </Typography>
                </Grid>
                <Grid item key={uuid()}>
                  <Avatar className={classes.avatar}>
                    <PeopleIcon />
                  </Avatar>
                </Grid>
              </>
            </Grid>
          </CardContent>
        </Card>
      </RouterLink>
    ))
  );
};

Groups.propTypes = {
  className: PropTypes.string,
  mygroups: PropTypes.array
};

export default Groups;
