/* eslint-disable */
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// import clsx from 'clsx';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
// @material-ui/icons
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// // core components
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import axios from 'axios';
import { toast } from 'react-toastify';
import Card from './Card';
import { red } from '@material-ui/core/colors';
import Header from "src/components/Header/Header.js";
import Footer from "src/components/Footer/Footer.js";
import Button from "src/components/CustomButtons/Button.js";

import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import HeaderLinks from "src/components/Header/HeaderLinks.js";
import Parallax from "src/components/Parallax/Parallax.js";
import styles from "src/assets/jss/material-kit-react/views/landingPage.js";
import landingbg from "src/assets/img/internship-bg.jpg";
import { useNavigate, useLocation } from 'react-router-dom';

const useStateRef = require('react-usestateref');
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  actionBar: {
      backgroundColor: '#f2f2f2'
  }
}));
export default function LandingPage(props) {
  const classes = useStyles();
  const classes2 = useStyles2();

  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const query = useQuery();
  const [courseData, SetCourseData] = useState([]);
  const [courseLength, SetCourseLength] = useState();
  const callBack = query.get('callback');
  const transactionStatus = query.get('status');
  const messageResp = query.get('message');
//   const [pricingData, SetPricingData] = useStateRef([]);
  // const [openBlink, SetOpenBlink] = useStateRef(false);
  const [duration, setDuration] = React.useState('');

  const options1 = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  useEffect(() => {
    // setTimeout(()=> alert("hello"),5000);
    async function getCourses() {
      // SetPricingData({ id: trainingId });
      // console.log(tiers);
      const query = "?type=ind-training";
      await axios.get(`${process.env.REACT_APP_API_BASE_URL}/courses/get-all${query}`, options1)
        .then(async (res) => {
          SetCourseData(res.data.result);
          // console.log(pricingData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getCourses();
    if (callBack === 'true') {
      if (transactionStatus === 'TXN_SUCCESS') {
        toast.success(`${messageResp}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (transactionStatus === 'TXN_FAILURE') {
        toast.error(`${messageResp}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, []);

  useEffect(()=> {
    SetCourseLength(courseData.length);
  },[courseData]);

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="BMS GLOBAL"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax normal filter image={ landingbg }>
        <div className={classes.container}>
          <GridContainer>
            <GridItem key={uuidv4()}>
              <h1 className={classes.title} style={{ 'fontSize': '3em' }}>Start Your Career With BMS GLOBAL.</h1>
              {/* <h4 style={{ 'fontSize': '1.125rem', 'fontWeight': '300', 'fontFamily': '"Roboto", "Helvetica", "Arial", sans-serif'}}>
                Every entrepreneur needs a personal assistant, but not every body can afford it, So we are here to manage all your stuff. You are welcome.
              </h4> */}
              <br />
              <Button
                color="danger"
                size="lg"
                // href="/payment/quick"
                // target="_blank"
                onClick={()=>{navigate('/payment/quick')}}
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Quick Enrolment
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)} key={uuidv4()}>
        <div className={classes.container} key={uuidv4()}>
          {/* <ProductSection /> */}
          {/* <Pricing /> */}
          <Grid container spacing={4} key={uuidv4()}>
            {(courseData.length > 0) && courseData.map((course, index) => (
              <Grid
               item
               key={uuidv4()}
               xs={12} ms={12} sm={6}
              >
                <Card currentCourse={{...course, sno:(index+1)}} courseLength={courseData.length} />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  );
}
