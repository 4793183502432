/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import moment from 'moment';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import axios from 'axios';
import { toast } from 'react-toastify';
// import placeholder from '../src/static/images/avatars/avatar_6.png';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  Avatar,
  Box,
  Button,
  Grid,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Yup from 'yup';
import { Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@material-ui/core/styles';
const placeholder = '/static/images/avatars/user-logo-avtar.png';
import TrainingContext from 'src/contexts/TrainingContext';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  hideInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    opacity: '0'
  }
}));

const Profile = ({ className, trainingData, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const trainingContext = useContext(TrainingContext);

  const [openPremiere, setOpenPremiere] = React.useState(false);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  const handleClickOpenPremiere = () => {
    setOpenPremiere(true);
  };
  const handleClosePremiere = () => {
    setOpenPremiere(false);
  };
  return (
    <>
    <Grid
      className={classes.statsItem}
      item
    >
      <Typography
        color="textSecondary"
        display="inline"
        variant="body2"
      >
        <Button
          onClick={() => handleClickOpenPremiere(trainingData)}
          style={{ color: '#4051B5' }}
        >
          <LiveTvIcon
            className={classes.statsIcon}
            color="action"
            style={{ color: '#4051B5' }}
          />
          Create Premiere
        </Button>
      </Typography>
    </Grid>
    {/* Starting Code for Creating Premiere dialog box */}
    <div>
    <Dialog open={openPremiere} onClose={handleClosePremiere} aria-labelledby="form-dialog-title">
      <Formik
        initialValues={{
          trainingid: trainingData.id,
          prem_time: '',
        }}
        validationSchema={Yup.object().shape({
          prem_time: Yup.date().required('Premiere time is required')
        })}
        onSubmit={(values, { setSubmitting }) => {
          const postData = {
            training: values.trainingid,
            prem_time: values.prem_time,
          };
          axios.post(`${process.env.REACT_APP_API_BASE_URL}/premieres/create`, postData, options)
            .then((res) => {
              const respData = res.data.premiere;
              trainingContext.setPremieredat(respData);
              // alert(respData.tokens.access);
              toast.success(`${res.data.message}`, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              handleClosePremiere();
              navigate(`/app/training/details-page/${trainingData.id}`);
              // if (res.data.status === true) {
              //   // navigate('/app/dashboard', { replace: true });
              // }
            })
            .catch((error) => {
              // what now?
              // console.log(error.response.data.message);
              setSubmitting(false);
              // Error
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                toast.error(`${error.response.data.message}`, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest
                // in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error);
              }
            });
          // navigate('/app/dashboard', { replace: true });
        }}
      >
        {({
          errors,
          // handleBlur,
          // handleChange,
          handleSubmit,
          // isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Create Premiere</DialogTitle>
            <DialogContent>
              {/* <DialogContentText>
                {`Create Premiere of ${training.data ? training.data.name : training.name}.`}
              </DialogContentText> */}
              <Grid
                item
                md={12}
                xs={12}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    disablePast
                    onChange={(date) => {
                      setFieldValue('prem_time', date, errors.deadline);
                    }}
                    error={Boolean(touched.prem_time && errors.prem_time)}
                    helperText={touched.prem_time && errors.prem_time}
                    fullWidth
                    label="Premiere time"
                    name="prem_time"
                    required
                    value={values.prem_time}
                    variant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {/* <Grid
                item
                md={6}
                xs={12}
              >
                <FormControlLabel
                  control={(
                    <Switch
                      checked={values.share}
                      onChange={handleChange}
                      name="share"
                      color="primary"
                    />
                  )}
                  label="can share"
                />
              </Grid> */}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePremiere} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Create
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  </div>
  </>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  trainingData: PropTypes.object
};

export default Profile;
