/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import UserContext from 'src/contexts/UserContext';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  makeStyles,
  TextField
} from '@material-ui/core';
import {
  Copy as CopyIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [processingref, setProcessingref] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [copySuccess, setCopySuccess] = useState('');
  const [myearningBalance, setMyearningBalance] = useState(0);
  const userContext = useContext(UserContext);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const loggidinUser = inLocalStorageJwt.getLoggedinUserData();
  const [open, setOpen] = React.useState(false);
  const body = (
  <Formik
      initialValues={{
        withdrawlAmount: '',
        upiId: ''
    }}
      validationSchema={Yup.object().shape({
        withdrawlAmount: Yup.number().required('Please enter withdrawl amount'),
        upiId: Yup.string().max(255).required('Upi Id is required')
    })}
      onSubmit={(values, { setSubmitting }) => {
        const postData = {
          withdrawlAmount: values.withdrawlAmount,
          upiId: values.upiId,
          user: getLoggedinUserData.id
        };

        const options = {
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${AuthToken}`,
            }
        };
        axios.patch(`${process.env.REACT_APP_API_BASE_URL}/categories/update/${category.id}`, postData, options)
        .then((res) => {
            const respData = res.data.data;
            // console.log(respData);
            // console.log(res.data.data.tokens);
            toast.success(`${res.data.message}`, {
              position: 'top-right',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            categoryContext.getCategories();
            handleClose();
        })
        .catch((error) => {
            // what now?
            // console.log(error.response.data.message);
            setSubmitting(false);
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                toast.error(`${error.response.data.message}`, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest
                // in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error);
            }
        });
        // navigate('/app/dashboard', { replace: true });
    }}
    >
    {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
    }) => (
        <form onSubmit={handleSubmit}>
        {/* <Box mb={3}>
            <Typography
            color="textPrimary"
            variant="h2"
            >
            Folder Create
            </Typography>
            <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
            >
            {/* Sign in on the internal platform */}
            {/* </Typography> */}
        {/* </Box> */}
        <TextField
            error={Boolean(touched.withdrawlAmount && errors.withdrawlAmount)}
            fullWidth
            helperText={touched.withdrawlAmount && errors.withdrawlAmount}
            label="Withdrawl amount"
            margin="normal"
            name="withdrawlAmount"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.withdrawlAmount}
            variant="outlined"
        />
        
        <Box my={2}>
            <Button
            color="primary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            >
            Withdraw
            </Button>
        </Box>
        </form>
    )}
    </Formik>
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  function handleCopy(referralCode) {
    // console.log(trainingId, permissions);
    // const encodedData = btoa(`?referral_code=${referralCode}`); // encode a string
    navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/register?referral_code=${referralCode}`);
    setCopySuccess(' Copied!');
  }
  const handleGetNewRefCode = async () => {
    // console.log(loggidinUser.id);
    setProcessingref(true);
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/get-new-ref-code`, options)
      .then(async (res) => {
        const usr = res.data.user;
        if (usr.id === loggidinUser.id) {
          // console.log('bhitar');
          userContext.userData = usr;
          inLocalStorageJwt.setLoggedinUserData(usr);
        }
        setProcessingref(false);
        // SetPaidUsers(res.data.result.users);
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    // console.log('gggggggg');
    const getmyearnings = async () => {
      // console.log(loggidinUser.id);
      setProcessing(true);
      await axios.get(`${process.env.REACT_APP_API_BASE_URL}/myearnings/earningbalance`, options)
        .then(async (res) => {
          // const usr = res.data.user;
          const mybalance = res.data.myearningBalance;
          if (mybalance.length > 0) {
            // console.log(mybalance[0].closingBalance);

            setMyearningBalance(mybalance[0].closingBalance);
          }
          setProcessing(false);
          // SetPaidUsers(res.data.result.users);
          // setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getmyearnings();
  }, []);
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        {/* <Button
          className={classes.importButton}
          variant="contained"
        >
          E-WALLET
          <AccountBalanceWalletIcon />
        </Button> */}
        {/* <Button className={classes.exportButton}>
          Export
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
        >
          <AccountBalanceIcon />
          Add Meeting
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Typography
              variant="h1"
              component="h2"
            >
              Meeting Room
            </Typography>
            {/* <Typography
              variant="h5"
              component="h5"
            >
              Your referral code : &nbsp;
              {loggidinUser.referral_code ? loggidinUser.referral_code : ''}
              {!loggidinUser.referral_code
              && (
                !processingref
                  ? (
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={handleGetNewRefCode}
                    >
                      Get Now
                    </Button>
                  ) : 'Wait'
              )}
              {
                loggidinUser.referral_code
                && (
                  <Button
                    onClick={() => handleCopy(loggidinUser.referral_code)}
                    style={{ color: '#4051B5' }}
                  >
                    <CopyIcon
                      className={classes.statsIcon}
                      style={{ color: '#4051B5' }}
                    />
                    Copy Link
                  </Button>
                )
              }
              {copySuccess}
            </Typography> */}
          </CardContent>
        </Card>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">My Earning Withdrawl</DialogTitle>
        <DialogContent>
          {body}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {/* <Button onClick={handleClose} color="primary" autoFocus>
            Withdraw
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
