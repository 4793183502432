/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Razorpay from 'razorpay-checkout';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import ApiCall from 'src/utils/ApiCall';
import CouponApiCall from 'src/utils/CouponApiCall';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const PaymentDialog = ({ open, handleClose, amount, planId }) => {
  const classes = useStyles();
  const [openL, setOpenL] = React.useState(false);
  // const handleCloseL = () => {
  //   setOpenL(false);
  // };
  const handleToggleL = () => {
    setOpenL(!openL);
  };
  const [selectedGateway, setSelectedGateway] = useState('razorpay');
  const [couponCode, setCouponCode] = useState('');
  const [discountedPrice, setdiscountedPrice] = useState('');
  const [disableCCodeInp, setdisableCCodeInp] = useState(false);

  const handleGatewayChange = (event) => {
    setSelectedGateway(event.target.value);
  };

  const handleCouponChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleApplyCoupon = async() => {
    // Apply coupon logic here
    // console.log('Coupon applied:', couponCode);
    if (!couponCode) {
      return;
    }
    const resp = await CouponApiCall.validateCoupon(couponCode);
    if (resp.status === 200 && resp.coupon) {
      const coupon =  resp.coupon;
      setdisableCCodeInp(true);
      const disc = amount - (amount * coupon.discount/100);
      setdiscountedPrice(disc);
      // console.log("coupon", resp);
    }
  };

  // Function to handle clearing the input value
  const handleClearInput = () => {
    setdisableCCodeInp(false);
    setCouponCode('')
  };
  const handlePayment = async (orderDetails, user) => {
    // console.log(user, orderDetails);
    try {
      // Fetch order details from the server (you might want to do this using useEffect)
    //   const response = await fetch('/create-payment');
    //   const orderDetails = await response.json();
      // Initialize Razorpay
      const razorpay = new window.Razorpay({
        key_id: process.env.REACT_APP_RAZORPAY_KEYID, // Replace with your Razorpay key
        currency: 'INR',
        name: 'BMS Global',
        description: 'Payment for Order ID: ' + orderDetails.receipt,
        order_id: orderDetails.id,
        handler: async function (response) {
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          // console.log(response);
          handleToggleL();
          const resp  = await ApiCall.saveRpPaymentStatus(orderDetails.receipt, response);
          if(resp.status === 200 && resp.subscription){
            // navigate('/app/account');
            handleToggleL();
            window.location.href = process.env.REACT_APP_BASE_URL + '/app/account';
          }
        },
        prefill: {
          name: user.name, // Customer's name
          email: user.email, // Customer's email
          contact: user.phone // Customer's phone number
        },
        theme: {
          color: '#3399cc' // Theme color
        },
        "options": {
          "checkout": {
            "method": {
              "netbanking": "1",
              "card": "1",
              "upi": "0",
              "wallet": "0"
            }
          }
        },
        modal: {
          ondismiss: function() {
              // console.log("Payment window closed without success");
              // delete the initiated order details orderDetails.receipt
              ApiCall.deleteOrderByCustomId(orderDetails.receipt);
              document.body.style.overflow = 'auto';
              // Handle payment window dismissal
          }
      },
      "payment.failed": function (response){
          console.log(response.error.description);
          // Handle payment failure
          // alert("Payment failed: " + response.error.description);
      }
      });

      // Open Razorpay checkout form
      razorpay.open();
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
  };
  const styleWithLineThrough = {
    textDecoration: 'line-through',
    color: 'red'
  };
  const styleForDiscPrice = {
    color: 'green'
  };
  const handleDialogClose = async() => {
    setCouponCode('');
    setdiscountedPrice('');
    setdisableCCodeInp(false);
    handleClose();
  }
  const handleConfirmPayment = async() => {
    // Payment logic here
    // console.log('Payment confirmed for amount:',planId, amount, 'using gateway:', selectedGateway);
    const resp  = await ApiCall.placeOrder(planId, couponCode);
    if(resp.status === 200 && resp.subscription){
      handlePayment(resp.subscription, getLoggedinUserData)
    }
    setCouponCode('');
    setdiscountedPrice('');
    setdisableCCodeInp(false);
    handleClose();
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={openL}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Payment Details</DialogTitle>
        <DialogContent>
          <p><b>Amount:</b>
            {!disableCCodeInp && <span>₹{amount}</span>}
            {disableCCodeInp && (
            <>
              <span style={styleWithLineThrough}>₹{amount}</span>
              <span style={styleForDiscPrice}> ₹{Math.round(discountedPrice)}</span>
            </>
            )}
          </p>
          <p><b>Tax & charges:</b>
          {!disableCCodeInp && <span>₹{Math.round((amount * 0.05))}</span>}
            {disableCCodeInp && (
            <>
              <span> ₹{Math.round((discountedPrice * 0.05))}</span>
            </>
            )}
          </p>
          <FormControl fullWidth>
            <InputLabel>Select Payment Gateway</InputLabel>
            <Select
              value={selectedGateway}
              onChange={handleGatewayChange}
            >
              <MenuItem value="razorpay">RazorPay</MenuItem>
              {/* <MenuItem value="paytm">Paytm</MenuItem> */}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            label="Coupon"
            value={couponCode}
            onChange={handleCouponChange}
            fullWidth
            disabled={disableCCodeInp}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* Render the clear button only if there is input value */}
                  {couponCode && (
                    <ClearIcon
                      onClick={handleClearInput}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleApplyCoupon} color="primary" disabled={disableCCodeInp}>
            Apply Coupon
          </Button>
          <Button onClick={handleConfirmPayment} color="primary" disabled={!selectedGateway}>
            Confirm Payment
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

PaymentDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    amount: PropTypes.number,
    planId: PropTypes.string
};

export default PaymentDialog;
