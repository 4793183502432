/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import QRCode from 'react-qr-code';
import { useSocket } from 'src/contexts/SocketContext';
import WorkApplicationContext from 'src/contexts/WorkApplicationContext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Import WhatsApp icon

const QRDialogButton = (params) => {
  const { formId } = params;
  const workApplicationContext = useContext(WorkApplicationContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setloadingMessage] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [phoneLoggedin, setphoneLoggedin] = useState('');
  // Define a theme with custom primary and secondary colors to resemble WhatsApp's colors
  const socket = useSocket();
  
  useEffect(() => {
    if(sessionId === '') {
      setLoading(true);
      socket.emit('checkauth', {id: formId});
    }
    socket.on("authstatus", (data) => {
        // console.log(data);
        const { status, id, phone } = data;
        setphoneLoggedin(phone);
        if(status === '') {
            setSessionId('');
            workApplicationContext.updateWaConnect(false);
        }
        if (status === "CONNECTED" && id === formId) {
            setSessionId(formId);
            setphoneLoggedin(phone);
            workApplicationContext.updateWaConnect(true);
        }
        setLoading(false);
    });
    socket.on("qr", (qr) => {
        // console.log(qr);
        setQrCode(qr);
        setLoading(false);
    });
    socket.on("ready", (data) => {
        // console.log(data);
        const { id } = data;
        setSessionId(id);
        workApplicationContext.updateWaConnect(true);
        setLoading(false);
        setOpen(false);
    });
    socket.on("loading_screen", (data) => {
        // console.log(data);
        const { id, percent, message } = data;
        if (id === formId) {
            setloadingMessage(message +' '+percent+'%');
        }
        if(percent >= 100){
            setloadingMessage('');
        }
    });
    socket.on("allchats", (chats) => {
        // console.log(chats);
        setLoading(false);
    })
  }, []);
  const handleOpen = () => {
    if (socket) {
      setLoading(true);
      // Listen for user status changes from the server
      socket.emit('createwhatsappSession', {id: formId});
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getAllChats = () => {
    setLoading(true);
    socket.emit('getAllChats', {id: sessionId});
  };
  return (
    <div>
        {sessionId ?
        (
            !loading && 
            (<Button variant="contained" disabled color="primary">
                <WhatsAppIcon style={{ color: '#25D366' }}/>
                <Typography variant="body2" color="textSecondary" component="span">{phoneLoggedin}</Typography>
             </Button>)
        ) : (
            !loading ? 
            (<Button variant="contained" size="small" color="primary" onClick={handleOpen}>
              Connect WhatsApp
            </Button>) : (
             <Typography>loading</Typography>
             )
        )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>QR Code</DialogTitle>
        <DialogContent>
          {qrCode && <QRCode value={qrCode} />}
          {loading && 'please wait!'}
          {loadingMessage && loadingMessage}
          {/* Replace 'https://example.com' with your URL or data for the QR code */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QRDialogButton;
