import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import NetworkStatus from './components/Network/networkStatus';

import App from './App';
import { SocketProvider } from './contexts/SocketContext';

ReactDOM.render((
  <SocketProvider>
    <BrowserRouter scrollToTop>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
          <script data-ad-client="ca-pub-4645266536536108" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />
        </Helmet>
        <App />
        <NetworkStatus />
      </div>
    </BrowserRouter>
  </SocketProvider>
), document.getElementById('root'));

serviceWorker.unregister();
