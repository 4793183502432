import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  // Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { Link as RouterLink } from 'react-router-dom';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import BusinessIcon from '@material-ui/icons/Business';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  },
  label: {
    backgroundColor: 'white'
  }
}));

const Offices = ({ className, officeList, ...rest }) => {
  const classes = useStyles();
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();

  return (
    <>
      <Grid
        item
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >
        <Typography
          color="textSecondary"
          gutterBottom
          variant="h3"
          style={{
            color: 'Green', backgroundColor: 'white', padding: '5px'
          }}
        >
          My Offices:
        </Typography>
      </Grid>
      {officeList.length === 0 && (
        <Grid
          item
          lg={3}
          md={6}
          xl={3}
          xs={12}
        >
          <Card
            className={clsx(classes.root, className)}
            {...rest}
          >
            <CardContent>
              You have not access to any office.
            </CardContent>
          </Card>
        </Grid>
      )}
      {officeList.map((office) => (
        <Grid
          item
          lg={3}
          md={6}
          xl={3}
          xs={12}
        >
          <RouterLink to={getLoggedinUserData.role !== 'user' ? `/app/office-details/${office.id}` : '#'}>
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card
                className={clsx(classes.root, className)}
                {...rest}
              >
                <CardContent>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                    key={uuid()}
                  >
                    <>
                      <Grid item key={uuid()}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h3"
                        >
                          {office.name}
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="h6"
                        >
                          {office.desc}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Avatar className={classes.avatar}>
                          <BusinessIcon />
                        </Avatar>
                      </Grid>
                    </>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </RouterLink>
        </Grid>
      ))}
    </>
  );
};

Offices.propTypes = {
  className: PropTypes.string,
  officeList: PropTypes.array
};

export default Offices;
