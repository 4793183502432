import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Link,
  Typography,
  makeStyles,
  // InputAdornment
} from '@material-ui/core';
import Page from 'src/components/Page';
// import PhoneInput from 'react-phone-input-2';

// import CustomPhoneNumber from 'src/components/PhoneNumber.Jsx';
// import 'yup-phone';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ForgotPwdView = () => {
  const { message } = useParams();
  const classes = useStyles();
  // validate phone number loosely in the given region
  // const phoneSchema = Yup.string()
  //   .phone('IN')
  //   .required();
  // (async () => {
  //   console.log(await phoneSchema.isValid('+919876543210')); // → true
  // })();
  // const phoneRegExp = /^[0-9]{2}[0-9]{8}$/;
  return (
    <Page
      className={classes.root}
      title="Password reset Link"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {message}
            </Typography>
          </Box>
          <Box
            mt={3}
            mb={1}
          >
            {/* <Typography
              align="center"
              color="textSecondary"
              variant="body1"
            >
            </Typography> */}
          </Box>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            Don&apos;t have an account?
            {' '}
            <Link
              component={RouterLink}
              to="/register"
              variant="h6"
            >
              Sign up
            </Link>
          </Typography>
        </Container>
      </Box>
    </Page>
  );
};

export default ForgotPwdView;
