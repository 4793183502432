/* eslint-disable */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';

const LogOutComp = async () => {
  const navigate = useNavigate();
    console.log('logout called');
    async function logout() {
      await inLocalStorageJwt.logout();
      navigate('/login');
    }
    await logout();
};

export default LogOutComp;
