/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactPlayer from 'react-player';
import { findDOMNode } from 'react-dom';
// import axios from 'axios';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  IconButton,
  Tooltip
  // Button
} from '@material-ui/core';
import axios from 'axios';
import Button from 'src/components/CustomButtons/Button';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import Duration from 'src/components/VideoPlayer/Duration'
// import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
// import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import ViewIcon from '@material-ui/icons/Visibility';
// import EditIcon from '@material-ui/icons/Edit';
// import { toast } from 'react-toastify';
import moment from 'moment';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';
import Soundbar from './Soundbar';
import TaskContext from 'src/contexts/TaskContext';
import { useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
// import TrainingContext from 'src/contexts/TrainingContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  largeIcon: {
    width: 60,
    height: 60,
  },
}));
// Random component
const Completionist = () => <span>Your Task is Expired!</span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {days}:{hours}:{minutes}:{seconds}
      </span>
    );
  }
};

// const handlePlay = (e,taskName) => {
//   console.log("playe called", taskName);
  
// }

// const handlePause = (e,taskName) => {
//   console.log("pause called", taskName);
// }
const TaskCard = ({ className, task, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const playerRef = React.createRef();
  const [playerObj, setPlayerObj] = React.useState(null);
  let forwardseek = false;
  // const [screenWidth, SetScreenWidth] = React.useState(window.innerWidth);
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const [seekCheck, setSeekCheck] = useState({
    pause: false,
    buffer: false,
    play: false
  });
  const [playingStatus, SetPlayingStatus] = useState({
    lastPlayed: 0,
    currentPlaying: 0
  });
  const taskstatusPost = ['expired', 'completed'];
  const AuthToken = AuthTokenObject.authToken;
  const taskContext = useContext(TaskContext);
  const [playedFormated, SetplayedFormated] = useState('');
  const [playingPath, SetPlayingPath] = useState([]);
  const [seeking, setSeeking] = useState();
  const [fullscreenMode, setFullscreenMode] = useState(false);

  const [nextStatus, SetnextStatus] = useState(
    task.status === 'pending' ? 'started': (task.status === 'started' ? 'completed': '')
  );
  // volume bar change
  const volumebar = (newValue) => {
    SetReactControl({...reactControl, volume:newValue/100});
  }
  const handleMute = () => {
    SetReactControl({...reactControl, muted: !reactControl.muted });
  }
  
  const handleCustomFullScreen = () => {
    if (!fullscreenMode){
      // console.log("hdghfhghfshfh");
        findDOMNode(playerRef.current).requestFullscreen().catch(
            (err) => 
            {toast.error("Could not activate full-screen mode :(")}
        );
    }
  }

  const seekToSeconds = (seconds) => {
    playerObj.seekTo(seconds, 'seconds');
  }
  //custom control code
  const [reactControl, SetReactControl] = useState({
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: true,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false
  });
  // const handleSeekMouseDown = (e) => {
  //   SetReactControl({...reactControl, seeking:true});
  //   // this.setState({ seeking: true })
  // }

  // const handleSeekChange = (e) => {
  //   console.log('hello',e.target.value);
  //   SetReactControl({...reactControl, played:e.target.value});
  //   // this.setState({ played: parseFloat(e.target.value) })
  // }

  // const handleSeekMouseUp = (e) => {
  //   SetReactControl({...reactControl, seeking: false});
  //   SetReactControl({...reactControl, playing: reactControl.playing});
  //   // this.setState({ seeking: false })
  //   playerRef.current.seekTo(parseFloat(e.target.value));
  // }
  function format (seconds) {
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
  }
  
  function pad (string) {
    return ('0' + string).slice(-2)
  }
  const onReady =  (player) => {
    setPlayerObj(player);
  }
  const handlePlay = () => {
    // console.log('onPlay', playerRef.current.getCurrentTime() );
    let object = `${task.title} is played from ${format(playerRef.current.getCurrentTime())} / ${format(playerRef.current.getDuration())}`;
    // object += ` (video is )${playerRef.current.props.muted? 'muted': 'audible'}`;
    const play_pause_time = format(playerRef.current.getCurrentTime()) + '/' + format(playerRef.current.getDuration());
    SetPlayingStatus({...playingStatus, currentPlaying: playerRef.current.getCurrentTime()});
    // ApiCall.createActivity(getLoggedinUserData.id,'video_play_at', 'Task', object, task.id, play_pause_time);
    // setSeekCheck({play: true, buffer: false, pause: false});
    SetReactControl({...reactControl, playing: true });
    SetPlayingPath(playingPath => [...playingPath, 'play']);
  }
  const handlePause = () => {
    console.log('onPause', playerRef.current.getCurrentTime() );
    let object = `${task.title} is paused at ${format(playerRef.current.getCurrentTime())} / ${format(playerRef.current.getDuration())}`;
    const play_pause_time = format(playerRef.current.getCurrentTime()) + '/' + format(playerRef.current.getDuration());
    // console.log('ref', format(playerRef.current.getCurrentTime()));
    // SetPlayingStatus({...playingStatus,lastPlayed: playerRef.current.getCurrentTime()});
    // ApiCall.createActivity(getLoggedinUserData.id,'video_pause_at', 'Task', object, task.id,play_pause_time);
    // setSeekCheck({play: false, buffer: false, pause: true});
    SetReactControl({...reactControl, playing: false });
    SetPlayingPath(playingPath => [...playingPath, 'pause']);
  }
  const handleProgress = (st) => {
    // console.log('inprogress:', playingPath);
    // We only want to update time slider if we are not currently seeking
      reactControl.seeking = false;
      // reactControl.forward = false;
      // reactControl.backward = false;
    if (!reactControl.seeking) {
      // this.setState(state)
      // if(playingStatus.lastPlayed !== playingStatus.currentPlaying) {
      //   console.log("hello");
      //   if(diff > 1) {
      //     reactControl.seeking = true;
      //     reactControl.forward = true;
      //     const statement = 'forwarded from '+ format(playingStatus.lastPlayed) +' to '+ format(playingStatus.currentPlaying)
      //     console.log(statement);
      //   }
      //   if(diff < 0) {
      //     reactControl.seeking = true;
      //     reactControl.backward = true;
      //     const statement = 'seeking back to '+ format(playingStatus.currentPlaying) +' from '+ format(playingStatus.lastPlayed)
      //     console.log(statement);
      //   }
      // }
      const playFlag = playerRef.current.props.playing;
      // console.log("playing:", forwardseek);
      if(playingPath.indexOf('pause') === 0 && playingPath.indexOf('buffer') === 1){
        // SetPlayingStatus({...playingStatus, currentPlaying: st.playedSeconds});
        setSeekCheck({play: false, buffer: false, pause: false});
        // console.log('seeking', true);
        forwardseek = true;
        setSeeking(true);
      } else if (playingPath.indexOf('pause') < 0 && playingPath.indexOf('play') < 0 && playFlag === false){
        // console.log('seeking', true);
        // SetPlayingStatus({...playingStatus, currentPlaying: st.playedSeconds});
        setSeekCheck({play: false, buffer: false, pause: false});
        setSeeking(true);
        forwardseek = false;
      } else if (playingPath.indexOf('pause') === 0 && playingPath.length === 1 ) {
        // SetPlayingStatus({...playingStatus, currentPlaying: st.playedSeconds});
        forwardseek = false;
        setSeekCheck({play: false, buffer: false, pause: true});
        setSeeking(false);
      } else if (playingPath.indexOf('play') > -1 && forwardseek === false) {
        // SetPlayingStatus({...playingStatus, lastPlayed: st.playedSeconds, currentPlaying: st.playedSeconds});
        setSeekCheck({play: true, buffer: false, pause: false});
        setSeeking(false);
      }
      if(playFlag === true){
        // console.log("play----");
        SetPlayingStatus({...playingStatus, lastPlayed: st.playedSeconds, currentPlaying: st.playedSeconds});
      } else if(playFlag === false) {
        // console.log("pause----");
        SetPlayingStatus({...playingStatus, currentPlaying: st.playedSeconds});
      }
      SetReactControl({...reactControl, ...st});
    }
    SetPlayingPath([]);
  }
  const handleBuffer = (e) => {
    // console.log("buffer", e);
    SetPlayingPath(playingPath => [...playingPath, 'buffer']);
    // setSeekCheck({...seekCheck, buffer: true});
  }
  useEffect(() => {
    const diff = playingStatus.currentPlaying - playingStatus.lastPlayed;
    // console.log("d", seekCheck, seeking );
    if(seekCheck.play === true && seeking === false) {
      const object = `Played at ${format(playerRef.current.getCurrentTime())} / ${format(playerRef.current.getDuration())}`;
      const play_pause_time = format(playerRef.current.getCurrentTime()) + '/' + format(playerRef.current.getDuration());
      ApiCall.createActivity(getLoggedinUserData.id,'video_play_at', 'Task', object, task.id,play_pause_time);
      // console.log('played at ', play_pause_time);
    }

    if(seekCheck.pause === true && seeking === false) {
      const object = `Paused at ${format(playerRef.current.getCurrentTime())} / ${format(playerRef.current.getDuration())}`;
      const play_pause_time = format(playerRef.current.getCurrentTime()) + '/' + format(playerRef.current.getDuration());
      ApiCall.createActivity(getLoggedinUserData.id,'video_pause_at', 'Task', object, task.id,play_pause_time);
      // console.log('paused at ', play_pause_time);
    }
  }, [seekCheck, seeking]);
  useEffect(() => {
    if (!reactControl.seeking) {
    const diff = playingStatus.currentPlaying - playingStatus.lastPlayed;
    
    if(playingStatus.lastPlayed !== playingStatus.currentPlaying) {
      if(diff > 1) {
        // seekCheck.seeking = true;
        // reactControl.forward = true;
        const object = 'Forwarded from '+ format(playingStatus.lastPlayed) +' to '+ format(playingStatus.currentPlaying);
        SetPlayingStatus({...playingStatus, lastPlayed: playingStatus.currentPlaying});
        const play_pause_time = format(playingStatus.lastPlayed) + '/' + format(playingStatus.currentPlaying) + '/' + format(playerRef.current.getDuration());
        ApiCall.createActivity(getLoggedinUserData.id,'video_forward_at', 'Task', object, task.id,play_pause_time);
      } else if(diff < 0) {
        // reactControl.backward = true;
        // const statement = 'seeking back to '+ format(playingStatus.currentPlaying) +' from '+ format(playingStatus.lastPlayed);
        SetPlayingStatus({...playingStatus, lastPlayed: playingStatus.currentPlaying});
        const object = 'Seek back to '+ format(playingStatus.currentPlaying) +' from '+ format(playingStatus.lastPlayed);
        SetPlayingStatus({...playingStatus, lastPlayed: playingStatus.currentPlaying});
        const play_pause_time = format(playingStatus.lastPlayed) + '/' + format(playingStatus.currentPlaying) + '/' + format(playerRef.current.getDuration());
        ApiCall.createActivity(getLoggedinUserData.id,'video_backward_at', 'Task', object, task.id,play_pause_time);
      } else {
        // console.log('else',diff);
        setSeeking(false);
      }
    }
  }
  }, [playingStatus]);
  const handleDuration = (duration) => {
    // console.log('onDuration', format(duration));
    SetReactControl({...reactControl, duration: duration });
  }
  // const handleStateChange = () => {
  //   console.log("state changed");
  // }
  const handleEnded = () => {
    // console.log('onEnded', format(reactControl.duration * reactControl.played));
    let object = `${task.title} is ended at ${format(playerRef.current.getCurrentTime())} / ${format(playerRef.current.getDuration())}`;
    const play_pause_time = format(playerRef.current.getCurrentTime()) + '/' + format(playerRef.current.getDuration());
    ApiCall.createActivity(getLoggedinUserData.id,'video_ended_at', 'Task', object, task.id, play_pause_time);
    SetReactControl({...reactControl, playing: reactControl.loop });
  }
  const handleToggleMuted = () => {
    SetReactControl({...reactControl, muted: !reactControl.muted });
  }
  // const handlePlayPause = () => {
  //   console.log("jhsghfghsf");
  //   SetReactControl({...reactControl, playing: !reactControl.playing });
  // }
  // SetnextStatus(task.status === 'started' ? 'completed':'');
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  async function updateStatus(taskId,status) {
    await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/tasks/update-status/${taskId}/${status}`, [], options)
      .then(async (res) => {
        // console.log(res.data);
        taskContext.fetchTasks();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // async function createActivity(activity, module, object) {
  //   // console.log(getLoggedinUserData);
  //   console.log(getLoggedinUserData.id, activity, module, object);
  // }
  useEffect(() => {
    SetnextStatus(
    task.status === 'pending' ? 'started': (task.status === 'started' ? 'completed': '')
    )
  }, [task]);
  // useEffect(() => {
  //   // console.log(reactControl);
  //   if(reactControl.playing===true) {
  //     createActivity('video_play_at', 'task.title played on'+ format(reactControl.duration * reactControl.played));
  //   }
  //   if(reactControl.playing===false) {
  //     createActivity('video_pause_at', 'task.title paused on'+ format(reactControl.duration * reactControl.played));
  //   }
  // }, [reactControl.played]);

  useEffect(() => {
    if(new Date(task.deadline).getTime() < new Date().getTime() && task.status !== 'expired'){
      // console.log(task.deadline);
      updateStatus(task.id, 'expired');
    }
  }, []);
  // useEffect(() => {
  //   SetScreenWidth(window.innerWidth);
  // }, [window]);
  return (
    <Card
      style={{margin:'0px', backgroundColor: taskstatusPost.indexOf(task.status) < 0? '#59e8e2':'white'}}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <div
        className="Iframecontainer"
        style={{
          width: '100%',
          // border: '1px solid black',
          position: 'relative'
        }}
      >
        <div
          style={{
            // border: '1px solid black',
            // width: screenWidth>958? '38%': '100%', 
            width: '100%',
            height: '100px',
            overflow: 'hidden',
            'position': 'absolute'
          }}
        />
        <div
          style={{
            // border: '1px solid black',
            right: '0',
            width: '20%', 
            height: '200px',
            overflow: 'hidden',
            'position': 'absolute'
          }}
        />
        <div
          className="player-wrapper"
          style={{ pointerEvents: 'none' }}
        >
          <ReactPlayer
            ref={playerRef}
            width="100%"
            height="350px"
            onContextMenu={(e) => { e.preventDefault(); }}
            controls={false}
            url={task.type.video_link}
            playing={reactControl.playing}
            muted={reactControl.muted}
            onPlay={handlePlay}
            volume={reactControl.volume}
            onReady={onReady}
            // onPlay={(e) => { console.log('play', e); }}
            onPause={handlePause}
            onBuffer={handleBuffer}
            onProgress={handleProgress}
            onDuration={handleDuration}
            onEnded={handleEnded}
            // played={format(reactControl.duration * reactControl.played)}
            onClick={(e)=> {console.log('click');}}
            onSeek={(e) => {console.log('onSeek', e);}}
            // onStateChange={handleStateChange}
            // playbackQuality={suggestedQuality}
            // muted={reactControl.muted}
            config={{
              file: { attributes: { controlsList: 'nodownload' } },
              youtube: {
                playerVars: {
                  // showinfo: 1,
                  // enablejsapi:1
                },
                events: {
                  onStateChange: () => {console.log('hhhh')}
                }
              }
            }}
          />
        </div>
      </div>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Soundbar
              volumebar={volumebar}
              handleMute={handleMute}
              handlePlay={handlePlay}
              handlePause={handlePause}
              handleCustomFullScreen={handleCustomFullScreen}
              playingStatus={playingStatus}
              duration={reactControl.duration}
              handleBuffer={handleBuffer}
              seekToSeconds={seekToSeconds}
            />
          </Grid>
        </Grid>
      </div>
      {/* <div>
        <input
          // title={reactControl.played}
          style={{width:'100%'}}
          type='range' min={0} max={0.999999} step='any'
          value={reactControl.played}
          onMouseDown={handleSeekMouseDown}
          onChange={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
        />
        {/* <Nouislider range={{ min: 0, max: 0.999999 }} start={reactControl.played} /> */}
        {/*<Tooltip title={reactControl.playing === false? 'Play' : 'Pause'}> 
        <IconButton
          aria-label={reactControl.playing === false? 'play' : 'pause'} 
          style={{backgroundColor:'transparent'}}
          className={classes.largeIcon} onClick={reactControl.playing === false? handlePlay : handlePause} >
          {/* <PlayCircleOutlineIcon /> */}
          {/* {
            reactControl.playing === false
            ? <PlayCircleFilledIcon />
            : <PauseCircleFilledIcon />
          }
        </IconButton>
        </Tooltip>
        
        <Tooltip title={reactControl.muted === true? 'Unmute' : 'Mute'}> 
        <IconButton
          aria-label={reactControl.muted === true? 'Unmute' : 'Mute'} 
          style={{backgroundColor:'transparent'}}
          className={classes.largeIcon} onClick={handleToggleMuted} >
          {/* <PlayCircleOutlineIcon /> */}
          {/* {
            reactControl.muted === true
            ? <VolumeOffIcon />
            : <VolumeUpIcon />
          }
        </IconButton>
        </Tooltip>
        <Duration seconds={reactControl.duration * reactControl.played} />
         <Duration seconds={reactControl.duration} />
      </div> */} 
      <CardContent>
        {/* <Box
          display="flex"
          justifyContent="center"
          mb={3}
        >
          <Avatar
            alt="Thumbnail"
            src={process.env.REACT_APP_SERVER_BASE_URL + task.type.thumbnail}
            variant="square"
          />
        </Box> */}
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {task.type.name}
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
          {`By ${task.type.trainer}`}
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
          {task.type.notes}
        </Typography>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
        >
          {/* <Grid
            className={classes.statsItem}
            item
          >
            <Typography
              color="textSecondary"
              display="inline"
              variant="body2"
            >
              <Button onClick={() => navigate(`/app/training/update?trainingid=${training.id}`)}>
                <EditIcon
                  className={classes.statsIcon}
                  color="action"
                />
                Edit
              </Button>
            </Typography>
          </Grid> */}
          <Grid
            className={classes.statsItem}
            item
          >
            <Typography
              color="textSecondary"
              display="inline"
              variant="body2"
            >
              {/* <Button onClick={() => console.log(task.id)}>
                <ViewIcon
                  className={classes.statsIcon}
                  color="action"
                />
                View
              </Button> */} 
              <b>Time Remaining:(D:H:M:S) </b>
              <Countdown
                // onTick={(e) => { console.log(e); }}
                date={new Date(task.deadline)} renderer={renderer}/>
            </Typography>
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >
            <Typography
              color="textSecondary"
              display="inline"
              variant="body2"
            >
              <b>Task Deadline:</b> {moment(task.deadline).format('MMM-DD, YYYY, (hh:mm A)')}
            </Typography>
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >
            <Typography
              color="textSecondary"
              display="inline"
              variant="body2"
            >
              <b>Assigned By:</b> {task.createdBy.fname +' '+ task.createdBy.lname}
            </Typography>
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >
            <b>Status:</b> {task.status}
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >
            {
              task.status !== 'completed' && task.status !== 'expired' && nextStatus
              && (
                  <Button
                    style={{backgroundColor: (nextStatus==='started'? '#2C397E': '#44A048')}}
                    variant="outlined"
                    onClick={() => {
                      updateStatus(task.id, nextStatus);
                    }}
                  >
                    <span> Change Status to: I have {nextStatus}.</span>
                  </Button>
              )
            }
          </Grid>
        </Grid>
      </Box>
      {/* <Divider />
      <Box p={2}>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
        >
          <Grid
            className={classes.statsItem}
            item
          >
            <MusicCard />
          </Grid>
        </Grid>
      </Box> */}
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-format="fluid"
        data-ad-layout-key="-f3-d+1d-41+70"
        data-ad-client="ca-pub-4645266536536108"
        data-ad-slot="6734199001"
      />
      <script>
        (adsbygoogle = window.adsbygoogle || []).push(
        {}
        );
      </script>
    </Card>
  );
};

TaskCard.propTypes = {
  className: PropTypes.string,
  task: PropTypes.object.isRequired
};

export default TaskCard;
