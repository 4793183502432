/* eslint-disable */
import axios from 'axios';
import { toast } from 'react-toastify';
import inLocalStorageJwt from './inLocalStorageJwt';

const groupDetails = async (groupId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/groups/view/${groupId}`, options);
    // toast.success(`${response.data.message}`, {
    //   position: 'top-right',
    //   autoClose: 10000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
    return { status: 200, group: response.data.group, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, group: '', message: error.response.data.message };
  }
};

const createOffice = async (officeBody) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/offices/create`, officeBody, options);
    toast.success(`${response.data.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 200, office: response.data.office, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, office: '', message: error.response.data.message };
  }
};

const editOffice = async (officeBody) => {
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
    const postBody = {
        name: officeBody.name,
        address: officeBody.address,
        desc: officeBody.desc,
        status: officeBody.status
    }
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/offices/edit/${officeBody.id}`, postBody, options);
      toast.success(`${response.data.message}`, {
        position: 'top-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return { status: 200, office: response.data.office, message: response.data.message };
    } catch (error) {
      console.error(error);
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return { status: 400, office: '', message: error.response.data.message };
    }
  };

const officeList = async (groupId, fields) => {
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
    const { skip, limit, name, address, desc  } = fields;
    let searchQuery = `?skip=${skip}&limit=${limit}`;
    if(name) {
        searchQuery += '&'+name;
    }
    if(address) {
        searchQuery += '&'+address;
    }
    if(desc) {
        searchQuery += '&'+desc;
    }
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/offices/list/${groupId}${searchQuery}`, options);
    //   toast.success(`${response.data.message}`, {
    //     position: 'top-right',
    //     autoClose: 10000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
      return { status: 200, offices: response.data.result.offices, message: response.data.message };
    } catch (error) {
      console.error(error);
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return { status: 400, offices: '', message: error.response.data.message };
    }
  };
export default {
  groupDetails,
  createOffice,
  officeList,
  editOffice
};
