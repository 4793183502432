import React, { useState, useContext } from 'react';
import {
  Formik, Field, Form, ErrorMessage
} from 'formik'; // Import Formik components
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PersonIcon from '@material-ui/icons/Person';
import { toast } from 'react-toastify';
import WorkApplicationContext from 'src/contexts/WorkApplicationContext';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red'
  },
  openBtn: {
    height: '56px'
  }
}));
const ContactForm = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const workApplicationContext = useContext(WorkApplicationContext);

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Define a validation schema using Yup
  const validationSchema = Yup.object().shape({
    // name: Yup.string().required('Name is required'),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
      .required('Phone is required'),
  });

  return (
    <div>
      <Button style={{ fontSize: isMobile ? '12px' : '14px', height: '40px' }} size="small" variant="outlined" color="primary" className={classes.openBtn} onClick={handleClickOpen}>
        <AddCircleIcon />
        <PersonIcon />
        Add
      </Button>
      <Dialog open={open} onClose={() => {}}>
        <DialogTitle>Contact Form</DialogTitle>
        <DialogContent>
          {/* Wrap the form with <Formik> */}
          <Formik
            initialValues={{
              name: '',
              phone: '',
              app_mode: 'custom_contact'
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values);
              axios
                .patch(`${process.env.REACT_APP_API_BASE_URL}/formdata/save-custom-contact`, values, options)
                .then((response) => {
                  // console.log('Form submitted:', response.data);
                  workApplicationContext.refreshPage();
                  toast.success(`${response.data.message}`, {
                    position: 'top-right',
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  handleClose();
                })
                .catch((error) => {
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error(`${error.response.data.message}`, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest
                    // in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error);
                  }
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div>
                  <Field
                    as={TextField}
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    margin="normal"
                  />
                  <ErrorMessage name="name" component="div" className={classes.error} />
                </div>
                <div>
                  <Field
                    as={TextField}
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone"
                    variant="outlined"
                    margin="normal"
                  />
                  <ErrorMessage name="phone" component="div" className={classes.error} />
                </div>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ContactForm;
