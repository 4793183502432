import styled from 'styled-components';

export const ZoomedDataTable = styled.div`
  transform: scale(${(props) => props.zoomLevel});
  transform-origin: left top;
  transition: transform 0.3s;
`;

export const DataTableContainer = styled.div`
  overflow: auto;
  height: auto; /* Adjust the height as needed */
`;

export const ZoomButton = styled.button`
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
`;
