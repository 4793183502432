/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react';
import {
    Button,
    Grid,
    makeStyles,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Box
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      }
    },
    textarea: {
      resize: 'both'
    },
    radioLabel: {
      margin: theme.spacing(1)
    }
  }));
export default function FormDialog({ className, taskId, ...rest }) {
  const [open, setOpen] = React.useState(false);
  const formikRef = useRef();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Create Pass
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText>
            <Formik
              innerRef={formikRef}
              initialValues={{
                name: '',
                contact: '',
                email: '',
                meeting_id: '',
                hosts: new Array()
            }}
              validationSchema={Yup.object().shape({
                name: Yup.string().max(255).required('Name is required'),
                contact: Yup.string().max(255).required('Contact is required'),
                email: Yup.string().max(255).required('Email is required'),
                meeting_id: Yup.string().max(255).required('Meeting is required'),
                hosts: Yup.date().required('Hosts are required')
            })}
              onSubmit={((values, { setSubmitting }) => {
                const postData = {
                name: values.name,
                contact: values.contact,
                email: values.email,
                meeting_id: values.meeting_id,
                hosts: values.hosts,
                };
                // const response = await axios.get(
                //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
                // );
                // if (taskId) {
                // axios.patch(`${process.env.REACT_APP_API_BASE_URL}/tasks/update/${taskId}`, postData, options)
                //     .then(async (res) => {
                //     // setReportList(res.data.dsreports);
                //     // console.log(res.data);
                //     setSubmitting(false);
                //     toast.success(`${res.data.message}`, {
                //         position: 'top-right',
                //         autoClose: 10000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //     });
                //     navigate('/app/tasks', { replace: true });
                //     })
                //     .catch((error) => {
                //     console.log(error);
                //     setSubmitting(false);
                //     toast.error(`${error.response.data.message}`, {
                //         position: 'top-right',
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //     });
                //     });
                // } else {
                axios.post(`${process.env.REACT_APP_API_BASE_URL}/tasks/create`, postData, options)
                    .then(async (res) => {
                    // setReportList(res.data.dsreports);
                    // console.log(res.data);
                    setSubmitting(false);
                    toast.success(`${res.data.message}`, {
                        position: 'top-right',
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate('/app/tasks', { replace: true });
                    })
                    .catch((error) => {
                    console.log(error);
                    setSubmitting(false);
                    toast.error(`${error.response.data.message}`, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    });
            })}
            >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                setFieldValue,
                values
            }) => (
                <form
                onSubmit={handleSubmit}
                autoComplete="off"
                noValidate
                className={clsx(classes.root, className)}
                {...rest}
                >
                <Card>
                    <CardHeader
                    subheader="Enter your pass details."
                    title="Assign the pass here."
                    />
                    <Divider />
                    <CardContent>
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                            item
                            md={6}
                            xs={12}
                            container
                            // justify="space-around"
                        >
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label="Candidate name"
                            name="name"
                            onChange={handleChange}
                            required
                            type="text"
                            onBlur={handleBlur}
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                         <TextField
                            error={Boolean(touched.contact && errors.contact)}
                            fullWidth
                            helperText={touched.contact && errors.contact}
                            label="Candidate contact"
                            name="contact"
                            onChange={handleChange}
                            required
                            type="text"
                            onBlur={handleBlur}
                            value={values.contact}
                            variant="outlined"
                         />
                        
                        </Grid>

                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Candidate email"
                            name="email"
                            onChange={handleChange}
                            required
                            type="text"
                            onBlur={handleBlur}
                            value={values.email}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                        item
                        md={6}
                        xs={12}
                        >
                        
                        </Grid>
                        <Grid
                        item
                        md={6}
                        xs={12}
                        >
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                        
                        </Grid>
                    </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                    display="flex"
                    justifyContent="flex-end"
                    p={2}
                    >
                    <Grid
                        item
                        style={{ margin: '3px' }}
                    >
                        <Button
                        // color="secondary"
                        // style={{ backgroundColor: '#21b6ae' }}
                        variant="contained"
                        onClick={() => { navigate('/app/tasks'); }}
                        >
                        Cancel
                        </Button>
                    </Grid>
                    <Grid
                        item
                        style={{ margin: '3px' }}
                    >
                        <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        >
                        Save details
                        </Button>
                    </Grid>
                    </Box>
                </Card>
                </form>
            )}
            </Formik>
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
