import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Link,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Search as SearchIcon } from 'react-feather';
import UserContext from 'src/contexts/UserContext';
import FilterMenu from './FilterMenu';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({
  className, field, gen, searchStr, ...rest
}) => {
  const classes = useStyles();
  const userContext = React.useContext(UserContext);

  const handleChange = async (event) => {
    const str = event.target.value;
    if (str.length > 2 || str.length === 0) {
      userContext.searchList(event.target.value);
      // console.log(userContext);
    }
  };
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        {/* <Button className={classes.importButton}>
          Import
        </Button>
        <Button className={classes.exportButton}>
          Export
        </Button> */}
        <Link
          to="/app/customer/create"
          component={RouterLink}
        >
          <Button
            color="primary"
            variant="contained"
          >
            Add Partner
          </Button>
        </Link>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    defaultValue={searchStr}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    onChange={handleChange}
                    placeholder="Search names"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FilterMenu fieldval={gen} />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string,
  gen: PropTypes.number,
  searchStr: PropTypes.string,
};

export default Toolbar;
