/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Card,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import Page from 'src/components/Page';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import GroupContext from 'src/contexts/GroupContext';
import ProductCard from './ProductCard';
import Toolbar from './Toolbar';
// import Scroll from './Scroll';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  Toolbar: {
    margin: '10px'
  },
}));

const ProductList = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([]);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
   const fetchGroups =  async (page, perPage) => {
    const offset = (page - 1) * perPage;
    setLoading(true);
    const postData = {
      createdBy: getLoggedinUserData.id,
      skip: offset,
      limit: perPage
    };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    var filters = `?createdBy=${getLoggedinUserData.id}&skip=${offset}&limit=${perPage}`;
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/groups${filters}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data.result);
        setData(res.data.data.groups);
        setTotalRows(res.data.data.totalGroups);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const groupContextVal = {
    fetchGroups
  };
  useEffect(() => {
    async function fetchData() {
      // You can await here
      await fetchGroups(1, 10);
    }
    fetchData();
  }, []); 

  return (
    <GroupContext.Provider value={groupContextVal}>
      <Page
        className={classes.root}
        title="Group & Members"
      >
        <Container maxWidth={false}>
          <Toolbar className={classes.Toolbar} />
          <Card>
            <Grid
              container
              spacing={3}
            >
              {data.length>0 && <ProductCard data={[...data]} />}
              {data.length===0 && <Typography>Loading</Typography>}
            </Grid>
          </Card>
        </Container>
      </Page>
    </GroupContext.Provider>
  );
};

export default ProductList;
