/*eslint-disable*/
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import ForumIcon from '@material-ui/icons/Forum';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Button from "src/components/CustomButtons/Button.js";
import styles from "src/assets/jss/material-kit-react/components/headerLinksStyle.js";
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from "@material-ui/core/styles";

const options = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/thebmsglobal/',
    icon: <FacebookIcon />
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/thebmsglobal/',
    icon: <InstagramIcon />
  },
  {
    name: 'Linkedin',
    link: 'https://www.linkedin.com/in/bms-global-980553252',
    icon: <LinkedInIcon />
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/BMSGLOBAL5?t=JX06_rsKVD0wIKMI-OZUWQ&s=09',
    icon: <TwitterIcon />
  },
  {
    name: 'Youtube',
    link: 'https://youtube.com/channel/UC-XFEweZ7039nJI8NNwZQXw',
    icon: <YouTubeIcon />
  }
];

const ITEM_HEIGHT = 48;
const useStyles = makeStyles(styles);

export default function LongMenu() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip
        id="instagram-tooltip"
        title="Our Social Links"
        placement={window.innerWidth > 959 ? 'top' : 'left'}
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.navLink}
        >
          <ForumIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.name} selected={option === 'Pyxis'} onClick={handleClose}>
            <Button
              color="transparent"
              href={option.link}
              target="_blank"
              className={classes.navLink}
            >
              {option.icon}
              {option.name}
            </Button>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
