/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Container,
  makeStyles,
  Grid,
  Button,
  Link
} from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
// import { useSocket } from 'src/contexts/SocketContext';
import ApiCall from 'src/utils/ApiCall';
import FilterMenu from './FilterMenu';
import UserContext from 'src/contexts/UserContext';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  },
  formExpndCard: {
    minWidth: 275,
    minWidth: 276,
    margin: '15px'
  },
  respHeader: {
    margin:'10px',
    padding: '10px',
    textAlign: 'center'
  }
}));

const List = (rest) => {
  const classes = useStyles();
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [data, setData] = useState([]);
  const [gen, setGen] = useState('my');
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);

  // const socket = useSocket();
// Function to find and replace status by viewer _id
// function findAndReplaceStatus(data, viewerId, newStatus) {
//   data.forEach((item) => {
//     const viewers = item.viewers;

//     for (let i = 0; i < viewers.length; i++) {
//       if (viewers[i]._id === viewerId) {
//         viewers[i].status = newStatus;
//         console.log(`Status for viewer with _id ${viewerId} has been updated to ${newStatus}`);
//       }
//     }
//   });
// }
// Function to count viewers with online status
function countOnlineViewers(viewers) {
  let onlineCount = 0;
    viewers.forEach((viewer) => {
      if (viewer.status === "Online") {
        onlineCount++;
      }
    });
  return onlineCount;
}
function countBackgroundViewers(viewers) {
  let onlineCount = 0;
    viewers.forEach((viewer) => {
      if (viewer.status === "Background") {
        onlineCount++;
      }
    });
  return onlineCount;
}
// Function to search for a specific _id.id value and return a boolean indicating if found
// function searchByIdId(arr, idToFind) {
//   const result = arr.filter(item => item._id.id === idToFind);
//   return result.length > 0; // Returns true if at least one match is found, otherwise false
// }
// Example usage: Replace the status for a specific viewer
  // useEffect(() => {
  //   if (socket) {
  //     // Listen for user status changes from the server
  //     socket.on('LiveTrainingViewerStatus', ({ trainingId, status }) => {
  //       // findAndReplaceStatus(data, viewerId, status);
  //       // setData(data);
  //       // console.log(trainingId, status);
  //       const found = searchByIdId(data, trainingId);
  //       if (found) {
  //         console.log("Item found!");
  //         getLiveViewers(currentPage);
  //       } else {
  //         console.log("Item not found.");
  //       }
  //     });
  //   }
  // }, [data]);
  const columns = [
    {
      name: <b style={{ fontSize: '15px' }}>Created By</b>,
      selector: '_id.createdBy',
      sortable: true,
      cell: (row) => <span>{row._id.createdBy? row._id.createdBy.fname+ ' '+ row._id.createdBy.lname: ''}</span>
    //   right: true,
    //   cell: (row) => moment(row.date).format('MMM D, YYYY')
    },
    {
      name: <b style={{ fontSize: '15px' }}>Training</b>,
      selector: '_id.name',
      sortable: true,
      cell: (row) => <span>{row._id.name? row._id.name : ''}</span>
    //   right: true,
    //   cell: (row) => moment(row.date).format('MMM D, YYYY')
    },
    {
      name: <b style={{ fontSize: '15px' }}>Live Status</b>,
      selector: 'viewers',
      sortable: true,
      cell: (row) => <ul>
                      <li style={{color:  'green'}}>{ row.onlineCount} Watching </li>
                      <li style={{color: 'red'}}> {row.backgroundCount} in Background</li>
                    </ul>
      //   right: true,
      //   cell: (row) => moment(row.date).format('MMM D, YYYY')
    }
  ];

  async function getLiveViewers(page) {
    setLoading(true);
    const lviewers = await ApiCall.findLiveViewersByTraining(getLoggedinUserData.id, page, perPage, gen);
    if(lviewers){
      setData(lviewers.hasOwnProperty("paginatedResults")?lviewers.paginatedResults:[]);
      setTotalRows(lviewers.totalCount[0].count);
    }
    setLoading(false);
  }

  const handlePageChange = (page) => {
    getLiveViewers(page);
  };

  const handlePageRefresh = () => {
    getLiveViewers(currentPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // setLoading(true);
    setPerPage(newPerPage);
    setCurrentPage(page);
    // await getLiveViewers(page);
    // setData(response.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getLiveViewers(1);
  }, [perPage]);
  useEffect(() => {
    getLiveViewers(currentPage);
  }, [gen]);
  // useEffect(() => {
  //   // Set up the interval to call the function every 10 seconds
  //   const intervalId = setInterval(() => {
  //     // Update the state or perform any other actions here
  //     getLiveViewers(currentPage);
  //   }, 60000); 
  //   // Clean up the interval when the component unmounts
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);
  const ExpandedRow = (row) => {
    const viewersOb = row.data.viewers;
    const bull = <span className={classes.bullet}>•</span>;
    return (<div className={classes.root}>
           <Grid container spacing={3}>
              <Card className={classes.formExpndCard}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className={classes.respHeader}>Name</Th>
                      <Th className={classes.respHeader}>Status</Th>
                      <Th className={classes.respHeader}>Last Updated </Th>
                      <Th className={classes.respHeader}>Action</Th>
                      {/* <Th className={classes.respHeader}>Notes</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {viewersOb.map((item) => (
                    <Tr key={Math.random()}>
                      <Td className={classes.respHeader} key={Math.random()}>{item.viewer.fname}</Td>
                      <Td className={classes.respHeader} key={Math.random()} style={{color: item.status == 'Online' ? 'green': 'red'}}>{ item.status }</Td>
                      <Td className={classes.respHeader}>{item.statusUpdatedAt? moment(item.statusUpdatedAt).format('MMM D, YYYY, h:mm:ss A') :  'NA'}</Td>
                      <Td className={classes.respHeader}>
                        { item.viewer ?
                          (<>
                            <Link
                              color="primary"
                              size="small"
                              component={RouterLink}
                              to={`/app/training/viewer/${item.ctyperef}/${item.viewer.id}?name=${item.viewer.fname}${' '}${item.viewer.lname?item.viewer.lname:''}`}
                            >
                              <IconButton color="primary" size="small" variant="contained">
                                <ViewIcon />
                              </IconButton>
                            </Link>
                          </>) : '' }
                        </Td>
                      {/* <Td className={classes.respHeader}>{applicationOb.notes}</Td> */}
                    </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Card>
           </Grid>
       </div>);
  };
  const updateGenSearch = (val) => {
    setGen(val);
  }
  const userDataContextVal = {
    updateGenSearch
  };
  const tableData = {
    columns,
    data
  };
  return (
    <UserContext.Provider value={userDataContextVal}>
      <Container maxWidth={false}>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item>
              <FilterMenu fieldval={gen} />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SyncIcon />} // Add the SyncIcon as a start icon
                onClick={handlePageRefresh}
                style={{ height: '100%' }}
                disabled={loading}
              >
                Refresh
              </Button>
            </Grid>
            {/* <Grid item>
              <ul>
                <li style={{color:  'green'}}>{ data.onlineCount} Watching </li>
                <li style={{color: 'red'}}> {data.backgroundCount} in Background</li>
              </ul>
            </Grid> */}
          </Grid>
          <DataTableExtensions {...tableData}>
            <DataTable
              // title="Viewers"
              columns={columns}
              data={data}
              paginationPerPage={Number(perPage)}
              currentPage={currentPage}
              // progressPending={loading}
              pagination
              filter
              paginationServer
              paginationTotalRows={totalRows}
              // selectableRows
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover
              expandableRows
              // expandableRowDisabled={row => row.disabled}
              expandableRowsComponent={<ExpandedRow />}
            />
          </DataTableExtensions>
        </Box>
      </Container>
     </UserContext.Provider>
  );
};

export default List;
