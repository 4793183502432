import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types'; // Import PropTypes
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  FormControl, InputLabel, Select, MenuItem, Container, makeStyles, Card,
  Box
} from '@material-ui/core';
import Page from 'src/components/Page';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // or any other date utility library
import AccessTimeIcon from '@material-ui/icons/AccessTime'; // Import the clock icon
import FormHelperText from '@material-ui/core/FormHelperText';
import MeetingApiCall from 'src/utils/MeetingApiCall';
import MeetingList from './MeetingList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  date: Yup.date().required('Date is required'),
  slot: Yup.string().required('Slot is required'),
  description: Yup.string().required('Description is required'),
  frequency: Yup.string().required('Frequency is required'),
  type: Yup.string().required('Type is required'),
  mode: Yup.string().required('Mode is required'),
  venue: Yup.string().required('Venue is required'),
  otherVenue: Yup.string().when('venue', {
    is: 'other',
    then: Yup.string().required('Please enter other venue')
  })
});
function AddMeetingFormDialog({ open, onClose, officeId }) {
  const formik = useFormik({
    initialValues: {
      title: '',
      date: '',
      slot: '',
      description: '',
      frequency: '',
      type: '',
      mode: '',
      venue: '',
      otherVenue: ''
    },
    validationSchema,
    onSubmit(values) {
      // Logic to handle submitting the form
      console.log('Meeting details:', values);
      MeetingApiCall.createMeeting(officeId, values);
      onClose(); // Close the dialog after submitting the form
    }
  });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Add Meeting</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill out the details for the new meeting.
          </DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <FormControl
              fullWidth
              error={formik.touched.venue && Boolean(formik.errors.venue)}
            >
              <InputLabel id="venue-label">Venue</InputLabel>
              <Select
                labelId="venue-label"
                id="venue-select"
                name="venue"
                value={formik.values.venue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
              >
                <MenuItem value="">Select Venue</MenuItem>
                <MenuItem value="venue1">Venue 1</MenuItem>
                <MenuItem value="venue2">Venue 2</MenuItem>
                <MenuItem value="venue3">Venue 3</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
              <FormHelperText>
                {formik.touched.venue && formik.errors.venue}
              </FormHelperText>
            </FormControl>
            {formik.values.venue === 'other' && (
              <FormControl
                fullWidth
                error={
                  formik.touched.otherVenue && Boolean(formik.errors.otherVenue)
                }
              >
                <TextField
                  id="other-venue"
                  name="otherVenue"
                  label="Other Venue"
                  value={formik.values.otherVenue}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                />
                <FormHelperText>
                  {formik.touched.otherVenue && formik.errors.otherVenue}
                </FormHelperText>
              </FormControl>
            )}
            <TextField
              fullWidth
              margin="normal"
              id="title"
              name="title"
              label="Title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            <TextField
              fullWidth
              margin="normal"
              id="date"
              name="date"
              label="Date"
              type="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TimePicker
              fullWidth
              margin="normal"
              id="slot"
              name="slot"
              label="Slot"
              value={formik.values.slot}
              onChange={(date) => formik.setFieldValue('slot', date)}
              onBlur={formik.handleBlur}
              error={formik.touched.slot && Boolean(formik.errors.slot)}
              helperText={formik.touched.slot && formik.errors.slot}
              InputProps={{
                // Add the clock icon as the end adornment
                endAdornment: <AccessTimeIcon />
              }}
              TextFieldComponent={(props) => <TextField {...props} />}
            />
            <TextField
              fullWidth
              margin="normal"
              id="description"
              name="description"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="frequency-label">Frequency</InputLabel>
              <Select
                labelId="frequency-label"
                id="frequency"
                name="frequency"
                value={formik.values.frequency}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.frequency && Boolean(formik.errors.frequency)
                }
              >
                <MenuItem value="">Select Frequency</MenuItem>
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="once">Once</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.type && Boolean(formik.errors.type)}
              >
                <MenuItem value="">Select Type</MenuItem>
                <MenuItem value="recruitment">Recruitment</MenuItem>
                <MenuItem value="retention">Retention</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="mode-label">Mode</InputLabel>
              <Select
                labelId="mode-label"
                id="mode"
                name="mode"
                value={formik.values.mode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.mode && Boolean(formik.errors.mode)}
              >
                <MenuItem value="">Select Mode</MenuItem>
                <MenuItem value="online">Online</MenuItem>
                <MenuItem value="offline">Offline</MenuItem>
              </Select>
            </FormControl>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">
                Save Meeting
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
}
// PropTypes validation for AddMeetingFormDialog component
AddMeetingFormDialog.propTypes = {
  open: PropTypes.bool.isRequired, // Validate 'open' prop as a boolean and required
  onClose: PropTypes.func.isRequired, // Validate 'onClose' prop as a function and required
  officeId: PropTypes.string.isRequired
};
function OfficePage() {
  const classes = useStyles();
  const { officeId } = useParams();

  const [openAddMeetingDialog, setOpenAddMeetingDialog] = useState(false);

  const handleOpenAddMeetingDialog = () => {
    setOpenAddMeetingDialog(true);
  };

  const handleCloseAddMeetingDialog = () => {
    setOpenAddMeetingDialog(false);
  };

  return (
    <Page
      className={classes.root}
      title="Group & Members"
    >
      <Container className={classes.mainContainer} maxWidth={false}>
        <div>
          <h1>
            Office Details
          </h1>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ margin: '10px' }}
          >
            <Button
              onClick={handleOpenAddMeetingDialog}
              variant="contained"
              color="primary"
              size="small"
            >
              Add Meeting
            </Button>
          </Box>
          <AddMeetingFormDialog
            open={openAddMeetingDialog}
            onClose={handleCloseAddMeetingDialog}
            officeId={officeId}
          />
        </div>
        <Card>
          <MeetingList officeId={officeId} />
        </Card>
      </Container>
    </Page>
  );
}

export default OfficePage;
