/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';

const useStyles = makeStyles(({
  root: {}
}));

const Password = ({ className, ...rest }) => {
  const classes = useStyles();
  // const [values, setValues] = useState({
  //   password: '',
  //   confirm: ''
  // });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Formik
      initialValues={{
        old_password: '',
        password: '',
        confirm_password: ''
      }}
      validationSchema={Yup.object().shape({
        old_password: Yup.string().max(15).required('Old password is required'),
        password: Yup.string().max(15).required('password is required').matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          'Password must contain at least 8 characters, one uppercase, one number and one special case character'
        ),
        confirm_password: Yup.string().when('password', {
          is: (val) => (val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            'password need to be the same as New Password.'
          )
        }).required('Confirm password is required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const AuthTokenObject = inLocalStorageJwt.getAuthToken();
        const AuthToken = AuthTokenObject.authToken;
        const postData = {
          old_password: values.old_password,
          new_password: values.password,
          confirm_password: values.confirm_password,
        };
        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${AuthToken}`,
          }
        };
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/reset-password`, postData, options)
          .then((res) => {
            const respData = res.data.data;
            console.log(res);
            // console.log(res.data.data.tokens);
              toast.success(`${res.data.message}`, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              if (res.data.status === true) {
                inLocalStorageJwt.logout();
                //  navigate('/app/dashboard', { replace: true });
              }
          })
          .catch((error) => {
            // what now?
            // console.log(error.response.data.message);
            setSubmitting(false);
            // Error
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
              toast.error(`${error.response.data.message}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest
              // in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error);
            }
          });
      }}
    >
      {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
        {...rest}
      >
        <Card>
          <CardHeader
            subheader="Update password (After Password reset, you need to login again.)"
            title="Password"
          />
          <Divider />
          <CardContent>
            <TextField
              error={Boolean(touched.old_password && errors.old_password)}
              helperText={touched.old_password && errors.old_password}
              fullWidth
              label="Old Password"
              margin="normal"
              name="old_password"
              id="old_password"
              onChange={handleChange}
              type="password"
              value={values.old_password}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              fullWidth
              label="New Password"
              margin="normal"
              name="password"
              id="password"
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.confirm_password && errors.confirm_password)}
              helperText={touched.confirm_password && errors.confirm_password}
              fullWidth
              label="Confirm password"
              margin="normal"
              name="confirm_password"
              id="confirm_password"
              onChange={handleChange}
              type="password"
              value={values.confirm_password}
              variant="outlined"
            />
          </CardContent>
          <Divider />
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              type="submit"
            >
              Update
            </Button>
          </Box>
        </Card>
      </form>
      )}
    </Formik>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
