/* eslint-disable */
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Typography } from "@material-ui/core";
// @material-ui/icons

// core components
import axios from 'axios'; 
import Header from "src/components/Header/Header.js";
import Footer from "src/components/Footer/Footer.js";
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import HeaderLinks from "src/components/Header/HeaderLinks.js";
import Parallax from "src/components/Parallax/Parallax.js";
import ApiCall from 'src/utils/ApiCall';
import Testimony from './Testimony';
import styles from "src/assets/jss/material-kit-react/views/landingPage.js";
import landingbg from "src/assets/img/testimony-bg.jpg";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [testimonies, setTestimonies] = useState([]);
  const [pageloading, setPageloading] = useState(true);
  const options = {
    headers: {
      'Content-Type': 'application/json',
    }
  };
  const getTestimonies = async () => {
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/trainings/testimonies`, options)
      .then((res) => {
        if (res.data.testimonies) {
          setTestimonies(res.data.testimonies);
          setPageloading(false);
          console.log(res.data.testimonies);
        }
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getTestimonies();
  }, []);

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="BMS GLOBAL"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax normal filter image={ landingbg }>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title} style={{ 'fontSize': '4em' }}>We Manage, You Grow.</h1>
              <h4 style={{ 'fontSize': '1.125rem', 'fontWeight': '300', 'fontFamily': '"Roboto", "Helvetica", "Arial", sans-serif'}}>
                Every entrepreneur needs a personal assistant, but not every body can afford it, So we are here to manage all your stuff. You are welcome.
              </h4>
              <br />
              {/* <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
            <GridContainer spacing={2}>
            {(testimonies.length === 0)
            && (
                <Grid
                  item
                  key='notest'
                  lg={12}
                  md={12}
                  xs={12}
                >
                   <Typography style={{ color: 'black', paddingTop: '145px', textAlign:'center', height: '300px'}}>No testimonies are available.</Typography>
                </Grid>
            )}
            {testimonies.map((testimony) => (
              <Grid
                item
                key={testimony.id}
                lg={4}
                md={6}
                xs={12}
              >
                <Testimony testimony={testimony} />
              </Grid>
            ))}
            </GridContainer>
            </div>
        </div>
      <Footer />
    </div>
  );
}
