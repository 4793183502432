import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import ApiCall from 'src/utils/ApiCall';

const OTPVerification = ({ open, onClose }) => {
  const [otp, setOTP] = useState('');
  const handleInputChange = (value) => {
    // Check if the entered value is numeric and has exactly 6 digits
    // if (/^\d{6}$/.test(value) || value === '') {
    setOTP(value);
    // }
  };
  const handleVerify = async () => {
    // Handle verification logic here (e.g., send OTP to backend for validation)
    // console.log('Verifying OTP:', otp);
    if (otp !== '') {
      const resp = await ApiCall.verifyEmailChangeOtp(otp);
      if (resp.status === 200) {
        setOTP('');
        onClose();
        // console.log(resp);
      }
    }
    // Close the dialog after verification (You can implement your logic here)
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Verify OTP</DialogTitle>
      <DialogContent>
        <TextField
          label="Enter OTP"
          variant="outlined"
          value={otp}
          onChange={(e) => handleInputChange(e.target.value)}
          inputProps={{
            maxLength: 6,
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          helperText={otp.length !== 6 ? 'Please enter a 6-digit number' : ''}
          error={otp.length !== 6}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleVerify} color="primary">
          Verify
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OTPVerification.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default OTPVerification;
