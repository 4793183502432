import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import ApiCall from 'src/utils/ApiCall';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import Page from 'src/components/Page';
import CategoryContext from 'src/contexts/CategoryContext';
import CategoryCard from './CategoryCard';
import Toolbar from './Toolbar';
import TrainingListView from '../../trainings/TrainingListView';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const CategoryList = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [searchStr, setsearchStr] = useState('');
  const [field, setField] = React.useState('category');
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();

  const getCategories = async () => {
    const userData = await ApiCall.getCategories(getLoggedinUserData.id, 'training', searchStr, field, null);
    setCategories(userData);
  };
  // useEffect(() => {
  //   getCategories();
  // }, []);
  const updateFieldSearch = (val) => {
    setField(val);
  };
  useEffect(() => {
    if (searchStr.length >= 3 && field === 'category') {
      getCategories();
    }
    if (searchStr.length === 0 && field === 'category') {
      getCategories();
    }
  }, [searchStr]);

  useEffect(() => {
    if (field === 'training') {
      console.log(field);
    }
    if (field === 'category') {
      getCategories();
    }
  }, [field]);

  const categoryContextVal = {
    getCategories,
    setsearchStr,
    updateFieldSearch
  };

  return (
    <>
      <CategoryContext.Provider value={categoryContextVal}>
        <Page
          className={classes.root}
          title="Training Categories"
        >
          <Container maxWidth={false}>
            <Toolbar field={field} />
            {field === 'category' && (
              <Box mt={3}>
                <Grid
                  container
                  spacing={3}
                >
                  {categories && categories.map((category) => (
                    <Grid
                      item
                      key={category.id}
                      lg={4}
                      md={12}
                      xs={12}
                    >
                      <CategoryCard
                        className={classes.productCard}
                        category={category}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            {field === 'training' && <TrainingListView />}
          </Container>
        </Page>
      </CategoryContext.Provider>
    </>
  );
};

export default CategoryList;
