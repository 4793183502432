import React from 'react';
// import Page from 'src/components/Page';
import MyProfile from 'src/views/customer/CustomerListView/Profile';

const Account = () => {
//   const classes = useStyles();

  return (
    <MyProfile />
  );
};

export default Account;
