/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
// import FullscreenIcon from '@material-ui/icons/Fullscreen';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  redContrlo:{
    color:'red !important'
  },
  greenContrlo:{
    color:'green !important'
  },
}));
export default function AlertDialogSlide(participantList) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const partList = participantList.participantList;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Participants
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-slide-title">{"Find Your Participant here"}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description"> */}
          <Autocomplete
            id="combo-box-demo"
            options={partList}
            getOptionLabel={(option) => `${option.name}`} //filter value
            renderOption={(option, { selected }) => (
              <React.Fragment>
                  <IconButton size="small">
                    {!option.playing && 
                      (<PlayArrowIcon style={{  color:'red' }} />
                    )}
                    {option.playing && 
                    <PauseIcon  style={{  color:'green' }} />
                    }
                  </IconButton>
                  <IconButton size="small">
                    {option.muted && 
                      (<VolumeOffIcon style={{  color:'red' }}></VolumeOffIcon>
                    )}
                    {!option.muted && 
                    <VolumeUpIcon  style={{  color:'green' }}></VolumeUpIcon>
                    }
                  </IconButton>
                  <h5>{`${option.name}`}</h5>
                  <Typography variant="caption" display="block" gutterBottom>
                  {option.PageVisible? <span style={{ color:'green' }}>-Live</span>:<span style={{ color:'red' }}>-Background</span>}
                  </Typography>

              </React.Fragment>
            )}
            // style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label={'Live Participants ('+(partList.length)+')'} variant="outlined" />}
          />
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

