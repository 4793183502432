/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import MeetingApiCall from 'src/utils/MeetingApiCall';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import { TextField } from '@material-ui/core';

const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();

const MaskedPhoneNumber = ({ phoneNumber }) => {
  // Mask the phone number (assuming format: +91-1234567890)
  const maskedPhoneNumber = `${phoneNumber.substring(0, phoneNumber.length - 4)}XXXX`;
  return (
    <span>{maskedPhoneNumber}</span>
  );
};
const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Phone',
    selector: 'phone',
    cell: (row) => ((row.senior && (getLoggedinUserData.id !== row.senior.id)) || (row.senior === null) ? <MaskedPhoneNumber phoneNumber={row.phone} /> : row.phone),
    sortable: true,
  },
  {
    name: 'Senior',
    selector: 'senior',
    cell: (row) => (row.senior && typeof row.senior === 'object' ? `${row.senior.fname} ${row.senior.lname}` : row.otherSenior),
    sortable: true,
  },
];

const MeetingParticipantsDataTable = (params) => {
  const {
    meetingId, filters, partRefresh, setPartRefresh
  } = params;
  const [participants, setParticipants] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const customRowsPerPageOptions = [10, 20, 30, 50, 100];
  const [loading, setLoading] = useState(false);

  const fetchParticipants = async () => {
    const secondParam = { ...filters, skip, limit: perPage };
    try {
      setLoading(true);
      const resp = await MeetingApiCall.participantList(meetingId, secondParam);
      if (resp.status === 200 && resp.participants) {
        setParticipants(resp.participants);
        setTotalRows(resp.totalParticipants);
        setPartRefresh(false);
        setLoading(false);
      }
    } catch (error) {
      setPartRefresh(false);
      setLoading(false);
      console.error('Error creating coupon:', error);
    // Handle error
    }
  };
  const handlePageChange = (pg) => {
    console.log(pg);
    setSkip((pg - 1) * perPage);
    console.log((pg - 1) * perPage);
    setPage(pg);
  };

  const handlePerRowsChange = async (newPerPage, pg) => {
    setSkip((pg - 1) * newPerPage);
    setPerPage(newPerPage);
    setPage(pg);
  };
  useEffect(() => {
    if (partRefresh) { fetchParticipants(); }
  }, [partRefresh]);
  useEffect(() => {
    // Fetch meetings from the server based on page and perPage
    fetchParticipants();
  }, [page, perPage]);
  // Handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter the data based on search term
  const filteredParticipants = participants.filter((participant) => participant.name.toLowerCase().includes(searchTerm.toLowerCase())
  || participant.phone.includes(searchTerm.toLowerCase())
  || (participant.senior && typeof participant.senior === 'object' && participant.senior.fname.toLowerCase().includes(searchTerm.toLowerCase()))
  || participant.otherSenior.toLowerCase().includes(searchTerm.toLowerCase()));
  return (
    <div>
      <TextField
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearchChange}
        inputProps={{ 'aria-label': 'search' }}
        size="small"
        variant="outlined"
      />
      <DataTable
        columns={columns}
        data={filteredParticipants}
        progressPending={loading}
        pagination
        filter
        paginationServer
        highlightOnHover
        striped
        responsive
        paginationTotalRows={totalRows}
        paginationPerPage={perPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationRowsPerPageOptions={customRowsPerPageOptions}
      />
    </div>
  );
};

export default MeetingParticipantsDataTable;
