/* eslint-disable */
import React, { useContext } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import UserContext from 'src/contexts/UserContext';

export default function ControlledOpenSelect(fieldval) {
  const userContext = useContext(UserContext);
  const [field, setField] = React.useState(fieldval.fieldval); // eslint-disable-line react/destructuring-assignment
  const [open, setOpen] = React.useState(false);
  const handleChange = (event) => {
    setField(event.target.value);
    userContext.updateGenSearch(event.target.value);
    // workApplicationContext.searchList(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Select
      labelId="demo-controlled-open-select-label"
      id="demo-controlled-open-select"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      value={field}
      onChange={handleChange}
      variant="outlined"
    >
      <MenuItem value="my"><em>MY Trainings</em></MenuItem>
      <MenuItem value={0}>All</MenuItem>
      <MenuItem value={1}>1st Gen</MenuItem>
      <MenuItem value={2}>2nd Gen</MenuItem>
      <MenuItem value={3}>3rd Gen</MenuItem>
      <MenuItem value={4}>4th Gen</MenuItem>
      <MenuItem value={5}>5th Gen</MenuItem>
      <MenuItem value={6}>6th Gen</MenuItem>
      <MenuItem value={7}>7th Gen</MenuItem>
      <MenuItem value={8}>8th Gen</MenuItem>
      <MenuItem value={9}>9th Gen</MenuItem>
      <MenuItem value={10}>10th Gen</MenuItem>
    </Select>
  );
}
