import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import GroupApiCall from 'src/utils/GroupApiCall';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  desc: Yup.string().required('Description is required'),
});

const GroupCard = ({
  groupName, description, groupId
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // Handle form submission here
    // console.log(values, groupId);
    values.groupId = groupId;
    try {
      const resp = await GroupApiCall.createOffice(values);
      if (resp.status === 200 && resp.office) {
        // onCouponCreated(resp.office);
        resetForm();
        handleDialogClose();
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    } finally {
      setSubmitting(false);
    }
    // Close dialog
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2">
          {groupName}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          {description}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDialogOpen}
          size="small"
        >
          Add Office
        </Button>
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Add Office</DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                name: '',
                address: '',
                desc: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <div style={{ marginTop: '1px' }}>
                    <Field
                      name="name"
                      label="Name"
                      as={TextField}
                      error={errors.name && touched.name}
                      helperText={errors.name && touched.name && errors.name}
                    />
                  </div>
                  <div style={{ marginTop: '2px' }}>
                    <Field
                      name="address"
                      label="Address"
                      as={TextField}
                      error={errors.address && touched.address}
                      helperText={errors.address && touched.address && errors.address}
                    />
                  </div>
                  <div style={{ marginTop: '15px' }}>
                    <Field
                      name="desc"
                      label="Description"
                      as={TextField}
                      multiline
                      rows={4}
                      variant="outlined"
                      error={errors.desc && touched.desc}
                      helperText={errors.desc && touched.desc && errors.desc}
                    />
                  </div>
                  <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                      Cancel
                    </Button>
                    <Button type="submit" color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

GroupCard.propTypes = {
  groupName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired
};

export default GroupCard;
