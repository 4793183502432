/* eslint-disable */
import React, { useState, usetheme } from 'react';
import {
  Card,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import ProductCard from './ProductCard';
import Toolbar from './Toolbar';
// import Scroll from './Scroll';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  Toolbar: {
    margin: '10px'
  },
}));

const ProductList = () => {
  const classes = useStyles();
  return (
      <Page
        className={classes.root}
        title="Share & Earn"
      >
        <Container maxWidth={false}>

          <Toolbar className={classes.Toolbar}/>
          <Card>
            <Grid
              container
              spacing={3}
            >
              <ProductCard />
            </Grid>
          </Card>
        </Container>
      </Page>
  );
};

export default ProductList;
