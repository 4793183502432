/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
// @material-ui/icons
import {
  // Box,
  // Container,
  CardContent,
  Grid,
  // Link,
  TextField,
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';
// core components
import {
  useSearchParams,
  // useNavigate
} from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import GridContainer from "src/components/Grid/GridContainer.js";
import countries from 'src/views/countries';
import GridItem from "src/components/Grid/GridItem.js";
// import CustomInput from "src/components/CustomInput/CustomInput.js";
import Button from "src/components/CustomButtons/Button.js";
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from "src/assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
// import Typography from "src/theme/typography";
const useStateRef = require('react-usestateref');

// const useStyles = makeStyles(styles);
// const handleCountryClick = (event) => {
//   console.log('hello', event.target.name);
// };
 
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  textarea: {
    resize: 'both'
  },
  errorMessage: {
    color: 'red'
  },
  radioLabel: {
    color: theme.palette.text.secondary
  },
  hideInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    opacity: '0'
  }
}));
const useStyles2 = makeStyles(styles);
// const phoneRegExp = /^[0-9]{2}[0-9]{8}$/;
const phoneRegExp = /^[0-9]{10}$/;

export default function WorkSection({meetingSlots, userId, formHeader, userName }) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const DELAY = 1500;
  const formikRef = useRef();
  const inputRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [country, SetCountry] = useStateRef({});
  const [optionslot, SetOptionslot] = useStateRef({});

  const [isformSubmitted, SetisformSubmitted] = useStateRef(searchParams.get('isformsubmitted')?searchParams.get('isformsubmitted'):false);
  const [connTime, SetconnTime] = useStateRef(searchParams.get('conn_time')?searchParams.get('conn_time'):'');

  const [state, SetState] = useStateRef({});
  const [options2, SetOptions2] = useStateRef([]);
  const [options3, SetOptions3] = useStateRef([]);
  const [slotopt, SetSlotopt] = useStateRef([]);

  const MAX_FILE_SIZE = 2e+6;
  const [{ callback, value, load, expired }, SetRecaptcha] = useState({
    callback: "not fired",
    value: "[empty]",
    load: false,
    expired: "false"
  });
  const [{resumeLink, resumeName}, SetResume] = useState({});
  //function for app mode selection
  const handleRadioChange = (event) => {
    if(event.target.value==='app_form'){
      formikRef.current.setFieldValue('resume_file','');
      SetResume({});
    }
    formikRef.current.setFieldValue('app_mode', event.target.value);
  }
  //function for resume upload
  const onProfileChange = (event) => {
    if (event.target.files[0]) {
      // const formData = new FormData();
      // console.log(event.target.files);
      SetResume({
        resumeLink: URL.createObjectURL(event.target.files[0]),
        resumeName: event.target.files[0].name
      });
      formikRef.current.setFieldValue('resume_file',event.target.files[0]);
    }
  }

  const resetFileInput = (e) => {
    // 👇️ reset input value
    // e.preventDefault();
    inputRef.current.value = null;
    formikRef.current.setFieldValue('resume_file',null);
    SetResume({});
  };
     
  const reCaptchaRef = React.createRef();
  function onCaptchaChange(value) {
    // console.log("Captcha value:", value);
    formikRef.current.setFieldValue('reCaptcha', value, false);

    if (value === null) SetRecaptcha({ expired: "true" });
  }
  function getExtension(filename) {
    if(filename){
      return filename.split(".").pop();
    }
  }
  useEffect(() => {
    setTimeout(() => {
      SetRecaptcha({ load: true });
      // console.log("didMount - reCaptcha Ref-", reCaptchaRef);
    }, DELAY);
  }, []);
  const asyncScriptOnLoad = () => {
    SetRecaptcha({ callback: "called!" });
    // console.log("scriptLoad - reCaptcha Ref-", reCaptchaRef);
  };
  const sortByDate = arr => {
    const sorter = (a, b) => {
       return new Date(a.value).getTime() - new Date(b.value).getTime();
    }
    arr.sort(sorter);
 };
  useEffect(() => {
    SetOptions2(countries && countries.map((ct) => (
      { name: ct.country, value: ct.country }
    )));
    let tempSlots = [];
    meetingSlots && meetingSlots.map(function(slot,index){
      // tempSlots.push(slot);
      if(slot.day === 'daily'){
        var dte = new Date();
        const d = moment(dte).format('L'); // d = "12/12/2017"
        dte = new Date(moment(d +' '+ slot.time));
        if(dte > new Date()){
          const tslot1Time = moment(dte).format();
          const tslot = {name: moment(tslot1Time).format("MMM Do, YYYY hh:mm A"), value: tslot1Time};
          tempSlots.push(tslot);
        }
        for(var i=1; i < 4; i++){
          const slot1Time = moment(dte.setDate(dte.getDate() + 1)).format(); //.format("MMM Do, YYYY hh:mm A");
          const slot1 = {name: moment(slot1Time).format("MMM Do, YYYY hh:mm A"), value: slot1Time};
          tempSlots.push(slot1);
        }
        // tempSlots.push(slot);
      }else{
        var dte = new Date(slot.date);
        const d = moment(dte).format('L'); // d = "12/12/2017"
        dte = new Date(moment(d +' '+ slot.time));
        const slot1Time = moment(dte.setDate(dte.getDate() + 1)).format(); //.format("MMM Do, YYYY hh:mm A");
        const slot1 = {name: moment(slot1Time).format("MMM Do, YYYY hh:mm A"), value: slot1Time};
        tempSlots.push(slot1);
      }
      sortByDate(tempSlots);
    });
    SetSlotopt(tempSlots && tempSlots.map((sl) => (
     { name: sl.name, value: sl.value }
    )));
  }, []);

  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        {!isformSubmitted ?
        (<GridItem cs={12} sm={12} md={8}>
    
          <h2 className={classes2.title}> {formHeader? formHeader: `Work with ${userName}`}</h2>
          {/* <h4 className={classes2.description}>
            Divide details about your product or agency work into parts. Write a
            few lines about each one and contact us about any further
            collaboration. We will responde get back to you in a couple of
            hours.
          </h4> */}
          <Formik
            innerRef={formikRef}
            initialValues={{
              app_mode: 'app_form',
              name: '',
              email: '',
              phone: '',
              country: '',
              state: '',
              city: '',
              address: '',
              zip: '',
              experience: '',
              qualification: '',
              dob: '',
              qualification:'',
              description:'',
              current_status:'',
              resume_file:'',
              conn_time:'',
              reCaptcha: null
            }}
            validationSchema={Yup.object().shape({
              app_mode: Yup.string().required('kindly choose mode of application.'),
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              name: Yup.string().max(255).required('Name is required'),
              country: Yup.string().when("app_mode", {
                is:'app_form',
                then: Yup.string().required("Country is required")
              }),
              state: Yup.string().when("app_mode", {
                is:'app_form',
                then: Yup.string().required("Region is required")
              }),
              phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone is required'),
              address: Yup.string().max(255).when("app_mode", {
                is:'app_form',
                then: Yup.string().required("Address is required")
              }),
              zip: Yup.string().min(6, 'zip must have 6 digits.').max(6, 'zip must have 6 digits.').when("app_mode", {
                is:'app_form',
                then: Yup.string().required("Pincode is required")
              }),
              city: Yup.string().min(3).max(100).when("app_mode", {
                is:'app_form',
                then: Yup.string().required("City is required")
              }),
              dob: Yup.string().when("app_mode", {
                is:'app_form',
                then: Yup.string().required("Date of Birth is required")
                  .test({
                    message: 'Please enter Past date for D.O.B.',
                    test: (date) => {
                    var g1 = new Date(date);
                    var g2 = new Date();
                    var isValid = true;
                    if (g1.getTime() >= g2.getTime())
                      return !isValid;
                    return isValid;
                  }})
                  .test({
                    message: 'Please enter D.O.B. same as your certificates,you must older than 15 years',
                    test: (date) => {
                    var g1 = new Date(date);
                    var g2 = new Date(new Date().setFullYear(new Date().getFullYear() - 15))
                    var isValid = true;
                    if (g1.getTime() >= g2.getTime())
                      return !isValid;
                    return isValid;
                  }}),
              }),
              qualification: Yup.string().max(255).when("app_mode", {
                is:'app_form',
                then: Yup.string().required("Qualification is required")
              }),
              current_status: Yup.string().max(255, 'Current status must be less than 300 characters.').when("app_mode", {
                is:'app_form',
                then: Yup.string().required("Current status is required")
              }),
              resume_file: Yup.mixed().when("app_mode", {
                is:'resume',
                then: Yup.mixed().required("Resume is required").test({
                  message: 'Please provide a supported file type',
                  test: (file, context) => {
                    const isValid = ['pdf'].includes(getExtension(file?.name));
                    if (!isValid) context?.createError();
                    return isValid;
                  }
                })
                .test({
                  message: `File too big, can't exceed ${MAX_FILE_SIZE}`,
                  test: (file) => {
                    const isValid = file?.size < MAX_FILE_SIZE;
                    return isValid;
                  }
                })
              }),
              description: Yup.string().max(300, 'Description must be less than 300 characters.'),
              conn_time:  Yup.string().required("Kindly enter the right time to connect with you.")
                              .test({
                                message: 'Please enter future time',
                                test: (date) => {
                                var g1 = new Date(date);
                                var g2 = new Date();
                                var isValid = true;
                                if (g1.getTime() <= g2.getTime())
                                  return !isValid;
                                return isValid;
                              }})
                              .test({
                                message: 'Slots are available after: ' + moment().add(6, 'hours').format('MMM Do, YYYY hh:mm A'),
                                test: (date) => {
                                var g1 = new Date(date);
                                var g2 = new Date(new Date().setHours(new Date().getHours() + 6));
                                var isValid = true;
                                if (g1.getTime() <= g2.getTime())
                                  return !isValid;
                                return isValid;
                              }}),
              reCaptcha: Yup.string().required('please click here').nullable()
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const recaptchaValue = reCaptchaRef.current.getValue();
              if(!recaptchaValue){
                alert('Please check the Google captcha.');
                return false;
              }
              const postData = new FormData();
              // const postData = {
              //   app_mode: values.app_mode,
              //   email: values.email,
              //   name: values.name,
              //   country: values.country,
              //   state: values.state,
              //   phone: values.phone,
              //   address: values.address,
              //   zip: values.zip,
              //   city: values.city,
              //   dob: values.dob,
              //   qualification: values.qualification,
              //   current_status: values.current_status,
              //   description: values.description,
              //   resume_file: values.resume_file
              // };
              postData.append('app_mode', values.app_mode);
              postData.append('email', values.email);
              postData.append('name', values.name);
              postData.append('country', values.country);
              postData.append('state', values.state);
              postData.append('phone', values.phone);
              postData.append('address', values.address);
              postData.append('zip', values.zip);
              postData.append('city', values.city);
              postData.append('dob', values.dob);
              postData.append('qualification', values.qualification);
              postData.append('current_status', values.current_status);
              postData.append('description', values.description);
              postData.append('conn_time', values.conn_time);

              if(values.resume_file !==''){
                postData.append('resume_file', values.resume_file);
              }
              // console.log("postData", postData);
              const options = {
                headers: {
                  // 'Content-Type': 'application/json',
                  'Content-Type': 'multipart/form-data',
                }
              };
              axios.post(`${process.env.REACT_APP_API_BASE_URL}/formdata/save-form-data/${userId}`, postData, options)
                .then((res) => {
                  const respData = res.data.applicationData;
                  // console.log(res.data.data.tokens);
                    // alert(respData.tokens.access);
                    // localStorage.setItem('bms_user', JSON.stringify(res.data));
                    setSearchParams({conn_time: respData.conn_time, isformsubmitted:true });
                    SetconnTime(respData.conn_time);
                    SetisformSubmitted(true);
                    toast.success(`${res.data.message}`, {
                      position: 'top-right',
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    if (res.data.status === true) {
                      //  navigate('/app/dashboard', { replace: true });
                      setSubmitting(false);
                      resetForm({values: ''});
                    }
                })
                .catch((error) => {
                  // what now?
                  // console.log(error.response.data.message);
                  setSubmitting(false);
                  // Error
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error(`${error.response.data.message}`, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest
                    // in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error);
                  }
                });
              // navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
          <form onSubmit={handleSubmit}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <RadioGroup
                    onChange={handleRadioChange}
                    className={classes.radioLabel}
                    value={values.app_mode}
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="app_form"
                  >
                    <FormControlLabel
                      value="app_form"
                      control={<Radio color="primary" />}
                      label="Application form"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value="resume"
                      control={<Radio color="primary" />}
                      label="Upload Resume"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Your Name"
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            {values.app_mode=='app_form' && 
              (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    {/* <TextField
                      error={Boolean(touched.country && errors.country)}
                      helperText={touched.country && errors.country}
                      fullWidth
                      label="Select Country"
                      name="country"
                      onClick={handleCountryClick}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      select
                      SelectProps={{ native: true }}
                      value={values.country}
                      variant="outlined"
                    >
                      {/* <option value="" key="">Select Country</option> */}
                      {/* {countries.map((country) => (
                        <option
                          key={country.country}
                          value={country.country}
                        >
                          {country.country}
                        </option>
                      ))}
                    </TextField> */}

                    <Autocomplete
                      id="country-drop-down"
                      name="country"
                      fullWidth
                      // onChange={handleChange}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          values.country = newValue.value;
                          SetCountry(newValue);
                          SetOptions3(countries
                            .find(({ country }) => country === values.country)
                            .states.map((region) => (
                              {name: region, value:region }
                            )));
                        } else {
                          values.country = '';
                          SetCountry({});
                        }
                      }}
                      onBlur={handleBlur}
                      autoHighlight
                      value={country}
                      options={options2}
                      getOptionLabel={(option) => option.name || ''}
                      getOptionSelected={(option) => option.name || ''}
                      renderInput={(params) => (
                        <TextField
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                          {...params}
                          label="Select Country"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Autocomplete
                      id="state-drop-down"
                      name="state"
                      fullWidth
                      // onChange={handleChange}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          values.state = newValue.value;
                          SetState(newValue);
                        } else {
                          values.state = '';
                          SetState({});
                        }
                      }}
                      onBlur={handleBlur}
                      autoHighlight
                      value={state}
                      options={options3}
                      getOptionLabel={(option) => option.name || ''}
                      getOptionSelected={(option) => option.name || ''}
                      renderInput={(params) => (
                        <TextField
                          error={Boolean(touched.state && errors.state)}
                          helperText={touched.state && errors.state}
                          {...params}
                          label="Select State"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                        fullWidth
                        label="Address"
                        placeholder="HNO/Floor Num. etc."
                        name="address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                        fullWidth
                        label="City"
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        value={values.city}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        error={Boolean(touched.zip && errors.zip)}
                        helperText={touched.zip && errors.zip}
                        fullWidth
                        label="Pin Code"
                        name="zip"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        value={values.zip}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      error={Boolean(touched.qualification && errors.qualification)}
                      helperText={touched.qualification && errors.qualification}
                      fullWidth
                      label="Qualification"
                      name="qualification"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.qualification}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      error={Boolean(touched.dob && errors.dob)}
                      fullWidth
                      helperText={touched.dob && errors.dob}
                      label="Date of Birth"
                      name="dob"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.dob}
                      variant="outlined"
                    />
                  </Grid>
              </Grid>
              </>
            )}
              <Grid container spacing={3}>
                <Grid item xs>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    fullWidth
                    label="Phone"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                
              {values.app_mode=='app_form' && 
              (
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    error={Boolean(touched.current_status && errors.current_status)}
                    fullWidth
                    helperText={touched.current_status && errors.current_status}
                    label="Current Status"
                    name="current_status"
                    placeholder="Study/Job/Business/Others"
                    // InputLabelProps={{ shrink: true }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.current_status}
                    variant="outlined"
                  />
                </Grid>
              )}
              </Grid>
              {values.app_mode=='resume' && 
              (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    variant="contained"
                    component="label"
                  >
                    Upload RESUME
                    <input
                      id="imageButton"
                      ref={inputRef}
                      accept=".png, .jpg, .jpeg, .pdf"
                      className={classes.hideInput}
                      type="file"
                      onChange={(e) => { onProfileChange(e); }}
                    />
                  </Button>
                  {resumeName && <span className={classes.paper}>{resumeName}</span>}
                  {resumeName && <Button onClick={resetFileInput}>clear</Button>}
                  <ErrorMessage name="resume_file">
                    { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                  </ErrorMessage>
                </Grid>
              </Grid>
              )}
              <Grid container spacing={3}>
                <GridItem xs={12}>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    placeholder="Describe about your skill set and other areas."
                    label="Description"
                    name="description"
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    multiline
                    inputProps={{ className: classes.textarea }}
                    value={values.description}
                    variant="outlined"
                  />
                </GridItem>
              </Grid>
              <Grid container spacing={3}>
                <GridItem xs={12}>
                  { meetingSlots && meetingSlots.length > 0
                  ?(
                    <Autocomplete
                      id="slot-drop-down"
                      name="conn_time"
                      fullWidth
                      // onChange={handleChange}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          values.conn_time = newValue.value;
                          SetOptionslot(newValue);
                        } else {
                          values.conn_time = '';
                          SetOptionslot({});
                        }
                      }}
                      onBlur={handleBlur}
                      autoHighlight
                      value={optionslot}
                      options={slotopt}
                      groupBy={(option) => (moment(option.value).format('MMM DD, YYYY'))}
                      getOptionLabel={(option) => option.name || ''}
                      getOptionSelected={(option) => option.name || ''}
                      renderInput={(params) => (
                        <TextField
                          error={Boolean(touched.conn_time && errors.conn_time)}
                          helperText={touched.conn_time && errors.conn_time}
                          {...params}
                          label="Select Slot"
                          variant="outlined"
                        />
                      )}
                    />
                  ):(
                    <TextField
                      error={Boolean(touched.conn_time && errors.conn_time)}
                      fullWidth
                      helperText={touched.conn_time && errors.conn_time}
                      label="Right date & time to connect with you."
                      placeholder="You may receive the call at this time from our side."
                      name="conn_time"
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.conn_time}
                      variant="outlined"
                    />
                  )}
                </GridItem>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={12} md={4}>
                {true && (
                  <>
                    <ReCAPTCHA
                      style={{ display: "inline-block" }}
                      theme="dark"
                      size="compact"
                      ref={reCaptchaRef}
                      sitekey="6LdCs3IaAAAAAGJ_pizzCwTKQmm4mMxjh6NGSmOM"
                      onChange={onCaptchaChange}
                      asyncScriptOnLoad={asyncScriptOnLoad}
                    />
                    <ErrorMessage name="reCaptcha">
                    { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </>
                )}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <GridItem xs={12} sm={12} md={4}>
                  <Button disabled={isSubmitting}  type="submit" color="primary">Submit Application</Button>
                </GridItem>
              </Grid>
            </CardContent>
          </form>
          )}
          </Formik>
        </GridItem>):
        (<GridItem cs={12} sm={12} md={12}>
            <h2 className={classes2.titleSuccess} style={{marginBottom:'10px'}}> Your application is submitted successfully.</h2>
            <p className={classes2.description}>
              your scheduled time is: {connTime?moment(connTime).format('MMM Do, YYYY hh:mm A'):'NA'}
            </p>
            <p className={classes2.description}>
              Kindly be available on your scheduled time, application owner may contact you.
            </p>
            <h2 className={classes2.titleSuccess}> </h2>
        </GridItem>)}
      </GridContainer>
    </div>
  );
}
