/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Container,
  makeStyles,
  Link,
  Typography,
  Button
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Page from 'src/components/Page';
// import DsrList from './DsrList';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import moment from 'moment';
import ViewIcon from '@material-ui/icons/Visibility';
// import data from './data';
import { NavLink as RouterLink } from 'react-router-dom';
// import { DesktopDatePicker } from '@material-ui/pickers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formExpndCard: {
    minWidth: 275,
    minWidth: 276,
    margin: theme.spacing(3)
  },
  respHeader: {
    margin:'10px',
    padding: '10px'
  },
  statusTaskLbl: {
    padding: '3px',
    borderRadius: '5px',
    color: 'white'
  },
  headerSt: {
    fontSize: '15px'
  },
  mainContainer: {
    padding: theme.spacing(0.5)
  }
}));

const List = () => {
  const classes = useStyles();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  async function deleteTask(taskId,page) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
   await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/tasks/delete/${taskId}`, options)
      .then((resp) => {
        console.log('del');
        // navigate('/app/customers');
      })
      .catch((error) => {
        console.log(error);
      });
    fetchTasks(currentPage);
    
  }
  function handleDelete(taskId) {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { console.log('yes'); deleteTask(taskId); }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }

  const columns = [
    {
      name: <span className={classes.headerSt}>Task Name</span>,
      selector: 'title',
      sortable: true,
      //right: true,
      cell: (row) => <div style={{width:'100%'}}>{row.title}</div>
    },
    // {
    //   name: 'Type',
    //   selector: 'typeName',
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'Content',
    //   selector: 'type',
    //   sortable: true,
    //   center: true,
    //   cell: (row) => (<div>{ row.type? row.type.name: 'N/A' } </div>)
    // },
    {
      name: <span className={classes.headerSt}>User</span>,
      selector: 'user',
      sortable: true,
      center: true,
      cell: (row) => (<div>{ row.user.fname + ` ` + row.user.lname } </div>)
    },
    // {
    //     name: 'Task Status',
    //     selector: 'status',
    //     sortable: true,
    //     center: true,
    //     cell: (row) => (<div>{ row.status } </div>)
    //   },
    {
      name: <span className={classes.headerSt}>Action</span>,
      center: true,
      // cell: (row) => <Link color="primary"
      // component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>Edit</Link>
      cell: (row) => (
        <>
          <Link color="primary" size="small" component={RouterLink} to={`/app/task/update?taskid=${row.id}`}>
            <IconButton color="primary" size="small" variant="contained">
              <EditIcon />
            </IconButton>
          </Link>
          <div>
            <IconButton aria-label="delete" color="secondary" onClick={() => { handleDelete(row.id); }}>
              <DeleteIcon style={{color:'#ff6666'}} />
            </IconButton>
          </div>
        </>
      )
    }
  ];
  const fetchTasks = async (page) => {
    const offset = (page - 1) * perPage;
    setLoading(true);
    const postData = {
      createdBy: getLoggedinUserData.id,
      skip: offset,
      limit: perPage
    };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    var filters = `?createdBy=${getLoggedinUserData.id}&skip=${offset}&limit=${perPage}`;
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/tasks${filters}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data.result);
        setData(res.data.data.tasks);
        setTotalRows(res.data.data.totalTasks);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    fetchTasks(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    setCurrentPage(page);
    await fetchTasks(page);
    // setData(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchTasks(1);
  }, []);

  const ExpandedRow = (row) => {
    const applicationOb = row.data;
    const bull = <span className={classes.bullet}>•</span>;
    return (<div className={classes.root}>
           <Grid container spacing={3}>
              <Card className={classes.formExpndCard}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className={classes.respHeader}>Type</Th>
                      <Th className={classes.respHeader}>Content</Th>
                      {/* <Th className={classes.respHeader}>User</Th> */}
                      <Th className={classes.respHeader}>Deadline</Th>
                      <Th className={classes.respHeader}>Status</Th>
                      {/* <Th className={classes.respHeader}>Completion Date</Th> */}
                      <Th className={classes.respHeader}>Task Details</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                    <Td className={classes.respHeader}>{applicationOb.typeName? applicationOb.typeName: 'N/A'}</Td>
                      <Td className={classes.respHeader}>{applicationOb.type? <><Link component={RouterLink} to={`/app/training/details-page/${applicationOb.type.id}`}>{applicationOb.type.name}</Link> (<span><b>Trainer:</b> {applicationOb.type.trainer}</span>)</> : 'N/A' }</Td>
                      {/* <Td className={classes.respHeader}>{`${applicationOb.user.fname} ${applicationOb.user.lname}`}</Td> */}
                      <Td className={classes.respHeader}>{moment(applicationOb.deadline).format('MMM DD, YYYY, HH:MM A')}</Td>
                      <Td className={classes.respHeader}>
                      <Typography
                        className={classes.statusTaskLbl}
                        style={{
                         backgroundColor: (applicationOb.status==='completed' ? '#44A048' : '#ff9900')
                        }}
                      >
                        {applicationOb.status}
                      </Typography>
                      </Td>
                      {/* <Td className={classes.respHeader}>{moment(applicationOb.completion_date).format('MMM DD, YYYY, HH:MM A')}</Td> */}
                      <Td className={classes.respHeader}>
                        <Link color="primary" size="small" component={RouterLink} to={`/app/task/view/${applicationOb.id}`}>
                          <IconButton color="primary" size="small" variant="contained">
                            <ViewIcon />
                          </IconButton>
                        </Link>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Card>
           </Grid>
       </div>);
  };
  const tableData = {
    columns,
    data
  };
  return (
      <Container className={classes.mainContainer} maxWidth={false}>
          {/* <DsrList dsrList={dsrList} /> */}
          <DataTableExtensions {...tableData}>
              <DataTable
                title="Tasks List(Created By me)"
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                filter
                paginationServer
                paginationTotalRows={totalRows}
                // selectableRows
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover
                expandableRows
                expandableRowDisabled={row => row.disabled}
                expandableRowsComponent={<ExpandedRow />}
              />
            </DataTableExtensions>
      </Container>
  );
};

export default List;
