import React, { useContext, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  // Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
// import Tooltip from '@material-ui/core/Tooltip';
// import {
//   // Send as SendOtpIcon,
// } from 'react-feather';
import Page from 'src/components/Page';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import UserContext from 'src/contexts/UserContext';
import AppContext from 'src/components/AppContext';
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }
const VerifyemailPhoneView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();
  const emailId = query.get('emailId');
  const phoneNum = query.get('phoneNum');
  const verifyToken = query.get('token');
  const redirectTo = query.get('redirect');
  const loginContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const [secondsEmail, setSecondsEmail] = React.useState(120);
  const [resendEmailCount, SetResendEmailCount] = React.useState(0);
  const [secondsPhone, setSecondsPhone] = React.useState(120);
  const [resendPhoneCount, SetResendPhoneCount] = React.useState(0);
  const options = {
    headers: {
      'Content-Type': 'application/json',
    }
  };
  React.useEffect(() => {
    if (secondsEmail > 0) {
      setTimeout(() => setSecondsEmail(secondsEmail - 1), 1000);
    } else {
      setSecondsEmail('Resend Email OTP');
    }

    if (secondsPhone > 0) {
      setTimeout(() => setSecondsPhone(secondsPhone - 1), 1000);
    } else {
      setSecondsPhone('Resend Phone OTP');
    }
  });
  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/verify-token`, { verifyToken }, options)
      .then(() => {
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          toast.error('Link is expired or broken, try with forgot password to verify your account.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate('/forgot-password', { replace: true });
        }
      });
    // console.log('first time');
  }, []);

  const reSendEmailOtp = () => {
    SetResendEmailCount(resendEmailCount + 1);
    setSecondsEmail(120);
    // console.log('send email otp', resendEmailCount);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/resend-email-otp`, { verifyToken }, options)
      .then((res) => {
        // console.log(res);
        toast.success(`${res.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const reSendPhoneOtp = () => {
    SetResendPhoneCount(resendPhoneCount + 1);
    setSecondsPhone(120);
    // console.log('send phone otp', resendPhoneCount);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/resend-phone-otp`, { verifyToken, type: 'emailVerify' }, options)
      .then((res) => {
        // console.log(res);
        toast.success(`${res.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // React.useEffect(() => {
  //   console.log(resendEmailCount);
  //   if(resendEmailCount > 3) {
  //   }
  // }, [resendEmailCount]);
  return (
    <Page
      className={classes.root}
      title="Verify Email & Phone"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email_otp: '',
              phone_otp: '',
            }}
            validationSchema={
              Yup.object().shape({
                email_otp: Yup.number().typeError('OTP must be number.')
                  .required('OTP is required'),
                phone_otp: Yup.number().typeError('OTP must be number.')
                  .required('OTP is required'),
              })
            }
            onSubmit={async (values, { setSubmitting }) => {
              const postData = {
                verifyToken,
                email: emailId,
                phone: phoneNum,
                email_otp: values.email_otp,
                phone_otp: values.phone_otp,
              };
              await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/verify-account`, postData, options)
                .then((res) => {
                  const respData = res.data.data;
                  // console.log(res);
                  // console.log(res.data.data.tokens);
                  if (respData.tokens) {
                    // alert(respData.tokens.access);
                    // localStorage.setItem('bms_user', JSON.stringify(res.data));
                    const accessToken = respData.tokens.access.token;
                    const accessTokenExpiry = respData.tokens.access.expires;
                    inLocalStorageJwt.setAuthToken(accessToken, accessTokenExpiry);
                    const refreshToken = respData.tokens.refresh.token;
                    const refreshTokenExpiry = respData.tokens.refresh.expires;
                    inLocalStorageJwt.setRefreshToken(refreshToken, refreshTokenExpiry);
                    userContext.userData = respData.user;
                    inLocalStorageJwt.setLoggedinUserData(respData.user);
                    loginContext.updateAuth(true);
                    toast.success(`${res.data.message}`, {
                      position: 'top-right',
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    if (res.data.status === true && redirectTo) {
                      navigate(redirectTo, { replace: true });
                      return;
                    }
                    if (res.data.status === true) {
                      navigate('/app/dashboard', { replace: true });
                    }
                  }
                })
                .catch((error) => {
                  // what now?
                  // console.log(error.response.data.message);
                  setSubmitting(false);
                  // Error
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error(`${error.response.data.message}`, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest
                    // in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error);
                  }
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Verify your Email and phone number
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Please check your inbox to get the otp
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email_otp && errors.email_otp)}
                  fullWidth
                  helperText={touched.email_otp && errors.email_otp}
                  label={`Enter OTP for  ${emailId}`}
                  margin="normal"
                  name="email_otp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email_otp}
                  variant="outlined"
                />
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {/* Not received the otp?
                  {' '} */}
                  {
                    resendEmailCount < 3
                    && (
                      <Link
                        to="/"
                        component={RouterLink}
                        // className="disabledCursor"
                        cursor="pointer"
                        onClick={(event) => { event.preventDefault(); reSendEmailOtp(); }}
                      >
                        {secondsEmail}
                        {secondsEmail > 0 ? ' seconds' : ''}
                      </Link>
                    )
                  }
                  {
                    resendEmailCount === 3
                    && (
                      <small>Maximum Limit Reached, Please try after some time.</small>
                    )
                  }
                </Typography>
                <TextField
                  error={Boolean(touched.phone_otp && errors.phone_otp)}
                  fullWidth
                  helperText={touched.phone_otp && errors.phone_otp}
                  label={`Enter OTP for  ${phoneNum}`}
                  margin="normal"
                  name="phone_otp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone_otp}
                  variant="outlined"
                />
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {/* Not received the otp?
                  {' '} */}
                  {
                    resendPhoneCount < 3
                    && (
                      <Link
                        to="/"
                        component={RouterLink}
                        // className="disabledCursor"
                        cursor="pointer"
                        onClick={(event) => { event.preventDefault(); reSendPhoneOtp(); }}
                      >
                        {secondsPhone}
                        {secondsPhone > 0 ? ' seconds' : ''}
                      </Link>
                    )
                  }
                  {
                    resendPhoneCount === 3
                    && (
                      <small>Maximum Limit Reached, Please try after some time.</small>
                    )
                  }
                </Typography>
                {/* <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box> */}
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Verify Now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default VerifyemailPhoneView;
