/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Button
} from '@material-ui/core';
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';


const useStyles = makeStyles(() => ({
  root: {},
}));
const Exportpdf = ({ className, data, ...rest }) => {
  const classes = useStyles();

  const [list, setList] = useState();
  const [owner, setOwner] = useState();

  const tempList = {
      people: [
        { name: "Keanu Reeves", profession: "Actor" },
        { name: "Lionel Messi", profession: "Football Player" },
        { name: "Cristiano Ronaldo", profession: "Football Player" },
        { name: "Jack Nicklaus", profession: "Golf Player" },
      ]
    };
  useEffect(() => {
    console.log(data)
    setList(tempList);
  }, []);
  function getDatefromid(id){
    const timestamp = id.toString().substring(0,8);
    const date = new Date( parseInt( timestamp, 16 ) * 1000 );
    return date;
  }
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Applicant List";
    const headers = [["Date", "NAME", "CITY", "PHONE", "EMAIL", "QUALIFICATION", "Meeting Time"]];

    const dataList = data && data.map(apl=> [
      moment(getDatefromid(apl.id)).format('DD/MM/YYYY'),
      apl.name,
      apl.city,
      apl.phone,
      apl.email,
      apl.qualification,
      moment(apl.conn_time).format('MMM Do, YY hh:mm A'),
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: dataList
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(moment().format('DD/MM/YYYY')+"My-applicants.pdf");
}
return (
  <div>
    <Button
     onClick={() => exportPDF()}
     color="primary"
     variant="contained"
    >
      <CloudDownloadIcon /> pdf
    </Button>
  </div>
);
}
export default Exportpdf;