import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import GroupApiCall from 'src/utils/GroupApiCall';
// import OfficePropTypes from './OfficePropTypes';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  desc: Yup.string().required('Description is required'),
  status: Yup.string().required('Status is required'),
});

const EditOffice = (params) => {
  const { office, officeList } = params;
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // Handle form submission here
    // console.log(values, groupId);
    values.id = office.id; // Assuming office object has an id property
    try {
      const resp = await GroupApiCall.editOffice(values);
      // console.log('Office edited', resp);
      if (resp.status === 200 && resp.office) {
        // onCouponCreated(resp.office);
        resetForm();
        handleDialogClose();
        officeList();
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    } finally {
      setSubmitting(false);
    }
    // Close dialog
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={handleDialogOpen}>
        <EditIcon />
      </IconButton>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Edit Office</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: office.name,
              address: office.address,
              desc: office.desc,
              status: office.status,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div style={{ marginTop: '1px' }}>
                  <Field
                    name="name"
                    label="Name"
                    as={TextField}
                    error={errors.name && touched.name}
                    helperText={errors.name && touched.name && errors.name}
                  />
                </div>
                <div style={{ marginTop: '2px' }}>
                  <Field
                    name="address"
                    label="Address"
                    as={TextField}
                    error={errors.address && touched.address}
                    helperText={errors.address && touched.address && errors.address}
                  />
                </div>
                <div style={{ marginTop: '15px' }}>
                  <Field
                    name="desc"
                    label="Description"
                    as={TextField}
                    multiline
                    rows={4}
                    variant="outlined"
                    error={errors.desc && touched.desc}
                    helperText={errors.desc && touched.desc && errors.desc}
                  />
                </div>
                <div style={{ marginTop: '15px' }}>
                  <FormControl>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Field
                      name="status"
                      as={Select}
                      labelId="status-label"
                      error={errors.status && touched.status}
                      helperText={errors.status && touched.status && errors.status}
                    >
                      <MenuItem value="open">Open</MenuItem>
                      <MenuItem value="closed">Closed</MenuItem>
                    </Field>
                  </FormControl>
                </div>
                <DialogActions>
                  <Button onClick={handleDialogClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

// EditOffice.propTypes = {
//   office: OfficePropTypes.isRequired,
// };

export default EditOffice;
