/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
// import FileUploader from 'src/components/FileUploader';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  withStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Yup from 'yup';
import Radio from '@material-ui/core/Radio';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik, ErrorMessage } from 'formik';
import ApiCall from 'src/utils/ApiCall';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import axios from 'axios';
import { toast } from 'react-toastify';
// import 'react-dropzone-uploader/dist/styles.css';
// import { DropzoneArea } from 'material-ui-dropzone';

// const states = [
//   {
//     value: 'alabama',
//     label: 'Alabama'
//   },
//   {
//     value: 'new-york',
//     label: 'New York'
//   },
//   {
//     value: 'san-francisco',
//     label: 'San Francisco'
//   }
// ];
const useStateRef = require('react-usestateref');

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  textarea: {
    resize: 'both'
  },
  errorMessage: {
    color: 'red'
  },
  hideInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    opacity: '0'
  },
  previewThumbnail: {
    height: '200px',
    width: '200px'
  }
}));

const CreateForm = ({ className, trainingId, ...rest }) => {
  const classes = useStyles();
  const [thumbnailSrc, setThumbnailSrc] = useState();
  const navigate = useNavigate();
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const formikRef = useRef();
  const { catId } = useParams();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [elementCount, SetElementCount] = useState('');
  const [trainingData, SetTrainingData] = useStateRef({});
  const options1 = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  // Get the training categories of this user
  const getCategories = async () => {
    const categ = await ApiCall.getAllCategories(getLoggedinUserData.id);
    const newList = categ.map((obj) => {
      if (catId === obj.id) {
        formikRef.current.setFieldValue('category', obj.id ? obj.id : '', false);
        setCategory(
          { name: obj.name, value: obj.id }
        );
      }

      return {
        id: obj.id,
        name: obj.name,
      };
    });
    setCategories(newList);
  };

  // Get the training categories of this user
  const getTrainingCount = async () => {
    const categ = await ApiCall.getTrainingCount(catId);
    SetElementCount(categ);
    return true;
  };
  useEffect( () => {
    getCategories();
    if (catId) {
      getTrainingCount();
    }
    async function getTraining() {
      if (trainingId) {
        SetTrainingData({ id: trainingId });
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/trainings/view/${trainingId}`, options1)
          .then(async (res) => {
            // console.log(res.data.training);
            SetTrainingData(res.data.training);
            const { trainingCountinCat } = res.data;
            SetTrainingData({ ...trainingData, trainingCountinCat });
            // console.log(trainingData);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    getTraining();
    ApiCall.createActivity(getLoggedinUserData.id, 'page_navigation', 'Training', 'Check in to Create-Training page.');
  }, []); // Or [] if effect doesn't need props or state
  const handleRadChange = (event) => {
    formikRef.current.setFieldValue('visibility', event.target.value);
  };
  const handleTypeRadChange = (event) => {
    formikRef.current.setFieldValue('type', event.target.value);
  };
  useEffect(() => {
    if (trainingData.id && formikRef.current) {
      // console.log(trainingData);
      formikRef.current.setFieldValue('name', trainingData.name ? trainingData.name : '', false);
      formikRef.current.setFieldValue('trainer', trainingData.trainer ? trainingData.trainer : '', false);
      formikRef.current.setFieldValue('topic', trainingData.topic ? trainingData.topic : '', false);
      formikRef.current.setFieldValue('video_link', trainingData.video_link ? trainingData.video_link : '', false);
      formikRef.current.setFieldValue('audio_link', trainingData.audio_link ? trainingData.audio_link : '', false);
      formikRef.current.setFieldValue('document_link', trainingData.document_link ? trainingData.document_link : '', false);
      formikRef.current.setFieldValue('visibility', trainingData.visibility ? trainingData.visibility : '', false);
      formikRef.current.setFieldValue('sequence', trainingData.sequence ? trainingData.sequence : '', false);
      formikRef.current.setFieldValue('category', trainingData.category ? trainingData.category.id : '', false);
      setCategory(
        trainingData.category
          ? { name: trainingData.category.name, value: trainingData.category.id } : {}
      );
      // formikRef.current.setFieldValue('thumbnail', trainingData.thumbnail ?
      // trainingData.thumbnail : '', false);
      if (trainingData.thumbnail) {
        setThumbnailSrc(process.env.REACT_APP_SERVER_BASE_URL + trainingData.thumbnail);
      }
      formikRef.current.setFieldValue('notes', trainingData.notes ? trainingData.notes : '', false);
      formikRef.current.setFieldValue('type', trainingData.type ? trainingData.type : '', false);
    }
  }, [trainingData]);
  // const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']; [a-zA-Z0-9_]*
  const UrlRegExp = /((https?):\/\/)?(www.)?[a-z0-9-_]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        name: '',
        trainer: '',
        topic: '',
        video_link: '',
        audio_link: '',
        document_link: '',
        thumbnail: '',
        notes: '',
        visibility: 'private',
        category: '',
        sequence: elementCount,
        type: 'training'
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Training name is required.'),
        trainer: Yup.string().required('Trainer name is required'),
        topic: Yup.string().required('Topic is required'),
        video_link: Yup.string().matches(UrlRegExp, 'Enter correct url!').required('Please enter video link.'),
        audio_link: Yup.string().matches(UrlRegExp, 'Enter correct url!'),
        document_link: Yup.string().matches(UrlRegExp, 'Enter correct url!'),
        thumbnail: Yup.mixed(),
        notes: Yup.string().required('Please enter notes.'),
        visibility: Yup.string().required('Please select visibility'),
        category: Yup.string().required('Please select category'),
        sequence: Yup.string().required('Please select sequence'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        // console.log(values.thumbnail);
        // const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
        // console.log(getLoggedinUserData);
        // const form = new FormData();
        // const options = {
        //   headers: {
        //     'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
        //     Authorization: `Bearer ${AuthToken}`,
        //   }
        // };
        const form = new FormData();
        const options = {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
            Authorization: `Bearer ${AuthToken}`,
          }
        };
        setSubmitting(false);
        // const postData = {
        //   name: values.name,
        //   trainer: values.trainer,
        //   topic: values.topic,
        //   video_link: values.video_link,
        //   audio_link: values.audio_link,
        //   document_link: values.document_link,
        //   thumbnail: values.thumbnail,
        //   notes: values.notes,
        // };
        form.append('name', values.name);
        form.append('trainer', values.trainer);
        form.append('topic', values.topic);
        form.append('video_link', values.video_link);
        form.append('audio_link', values.audio_link);
        form.append('document_link', values.document_link);
        form.append('visibility', values.visibility);
        form.append('category', values.category);
        form.append('sequence', values.sequence);
        form.append('type', values.type);

        if (values.thumbnail) {
          form.append('thumbnail', values.thumbnail);
        }
        form.append('notes', values.notes);
        if (trainingId) {
          axios.patch(`${process.env.REACT_APP_API_BASE_URL}/trainings/update/${trainingId}`, form, options)
            .then(async (res) => {
              // setReportList(res.data.dsreports);
              // console.log(res.data.updatedTraining);
              setSubmitting(false);
              toast.success(`${res.data.message}`, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // navigate('/app/trainings', { replace: true });
              navigate(`/app/training/details-page/${trainingId}`);
            })
            .catch((error) => {
              console.log(error);
              setSubmitting(false);
              console.log(error);
              if (error.response) {
                toast.error(`${error.response.data.message}`, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
        } else {
          axios.post(`${process.env.REACT_APP_API_BASE_URL}/trainings/create`, form, options)
            .then(async (res) => {
              // setReportList(res.data.dsreports);
              setSubmitting(false);
              toast.success(`${res.data.message}`, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              if (res.data.training) {
                const trId = res.data.training.id;
                navigate(`/app/training/details-page/${trId}`);
              }
              // navigate('/app/trainings', { replace: true });
            })
            .catch((error) => {
              console.log(error);
              setSubmitting(false);
              console.log(error);
              if (error.response) {
                toast.error(`${error.response.data.message}`, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
        }
        // navigate('/app/dashboard', { replace: true });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        setFieldValue,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader="Enter Details"
              title="Training"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Training Name"
                    name="name"
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.trainer && errors.trainer)}
                    helperText={touched.trainer && errors.trainer}
                    fullWidth
                    label="Trainer Name"
                    name="trainer"
                    onChange={handleChange}
                    required
                    value={values.trainer}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.topic && errors.topic)}
                    helperText={touched.topic && errors.topic}
                    fullWidth
                    label="Training Topic"
                    name="topic"
                    multiline
                    onChange={handleChange}
                    required
                    value={values.topic}
                    variant="outlined"
                    inputProps={{ className: classes.textarea }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.video_link && errors.video_link)}
                    helperText={touched.video_link && errors.video_link}
                    fullWidth
                    label="Video Link"
                    name="video_link"
                    required
                    onChange={handleChange}
                    value={values.video_link}
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.audio_link && errors.audio_link)}
                    helperText={touched.audio_link && errors.audio_link}
                    fullWidth
                    label="Audio Link (Optional)"
                    name="audio_link"
                    onChange={handleChange}
                    value={values.audio_link}
                    variant="outlined"
                    multiline
                    inputProps={{ className: classes.textarea }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.document_link && errors.document_link)}
                    helperText={touched.document_link && errors.document_link}
                    fullWidth
                    label="Document Link (Optional)"
                    name="document_link"
                    onChange={handleChange}
                    value={values.document_link}
                    multiline
                    inputProps={{ className: classes.textarea }}
                    variant="outlined"
                  />
                </Grid> */}
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  {/* <TextField
                    error={Boolean(touched.thumbnail && errors.thumbnail)}
                    helperText={touched.thumbnail && errors.thumbnail}
                    fullWidth
                    label="Upload thumbnail"
                    name="thumbnail"
                    // onChange={handleChange}
                    onChange={(event) => {
                      console.log(event);
                      // values.thumbnail = event.currentTarget.files[0];
                      // setFieldValue("thumbnail", event.currentTarget.files[0]);
                    }}
                    required
                    value={values.thumbnail}
                    variant="outlined"
                    type="hidden"
                    inputProps={{ className: classes.textarea }}
                  /> */}
                  {/* <FileUploader /> */}
                  {/* <DropzoneArea
                    accept="image/*"
                    maxFiles={1}
                    // multiple={Boolean(false)}
                    // error={Boolean(touched.thumbnail && errors.thumbnail)}
                    // helperText={touched.thumbnail && errors.thumbnail}
                    onChange={(file) => setFieldValue("thumbnail", file)}
                  /> */}
                  <Button
                    color="primary"
                    fullWidth
                    variant="text"
                    // className={classes.textarea}
                  >
                    <input
                      id="imageButton"
                      accept=".png, .jpg, .jpeg"
                      className={classes.hideInput}
                      type="file"
                      onChange={(event) => {
                        setFieldValue('thumbnail', event.target.files[0]);
                        setThumbnailSrc(URL.createObjectURL(event.target.files[0]));
                      }}
                    />
                    Upload Thumbnail Picture (Optional)
                  </Button>
                  { thumbnailSrc ? <img className={classes.previewThumbnail} alt="preview" src={thumbnailSrc} /> : ''}

                  {/* { thumbnailSrc ? <div>remove</div> : ''} */}
                  {/* {errors.thumbnail && touched.thumbnail ? (
                    <div>{touched.thumbnail && errors.thumbnail}</div>
                    ) : null} */}
                  {/* <ErrorMessage name="thumbnail" className={classes.errorMessage} /> */}
                  <ErrorMessage name="thumbnail">
                    { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                  </ErrorMessage>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={touched.notes && errors.notes}
                    fullWidth
                    label="Notes"
                    name="notes"
                    onChange={handleChange}
                    required
                    value={values.notes}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.radioLabel}
                  >
                    Training visibility:
                    <HtmlTooltip
                      title={
                        (
                          <>
                            <em>
                              <b>Private:</b>
                              Only for you, and who has permission.
                            </em>
                            <em>
                              <b>Public:</b>
                              any body can see it outside of app,
                            </em>
                            <em>
                              <b>unlisted:</b>
                              person with link in app can see it.
                            </em>
                          </>
                      )
                    }
                    >
                      <InfoIcon />
                    </HtmlTooltip>
                  </Grid>
                  <FormControlLabel
                    checked={values.visibility === 'private'}
                    value="private"
                    name="private"
                    control={<Radio inputProps={{ 'aria-label': 'B' }} />}
                    label="Private (Default)"
                    onChange={handleRadChange}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    checked={values.visibility === 'public'}
                    value="public"
                    name="public"
                    control={<Radio inputProps={{ 'aria-label': 'C' }} />}
                    label="Public"
                    onChange={handleRadChange}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    checked={values.visibility === 'unlisted'}
                    value="unlisted"
                    name="unlisted"
                    control={<Radio inputProps={{ 'aria-label': 'D' }} />}
                    label="Unlisted"
                    onChange={handleRadChange}
                    labelPlacement="start"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Autocomplete
                    id="cat-drop-down"
                    name="category"
                    fullWidth
                    // onChange={handleChange}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        values.category = newValue.id;
                        setCategory(newValue);
                      } else {
                        values.category = '';
                      }
                    }}
                    onBlur={handleBlur}
                    autoHighlight
                    value={category}
                    options={categories}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option) => option.name || ''}
                    renderInput={(params) => (
                      <TextField
                        error={Boolean(touched.category && errors.category)}
                        helperText={touched.category && errors.category}
                        {...params}
                        label="Select Category"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.sequence && errors.sequence)}
                    helperText={touched.sequence && errors.sequence}
                    fullWidth
                    label="Select sequence"
                    name="sequence"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    select
                    SelectProps={{ native: true }}
                    value={values.sequence}
                    variant="outlined"
                  >
                    <option value="" key="" />
                    {trainingData.trainingCountinCat
                    && [...Array(trainingData.trainingCountinCat)].map((x, i) => (
                      <option
                        key={Number(i)}
                        value={i + 1}
                      >
                        {i + 1}
                      </option>
                    ))}
                    {
                      !trainingData.trainingCountinCat
                      && [...Array(elementCount + 1)].map((x, i) => (
                        <option
                          key={Number(i) * 2}
                          value={i + 1}
                        >
                          {i + 1}
                        </option>
                      ))
                    }
                  </TextField>
                </Grid>
                {getLoggedinUserData.role === 'admin'
                && (<Grid
                      item
                      md={6}
                      xs={12}
                    >
                      Type of Content
                      <FormControlLabel
                        checked={values.type === 'training'}
                        value="private"
                        name="private"
                        control={<Radio inputProps={{ 'aria-label': 'B' }} />}
                        label="Training (Default)"
                        onChange={handleTypeRadChange}
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        checked={values.type === 'testimony'}
                        value="testimony"
                        name="testimony"
                        control={<Radio inputProps={{ 'aria-label': 'B' }} />}
                        label="Testimony"
                        onChange={handleTypeRadChange}
                        labelPlacement="start"
                      />
                    </Grid>
                  )}
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={isSubmitting}
              >
                Save details
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

CreateForm.propTypes = {
  className: PropTypes.string,
  trainingId: PropTypes.string
};

export default CreateForm;
