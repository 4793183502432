import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import ApiCall from 'src/utils/ApiCall';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Sales = ({ className, userId, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [dsrData, setDsrData] = React.useState([]);
  const [data, Setdata] = React.useState();
  // const data = {
  //   datasets: [
  //     {
  //       backgroundColor: colors.indigo[500],
  //       data: [18, 5, 19, 27, 29, 19, 20],
  //       label: 'This year'
  //     },
  //     {
  //       backgroundColor: colors.grey[200],
  //       data: [11, 20, 12, 29, 30, 25],
  //       label: 'Last year'
  //     }
  //   ],
  //   labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug', '7 Aug']
  // };

  React.useEffect(() => {
    async function fetchData() {
      // You can await here
    //   console.log(userId);
      if (!userId) {
        return;
      }
      const response = await ApiCall.getDsrData(userId);
      setDsrData(response);
      // ...
    }
    fetchData();
  }, [userId]); // Or [] if effect doesn't need props or state
  React.useEffect(() => {
    const thisWeeekData = [];
    const thisWeeekDates = [];
    const thisWeeekTP = [];

    if (dsrData && dsrData.length > 0) {
      dsrData.sort((a, b) => {
        const adate = new Date(a.date);
        const bdate = new Date(b.date);
        return adate - bdate;
      });
      dsrData.forEach((dsr) => {
        thisWeeekData.push(dsr.no_of_ppt);
        thisWeeekTP.push(dsr.no_of_tp);
        thisWeeekDates.push(format(new Date(dsr.date), 'd MMM'));
      });
    }
    const tempdata = {
      datasets: [
        {
          backgroundColor: colors.indigo[500],
          data: thisWeeekData,
          label: 'PPT'
        },
        {
          backgroundColor: '#FB8C02',
          data: thisWeeekTP,
          label: 'Third Parties'
        }
      ],
      labels: thisWeeekDates
    };
    Setdata(tempdata);
  }, [dsrData]); // Or [] if effect doesn't need props or state

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [1],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        action={(
          <Button
            endIcon={<ArrowDropDownIcon />}
            size="small"
            variant="text"
          >
            Last 7 Entries
          </Button>
        )}
        title="Latest Ppts"
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          {
            data && (
              <Bar
                data={data}
                options={options}
              />
            )
          }
        </Box>
      </CardContent>
      <Divider />
      {/* <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Overview
        </Button>
      </Box> */}
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string
};

export default Sales;
