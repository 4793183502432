import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Grid,
  Card,
  Typography,
} from '@material-ui/core';
import Page from 'src/components/Page';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';
// import Results from './Results';
import UserContext from 'src/contexts/UserContext';
import TaskContext from 'src/contexts/TaskContext';
import axios from 'axios';
import List from './List';
import Toolbar from './Toolbar';
import TaskCard from './TaskCard';
// import data from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  taskList: {
    paddingTop: '20px'
  },
  Toolbar: {
    margin: '10px'
  },
  Header: {
    margin: '10px'
  },
  spacer: {
    marginTop: '10px'
  },
  mainContainer: {
    padding: theme.spacing(0.5)
  }
}));

const TaskListView = () => {
  const classes = useStyles();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const userContext = useContext(UserContext);
  const [tasks, setTasks] = useState([]);
  const SuperRoles = ['paid_user', 'admin', 'bms_promotor'];

  // console.log(getLoggedinUserData);
  // const [customers] = useState(data);
  const fetchTasks = async () => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/my-tasks`, options)
      .then((res) => {
        setTasks(res.data.assignedTasks);
        // console.log(tasks);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchTasks();
    userContext.userData = getLoggedinUserData;
    ApiCall.createActivity(getLoggedinUserData.id, 'page_navigation', 'Task', 'Check in to Task page.');
    // console.log(userContext.userData);
    // console.log(userContext);
  }, []);
  const taskContextVal = {
    fetchTasks
  };
  return (
    <TaskContext.Provider value={taskContextVal}>
      <Page
        className={classes.root}
        title="Tasks"
      >
        <Container className={classes.mainContainer} maxWidth={false}>
          {
            SuperRoles.indexOf(userContext.userData.role) > -1
              ? (
                <>
                  <Toolbar className={classes.Toolbar} />
                  <Card className={classes.taskList}>
                    <Grid
                      container
                      spacing={3}
                    >
                      <List />
                    </Grid>
                  </Card>
                  <Card mt={3} className={classes.spacer}>
                    <Grid
                      container
                      spacing={3}
                    >
                      {tasks && tasks.length > 0 && (
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Typography
                            align="left"
                            color="textPrimary"
                            gutterBottom
                            variant="h4"
                            className={classes.Header}
                          >
                            Task Assigned to me
                          </Typography>
                        </Grid>
                      )}
                      {tasks && tasks.map((task) => (
                        <Grid
                          item
                          key={task.id}
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <TaskCard
                            className={classes.trainingCard}
                            task={task}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Card>
                </>
              )
              : (
                <Box mt={3}>
                  <Grid
                    container
                    spacing={3}
                  >
                    {tasks && tasks.map((task) => (
                      <Grid
                        item
                        key={task.id}
                        lg={6}
                        md={6}
                        xs={12}
                      >
                        <TaskCard
                          className={classes.trainingCard}
                          task={task}
                        />
                      </Grid>
                    ))}
                    {
                      (!tasks || tasks.length === 0)
                      && (
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Typography
                            align="center"
                            color="textPrimary"
                            gutterBottom
                            style={{ backgroundColor: 'white' }}
                            variant="h4"
                            className={classes.Header}
                          >
                            No task found.
                          </Typography>
                        </Grid>
                      )
                    }
                  </Grid>
                </Box>
              )
          }
        </Container>
      </Page>
    </TaskContext.Provider>
  );
};
export default TaskListView;
