import React from 'react';
import {
  Container,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import CouponList from './CouponList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  mainContainer: {
    padding: theme.spacing(0.5)
  },
}));

const Coupons = () => {
  const classes = useStyles();
  return (
    <Page
      className={classes.root}
      title="Products"
    >
      <Container className={classes.mainContainer} maxWidth={false}>
        <CouponList />
      </Container>
    </Page>
  );
};

export default Coupons;
