/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import axios from 'axios';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton,
  Grid
} from '@material-ui/core';
import {
  Eye as ShowPassword,
  EyeOff as HidePassword
} from 'react-feather';
import Page from 'src/components/Page';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RegisterView = (params) => {
  const redirectToPublic = params.redirectToPublic;
  const classes = useStyles();
  const navigate = useNavigate();
  // const phoneRegExp = /^[0-9]{2}[0-9]{8}$/;
  const phoneRegExp = /^[0-9]{10}$/;
  const formikRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [referralCode, setReferralCode] = useState(searchParams.get('referral_code'));
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const DELAY = 1500;
  const [{
    callback, value, load, expired
  }, SetRecaptcha] = useState({
    callback: 'not fired',
    value: '[empty]',
    load: false,
    expired: 'false'
  });
  const reCaptchaRef = React.createRef();
  function onCaptchaChange(valueChanged) {
    // console.log('Captcha value:', valueChanged);
    formikRef.current.setFieldValue('reCaptcha', valueChanged, false);
    if (valueChanged === null) SetRecaptcha({ expired: 'true' });
  }
  useEffect(() => {
    setTimeout(() => {
      SetRecaptcha({ load: true });
      // console.log('didMount - reCaptcha Ref-', reCaptchaRef);
    }, DELAY);
  }, []);
  const asyncScriptOnLoad = () => {
    SetRecaptcha({ callback: 'called!' });
    // console.log('scriptLoad - reCaptcha Ref-', reCaptchaRef);
  };

  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            innerRef={formikRef}
            initialValues={{
              email: '',
              phone: '',
              firstName: '',
              lastName: '',
              password: '',
              confirm_password: '',
              referred_by: referralCode ? referralCode : '',
              policy: false,
              reCaptcha: null
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(100).required('Email is required'),
                phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
                firstName: Yup.string().max(100).required('First name is required'),
                lastName: Yup.string().max(100).required('Last name is required'),
                referred_by: Yup.string().max(18, 'Invalid Referral code.').min(18, 'Invalid Referral code.'),
                password: Yup.string().max(15).required('password is required').matches(
                  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                  'Password must contain at least 8 characters, one uppercase, one number and one special case character'
                ),
                confirm_password: Yup.string().when('password', {
                  is: (val) => (val && val.length > 0),
                  then: Yup.string().oneOf(
                    [Yup.ref('password')],
                    'Both password need to be the same'
                  )
                }).required('Confirm password is required'),
                policy: Yup.boolean().oneOf([true], 'This field must be checked'),
                reCaptcha: Yup.string().required('please click here').nullable()
              })
            }
            onSubmit={(values, { setSubmitting }) => {
              const recaptchaValue = reCaptchaRef.current.getValue();
              if (!recaptchaValue) {
                alert('Please check the Google captcha.');
                return;
              }
              const postData = {
                fname: values.firstName,
                lname: values.lastName,
                email: values.email,
                password: values.password,
                phone: values.phone,
                password_confirmation: values.confirm_password,
                referred_by: values.referred_by,
                toc: values.policy
              };
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                }
              };
              axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/register`, postData, options)
                .then((res) => {
                  // console.log(res);
                  // console.log(res.data);
                  toast.success(`${res.data.message}`, {
                    position: 'top-right',
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  if(redirectToPublic && res.data.status === true){
                    navigate(redirectToPublic, { replace: true });
                    navigate(`/verify?emailId=${values.email}&phoneNum=${values.phone}&token=${res.data.verifyToken}&redirect=${redirectToPublic}`, { replace: false });
                    return;
                  }
                  if (res.data.status === true) {
                    navigate(`/verify?emailId=${values.email}&phoneNum=${values.phone}&token=${res.data.verifyToken}`, { replace: false });
                  }
                })
                .catch((error) => {
                  // what now?
                  // console.log(error.response.data.message);
                  setSubmitting(false);
                  // Error
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error(`${error.response.data.message}`, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest
                    // in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error);
                  }
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Create new account
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use your email to create new account
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label="First name"
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label="Last name"
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  label="Phone Number"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                  }}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // type="password"
                  value={values.password}
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
                  InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <ShowPassword /> : <HidePassword />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  error={Boolean(touched.confirm_password && errors.confirm_password)}
                  fullWidth
                  helperText={touched.confirm_password && errors.confirm_password}
                  label="Confirm Password"
                  margin="normal"
                  name="confirm_password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirm_password}
                  variant="outlined"
                  // type={showPassword ? 'text' : 'password'}
                  // InputProps={{ // <-- This is where the toggle button is added.
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <IconButton
                  //         aria-label="toggle password visibility"
                  //         onClick={handleClickShowPassword}
                  //         onMouseDown={handleMouseDownPassword}
                  //       >
                  //         {showPassword ? <ShowPassword /> : <HidePassword />}
                  //       </IconButton>
                  //     </InputAdornment>
                  //   )
                  // }}
                />
                <TextField
                  error={Boolean(touched.referred_by && errors.referred_by)}
                  fullWidth
                  helperText={touched.referred_by && errors.referred_by}
                  label="Do you have referral code? if yes then kindly enter the code."
                  margin="normal"
                  name="referred_by"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.referred_by}
                  variant="outlined"
                />
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="/terms-and-conditions"
                      target='_blank'
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {true && (
                      <>
                        <ReCAPTCHA
                          style={{ display: 'inline-block' }}
                          theme="dark"
                          size="compact"
                          ref={reCaptchaRef}
                          sitekey="6LdCs3IaAAAAAGJ_pizzCwTKQmm4mMxjh6NGSmOM"
                          onChange={onCaptchaChange}
                          asyncScriptOnLoad={asyncScriptOnLoad}
                        />
                        <ErrorMessage name="reCaptcha">
                          { (msg) => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign up now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
