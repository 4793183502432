/* eslint-disable */
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#000000',
    color: 'white !important',
    marginTop: '0px'
  },
  iconBtn: {
    color: 'white !important',
    padding: '3px'
  },
  input: {
    width: 42,
  },
});
const PrettoSlider = withStyles({
    root: {
      color: '#325ca8',
      height: 3,
      width: '99%',
    },
    thumb: {
      height: 15,
      width: 15,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 3,
      borderRadius: 4,
    },
    rail: {
      height: 3,
      borderRadius: 4,
    },
  })(Slider);

const InputSlider = (props) => {
  const classes = useStyles();
  const { volumebar, handleMute, handlePlay, handlePause, handleCustomFullScreen, playingStatus, duration, seekToSeconds, reactControl } = props;
  const [value, setValue] = React.useState(30);
  // const [videovalue, setVideoValue] = React.useState(playingStatus.currentPlaying);

  const [muted, setMuted] = useState(true);
  const [playing, setPlaying] = useState(false);
  const handleSliderChange = (event, newValue) => {
    volumebar(newValue);
    setValue(newValue);
  };
  const handleVolumeUp = () => {
    handleMute();
    setMuted(!muted);
  };
  function convertTime(sec) {
    var hours = Math.floor(sec/3600);
    (hours >= 1) ? sec = sec - (hours*3600) : hours = '00';
    var min = Math.floor(sec/60);
    (min >= 1) ? sec = sec - (min*60) : min = '00';
    (sec < 1) ? sec='00' : void 0;
    (min.toString().length == 1) ? min = '0'+min : void 0;
    (sec.toString().length == 1) ? sec = '0'+sec : void 0;
    // const formattedMin = min < 10 ? `0${min}` : `${min}`;
    const formattedSec = Math.floor(sec) < 10 ? `0${Math.floor(sec)}` : `${Math.floor(sec)}`;
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;

    if(hours === '00'){
      return min+':'+formattedSec;
    }
    return formattedHours+':'+min+':'+formattedSec;
  }
  const handlePlayPause = () => {
    if(!reactControl.playing){
        handlePlay();
    } else {
        handlePause();
    }
    // setPlaying(!playing);
  };
  const handlevideoSlider = (e, newvalue) => {
    // console.log(newvalue);
    seekToSeconds(newvalue);
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={1} style={{textAlign:"center"}}>
        <Grid item xs={12}>
          <PrettoSlider key={`slider-${playingStatus.currentPlaying}`} min={0} max={duration} onChange={handlevideoSlider} aria-labelledby="input-slider" defaultValue={typeof playingStatus.currentPlaying === 'number' ? playingStatus.currentPlaying : 0} />
        </Grid>
      </Grid>
      <Grid container style={{textAlign:"center"}}>
        <Grid item>
          <IconButton className={classes.iconBtn} onClick={handlePlayPause}>
            {reactControl.playing? <PauseIcon /> : <PlayArrowIcon /> }
          </IconButton>
          <IconButton className={classes.iconBtn} onClick={handleVolumeUp}>
            {muted? <VolumeOffIcon /> : <VolumeUp /> }
          </IconButton>
        </Grid>
        <Grid item xs={3} sm={2}>
          <PrettoSlider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
            <Typography>
                {convertTime(playingStatus.currentPlaying)} / {convertTime(duration)}
            </Typography>
        </Grid>
        <Grid item style={{position:'absolute', right: '5px'}}>
          <IconButton className={classes.iconBtn} aria-label="next" onClick={handleCustomFullScreen}>
            <FullscreenIcon></FullscreenIcon>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

InputSlider.propTypes = {
  volumebar: PropTypes.func,
  playingStatus: PropTypes.object,
  reactControl: PropTypes.object,
};

export default InputSlider;
