/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { FormControl, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as Yup from 'yup';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useFormik } from 'formik';
import MeetingApiCall from 'src/utils/MeetingApiCall';

const validationSchema = Yup.object().shape({
  date: Yup.date().required('Date is required'),
  name: Yup.string().required('Name is required'),
  phone: Yup.string().matches(/^\d{10}$/, 'Phone number is invalid').required('Phone is required'),
  senior: Yup.string().required('Senior selection is required'),
  otherSenior: Yup.string().when('senior', {
    is: 'other',
    then: Yup.string().required('Please enter other Senior')
  })
});

const MyForm = (params) => {
  const { meetingId, slot, title, dropDownlist, onClose } = params;
  const defaultDate = new Date();
  const formik = useFormik({
    initialValues: {
      date: defaultDate,
      name: '',
      phone: '',
      senior: '',
      otherSenior: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Logic to handle submitting the form
      // console.log('Meeting details:', values);
      const resp = await MeetingApiCall.createParticipant(meetingId, values);
      if (resp.status === 200) {
        onClose(); // Close the dialog after submitting the form
      }
    },
  });

  const handleOtherSeniorChange = (event) => {
    formik.setFieldValue('otherSenior', event.target.value);
  };
  useEffect(() => {
    if (!dropDownlist.some(option => option.value === 'other')) {
      dropDownlist.push({ label: 'other', value: 'other' });
    }
  }, []);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form onSubmit={formik.handleSubmit}>
        <KeyboardDatePicker
          name="date" // Ensure that the name attribute matches Formik field name
          id="date-picker"
          label="Select Date"
          format="MM/dd/yyyy"
          value={formik.values.date}
          maxDate={new Date()} // This line restricts future dates
          onChange={(date) => formik.setFieldValue('date', date)}
          error={Boolean(formik.touched.date && formik.errors.date)}
          helperText={formik.touched.date && formik.errors.date}
          fullWidth
        />

        <FormControl fullWidth margin="normal">
          <TextField
            id="name"
            name="name"
            label="Name"
            {...formik.getFieldProps('name')}
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            id="phone"
            name="phone"
            label="Phone"
            {...formik.getFieldProps('phone')}
            error={Boolean(formik.touched.phone && formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            fullWidth
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Autocomplete
            options={dropDownlist}
            getOptionLabel={(option) => option.label}
            onChange={(_, value) => formik.setFieldValue('senior', value ? value.value : '')}
            value={dropDownlist.find((option) => option.value === formik.values.senior) || null}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                id="senior"
                name="senior"
                label="Select Senior"
                error={Boolean(formik.touched.senior && formik.errors.senior)}
                helperText={formik.touched.senior && formik.errors.senior}
                fullWidth
              />
            )}
          />
          {formik.values.senior === 'other' && (
            <TextField
              id="other-senior"
              label="Other Senior"
              name="otherSenior"
              value={formik.values.otherSenior}
              onChange={handleOtherSeniorChange}
              onBlur={formik.handleBlur}
              fullWidth
              style={{ marginBottom: '10px' }}
              error={Boolean(formik.touched.otherSenior && formik.errors.otherSenior)}
              helperText={formik.touched.otherSenior && formik.errors.otherSenior}
            />
          )}
        </FormControl>

        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </MuiPickersUtilsProvider>
  );
};

export default MyForm;
