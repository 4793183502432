/*eslint-disable*/
import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors,
  Button
} from '@material-ui/core';
import axios from 'axios';
import ApiCall from 'src/utils/ApiCall';
import {Link as RouterLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import config from 'src/components/paytm/mocks/merchant-config';
import { CheckoutProvider, Checkout } from 'paytm-blink-checkout-react';
import Passdetails from './Passdetails';

const useStateRef = require('react-usestateref');

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}));

const TakePass = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [openBlink, SetOpenBlink] = useStateRef(false);
  const [isloading, Setisloading] = useStateRef(false);
  const [pricingData, SetPricingData] = useStateRef([]);
  config.handler = {
    notifyMerchant: function (eventName, data) {
      console.log('notifyMerchant handler function called');
      console.log('eventName => ', eventName);
      if (eventName === 'APP_CLOSED') {
        Setisloading(false);
        ApiCall.deleteALlIncompleteOrder();
      }
      console.log('data => ', data);
    }
  };
  const options1 = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  useEffect(() => {
    async function getPricing() {
      // SetPricingData({ id: trainingId });
      // console.log(tiers);
      await axios.get(`${process.env.REACT_APP_API_BASE_URL}/plans/get-all?name=BMS-LEARNER`, options1)
        .then(async (res) => {
          console.log(res.data.result.length);
          if (res.data.result.length > 0) {
            SetPricingData(res.data.result[0]);
          }
          // console.log(pricingData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getPricing();
  }, []); // Or [] if effect doesn't need props or state
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          {/* <Grid item> */}
            {/* <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              TAKE MY LEARNER PASS
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
              style={{ textDecoration: "line-through"}}
            >
              1999
              <span>₹</span>
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {pricingData.price}
              <span>₹</span>
            </Typography> */}
            {/* <Passdetails /> */}
          {/* </Grid> */}
          {/* <Grid item>
            <Button
              fullWidth
              variant="contained"
              disabled={isloading}
              onClick={async () => {
                SetOpenBlink(false);
                Setisloading(true);
                // alert("We are working on it. please wait. we will update you.");
                const isAuth = await inLocalStorageJwt.checkAuth();
                // // console.log("api caled", isAuth);
                if (!isAuth) {
                  // alert("login before buying.");
                  toast.info('Please login before placing your order.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  navigate('/login?redirect=pricing');
                  return;
                }
                const checksum = await ApiCall.makePurchase(pricingData.id);
                // console.log(checksum);
                const tempData = {
                  orderId: checksum.body.orderId,
                  amount: checksum.body.txnAmount.value,
                  token: checksum.body.txnToken,
                  tokenType: 'TXN_TOKEN',
                  userDetail: checksum.body.userInfo
                };
                config.data = tempData;
                SetOpenBlink(true);
              }}
              color="primary"
            >
              {isloading ? 'Loading' : 'Avail'}
            </Button>
          </Grid> */}
          <Grid item>
            <Typography>
              <span> Oh! You are free user.</span>
              <span> Get the chance to become premium.</span>
              <Button
                color="primary"
                component={RouterLink}
                to="/pricing"
                variant="contained"
              >
                Get premium
              </Button>
            </Typography>
          </Grid>
        </Grid>
        {
          openBlink === true
          && (
          <CheckoutProvider config={config} env={process.env.REACT_APP_PAYTM_ENV}>
            <Checkout />
          </CheckoutProvider>
          )
        }
      </CardContent>
    </Card>
  );
};

TakePass.propTypes = {
  className: PropTypes.string
};

export default TakePass;
