/* eslint-disable */
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
// @material-ui/icons

// core components
import HeaderNoLinks from "src/components/Header/HeaderNoLinks.js";
import FooterNoLinks from "src/components/Footer/FooterNoLinks.js";
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
// import Button from "src/components/CustomButtons/Button.js";
import HeaderLinks from "src/components/Header/HeaderLinks.js";
import Parallax from "src/components/Parallax/Parallax.js";
import { useParams, useNavigate } from 'react-router-dom';
import styles from "src/assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
// import ProductSection from "./Sections/ProductSection.js";
// import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
import landingbg from "src/assets/img/landing-bg.jpg";

// A custom hook that builds on useLocation to parse
// the query string for you.
// function useQuery() {
//     return new URLSearchParams(useLocation().search);
//   }
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const navigate = useNavigate();
    //   const query = useQuery();
    //   const userId = query.get('userId');
  const [formdata, SetFormdata] = useState();
  let { userId } = useParams();
  // console.log(userId);
  const fetchFormData = async () => {
    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/work-application-form/${userId}`, options)
      .then((res) => {
        if (res.data.applicationData) {
          SetFormdata(res.data.applicationData);
        }
        // console.log(res.data.applicationData);
      })
      .catch((error) => {
        console.log(error);
        // navigate('/404', { replace: true });
      });
  };
  useEffect(() => {
    fetchFormData();
  }, []);
  const { ...rest } = props;
  return (
    <div>
      <HeaderNoLinks
        color="transparent"
        routes={dashboardRoutes}
        brand="BMS GLOBAL"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        topTitle={formdata && formdata.workapplication && formdata.workapplication[0].topTitle}
        {...rest}
      />
      <Parallax filter image={ landingbg }>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
      <h1 className={classes.title} style={{ 'fontSize': '3em' }}>{formdata && formdata.workapplication && formdata.workapplication[0].largeTitle ? formdata.workapplication[0].largeTitle: 'Learn early. Learn often.' }</h1>
              <h4 style={{ 'fontSize': '1.125rem', 'fontWeight': '300', 'fontFamily': '"Roboto", "Helvetica", "Arial", sans-serif'}}>
                {formdata && formdata.workapplication && formdata.workapplication[0].description ? formdata.workapplication[0].description : '' }
              </h4>
              <br />
              {/* <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {/* <ProductSection />
          <TeamSection />*/}
          {formdata
          && (
            <WorkSection
              meetingSlots={formdata && formdata.workapplication && formdata.workapplication[0].meetingSlots}
              userId={userId}
              formHeader={formdata && formdata.workapplication && formdata.workapplication[0].formHeader}
              userName={formdata && formdata.name? formdata.name : 'Us'}
            /> 
          )}
        </div>
      </div>
      <FooterNoLinks />
    </div>
  );
}
