/* eslint-disable */
import 'date-fns';
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { green } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
const useStateRef = require('react-usestateref');

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles,
    Checkbox
  } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      }
    },
    textarea: {
      resize: 'both'
    },
    radioLabel: {
      margin: theme.spacing(1)
    }
  }));
const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);
const TaskDialog = ({ className, isOpen,category, ...rest }) => {
//   const { isOpen } = props;
  const [open, setOpen] = React.useState(isOpen);
  const formikRef = useRef();
  const navigate = useNavigate();
  const classes = useStyles();
  const [usersArray, SetusersArray] = useState([]);
  const [userval, SetUserval] = useStateRef({});
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleRadChange = (event) => {
    if (event.target.value === 'no') {
      formikRef.current.setFieldValue('emailNotify', false);
    }
    if (event.target.value === 'yes') {
      formikRef.current.setFieldValue('emailNotify', true);
    }
    formikRef.current.setFieldValue('isNotify', event.target.value, false);
  };
  const fetchUsers = async (page) => {
    const perPage = 100;
    const offset = (page - 1) * perPage;
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();

    // setLoading(true);
    // const postData = {
    //   createdBy: getLoggedinUserData.id,
    //   skip: offset,
    //   limit: perPage
    // };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    const filters = `?bms_sponsor=${getLoggedinUserData.email}&skip=${offset}&limit=${perPage}&is_followup=${false}&gen=1`;
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users${filters}`, options)
      .then(async (res) => {
        // console.log(res.data.result.users);
        const users = res.data.result.users.map((user) => (
             { name: user.fname, userid: user.id }
        ));
        SetusersArray(users);
        // console.log(usersArray);
        // setTotalRows(res.data.result.totalUsers);
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchUsers(1);
  }, []);

  useEffect(() => {
    console.log(isOpen);
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Assign this folder [{category && category.name}] as Task</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          <Formik
      innerRef={formikRef}
      initialValues={{
        title: '',
        user: [],
        deadline: new Date(),
        isNotify: 'no',
        emailNotify: true,
        phoneNotify: false
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255).required('Title is required'),
        user: Yup.array().required('User is required'),
        deadline: Yup.date().required('Deadline is required')
          .test('testTime', 'Please enter future time.', (date) => {
            // your logic
            const currentTime = new Date().getTime();
            // console.log(date.getTime(), currentTime);
            if (date.getTime() > currentTime) {
              // errors.deadline = 'please select only future date and time.';
              return date;
            }
            return false;
          })
      })}
      onSubmit={((values, { setSubmitting }) => {
        const postData = {
          category: category.id,
          title: values.title,
          user: values.user,
          deadline: values.deadline,
          isNotify: values.isNotify,
          phoneNotify: values.isNotify === 'yes' ? values.phoneNotify : false,
          emailNotify: values.isNotify === 'yes' ? values.emailNotify : false
        };
        // const response = await axios.get(
        //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
        // );
        
          axios.post(`${process.env.REACT_APP_API_BASE_URL}/tasks/assign-folder`, postData, options)
            .then(async (res) => {
              // setReportList(res.data.dsreports);
              // console.log(res.data);
              setSubmitting(false);
              toast.success(`${res.data.message}`, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              navigate('/app/tasks', { replace: true });
            })
            .catch((error) => {
            if(error){
              console.log(error);
              setSubmitting(false);
              toast.error(`${error.response.data.message}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
              
            });
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        setFieldValue,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader="Enter your task details."
            //   title="Assign the task here."
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                  container
                  // justifyContent="space-around"
                >
                  <TextField
                    error={Boolean(touched.title && errors.title)}
                    fullWidth
                    helperText={touched.title && errors.title}
                    label="Task Title"
                    name="title"
                    onChange={handleChange}
                    required
                    type="text"
                    onBlur={handleBlur}
                    value={values.title}
                    variant="outlined"
                  />
                </Grid>

                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Autocomplete
                    id="user-drop-down"
                    name="user"
                    fullWidth
                    multiple
                    onChange={(event, newValue) => {
                      if (newValue) {
                        // values.user.push(newValue.userid);
                        values.user = newValue;
                        SetUserval(newValue);
                      } else {
                        values.user = '';
                        SetUserval({});
                      }
                      console.log(values.user, newValue);
                    }}
                    onBlur={handleBlur}
                    filterSelectedOptions
                    // value={userval}
                    options={usersArray}
                    getOptionLabel={(option) => option.name || ''}
                    // getOptionSelected={(option) => option.name || ''}
                    renderInput={(params) => (
                      <TextField
                        error={Boolean(touched.user && errors.user)}
                        helperText={touched.user && errors.user}
                        {...params}
                        label="Select User"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  {/* <TextField
                    error={Boolean(touched.deadline && errors.deadline)}
                    helperText={touched.deadline && errors.deadline}
                    fullWidth
                    id="task_deadline"
                    label="Task Deadline"
                    type="datetime-local"
                    defaultValue="2017-05-24T10:30"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.deadline}
                    variant="outlined"
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      disablePast
                      onChange={(date) => {
                        setFieldValue('deadline', date, errors.deadline);
                      }}
                      error={Boolean(touched.deadline && errors.deadline)}
                      helperText={touched.deadline && errors.deadline}
                      fullWidth
                      label="Task Deadline"
                      name="deadline"
                      required
                      value={values.deadline}
                      variant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.radioLabel}
                  >
                    Do you want to send the notification?
                  </Grid>
                  <FormControlLabel
                    checked={values.isNotify === 'no'}
                    value="no"
                    control={<Radio inputProps={{ 'aria-label': 'B' }} />}
                    label="No"
                    onChange={handleRadChange}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    checked={values.isNotify === 'yes'}
                    value="yes"
                    control={<GreenRadio inputProps={{ 'aria-label': 'C' }} />}
                    label="Yes"
                    onChange={handleRadChange}
                    labelPlacement="start"
                  />
                </Grid>
                {
                  values.isNotify && values.isNotify === 'yes'
                  && (
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <div className={classes.radioLabel}>
                        <FormControlLabel
                          control={<Checkbox checked={values.emailNotify} onChange={handleChange} name="emailNotify" />}
                          label="Email Notification"
                        />
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={values.phoneNotify}
                              onChange={handleChange}
                              name="phoneNotify"
                              color="primary"
                            />
                          )}
                          label="Phone Notification"
                        />
                      </div>
                    </Grid>
                  )
                }
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Grid
                item
                style={{ margin: '3px' }}
              >
                <Button
                  // color="secondary"
                  // style={{ backgroundColor: '#21b6ae' }}
                  variant="contained"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                style={{ margin: '3px' }}
              >
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Save details
                </Button>
              </Grid>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

TaskDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  category: PropTypes.object.isRequired
};

export default TaskDialog;
