/* eslint-disable */
import React, { useRef, useEffect } from 'react';
import WebViewer from '@pdftron/pdfjs-express';
import './PdfViewer.css';

const PdfViewer = (file) => {
  const viewer = useRef(null);
  // const file = 'https://bmsglobal.s3.ap-south-1.amazonaws.com/uploads/resume/sell+like+crazy+preface.pdf';

  // if using a class, equivalent of componentDidMount
  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer/lib',
        initialDoc: file.file,
      },
      viewer.current,
    ).then((instance) => {
      instance.UI.disableElements(['header']);
      // instance.UI.disableElements(['viewControlsButton']);
      instance.UI.disableElements(['leftPanelButton']);
      instance.UI.disableElements(['searchButton']);
      instance.UI.disableElements(['menuButton']);
      instance.UI.disableElements(['pageNavOverlay']);
      const { docViewer, Annotations } = instance;
      const annotManager = docViewer.getAnnotationManager();

      docViewer.on('documentLoaded', () => {
        const rectangleAnnot = new Annotations.RectangleAnnotation();
        rectangleAnnot.PageNumber = 1;
        // values are in page coordinates with (0, 0) in the top left
        rectangleAnnot.X = 100;
        rectangleAnnot.Y = 150;
        rectangleAnnot.Width = 250;
        rectangleAnnot.Height = 250;
        rectangleAnnot.Author = annotManager.getCurrentUser();

        // annotManager.addAnnotation(rectangleAnnot);
        // need to draw the annotation otherwise it won't show up until the page is refreshed
        // annotManager.redrawAnnotation(rectangleAnnot);
      });
    });
  }, []);

  return (
    <div className="PdfViewer">
      {/* <div className="header">Here is the file</div> */}
      <div className="webviewer" ref={viewer} />
    </div>
  );
};

export default PdfViewer;
