/* eslint-disable */
import React, { useContext } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { 
    Box,
    Button,
    Container,
    Grid,
    Link,
    TextField,
    Typography,
    makeStyles,
    InputAdornment,
    IconButton,
    Fab
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import CategoryContext from 'src/contexts/CategoryContext';

const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();

function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    fabEdit: {
      float: 'right',
      color: 'blue',
      margin: theme.spacing(1)
    },
  }));
export default function SimpleModal(category) {
  const classes = useStyles();
  const categoryContext = useContext(CategoryContext); 
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
  const body = (
    // <div style={modalStyle} className={classes.paper}>
    <Formik
      initialValues={{
        folder_name: category.name,
        // folder_slug: ''
    }}
      validationSchema={Yup.object().shape({
        folder_name: Yup.string().max(255, 'only less than 255 character.').required('Please enter folder name'),
        // folder_slug: Yup.string().max(255).required('Slug is required')
    })}
      onSubmit={(values, { setSubmitting }) => {
        const postData = {
          name: values.folder_name,
          slug: slugify(values.folder_name),
          type: 'training',
          user: getLoggedinUserData.id
        };

        const options = {
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${AuthToken}`,
            }
        };
        axios.patch(`${process.env.REACT_APP_API_BASE_URL}/categories/update/${category.id}`, postData, options)
        .then((res) => {
            const respData = res.data.data;
            console.log(respData);
            // console.log(res.data.data.tokens);
            toast.success(`${res.data.message}`, {
              position: 'top-right',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            categoryContext.getCategories();
            handleClose();
        })
        .catch((error) => {
            // what now?
            // console.log(error.response.data.message);
            setSubmitting(false);
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                toast.error(`${error.response.data.message}`, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest
                // in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error);
            }
        });
        // navigate('/app/dashboard', { replace: true });
    }}
    >
    {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
    }) => (
        <form onSubmit={handleSubmit}>
        {/* <Box mb={3}>
            <Typography
            color="textPrimary"
            variant="h2"
            >
            Folder Create
            </Typography>
            <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
            >
            {/* Sign in on the internal platform */}
            {/* </Typography> */}
        {/* </Box> */}
        <TextField
            error={Boolean(touched.folder_name && errors.folder_name)}
            fullWidth
            helperText={touched.folder_name && errors.folder_name}
            label="Folder Name"
            margin="normal"
            name="folder_name"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.folder_name}
            variant="outlined"
        />
        
        <Box my={2}>
            <Button
            color="primary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            >
            Update
            </Button>
        </Box>
        </form>
    )}
    </Formik>
  );

  return (
    <div>
      <Fab 
        size='small'
        aria-label="delete" 
        className={classes.fabEdit}
        onClick={handleOpen}
      >
        <EditIcon />
      </Fab>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          /> */}
          {body}
          <Box>
            <Button
                // color="red"
                fullWidth
                size="large"
                variant="contained"
                onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
