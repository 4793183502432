import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
// import { Link as RouterLink } from 'react-router-dom';
// import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
// import ApiCall from 'src/utils/ApiCall';
// import axios from 'axios';
import Page from 'src/components/Page';
// import Confetti from 'src/components/Confetti/Confetti';
import GroupMemberApiCall from 'src/utils/GroupMemberApiCall';
import Groups from './Groups';
import OfficeList from './OfficeList';
import Breadcrumbs from './BreadCrumbs';
// import Tools from './Components/Tools';
// import TrafficByDevice from './TrafficByDevice';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  mainContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  //   const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  //   const AuthToken = AuthTokenObject.authToken;
  //  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const { groupId } = useParams();
  const [groupData, setGroupData] = useState({});
  const [myOffices, setMyOffices] = useState([]);
  const itemsArray = [
    { page: 'Home', href: '/app/dashboard' },
  ];
  const groupDetails = async () => {
    try {
      const resp = await GroupMemberApiCall.groupDetails(groupId);
      if (resp.status === 200 && resp.group) {
        setGroupData(resp.group);
        // console.log(resp.mygroups);
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    } finally {
      // setSubmitting(false);
    }
  };

  const myOfficesFun = async () => {
    try {
      const resp = await GroupMemberApiCall.myOffices(groupId);
      if (resp.status === 200 && resp.myOffices) {
        setMyOffices(resp.myOffices);
        // console.log(resp.mygroups);
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    } finally {
      // setSubmitting(false);
    }
  };

  useEffect(() => {
    groupDetails();
    myOfficesFun();
  }, []);
  return (
    <Page
      className={classes.root}
      title="Group Details"
    >
      <Container maxWidth={false} className={classes.mainContainer}>
        <Breadcrumbs itemsArray={itemsArray} className={classes.spacer} lastPage={{ page: 'Super-5' }} />
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Groups groupData={groupData} />
          </Grid>
          <OfficeList officeList={myOffices} />
          {/* <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <Tools />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
