import axios from 'axios';
import { toast } from 'react-toastify';
// import inLocalStorageJwt from './inLocalStorageJwt';

const getTraining = async (trainingId) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    }
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}trainings/view-public/${trainingId}`, options);
    // toast.success(`${response.data.message}`, {
    //   position: 'top-right',
    //   autoClose: 10000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
    return { status: 200, training: response.data.training, message: response.data.message };
  } catch (error) {
    // console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, training: '', message: error.response.data.message };
  }
};
export default {
  getTraining
};
