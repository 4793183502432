import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {
  Grid, Avatar, TextField, IconButton
} from '@material-ui/core';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LinkIcon from '@material-ui/icons/Link';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    height: 64,
    width: 64
  },
  actionButtons: {
    marginTop: '10px'
  }
});

export default function SimpleCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2} className={classes.containerG}>
          <Grid item>
            <Avatar
              className={classes.avatar}
              id="profile_pic"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuQVDZzmYwKrMZsWyCUCfRqP-QK7vHJqTl0gCCqkuu0g&s"
              alt="profile picture"
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="outlined-multiline-static"
              fullWidth
              multiline
              rows={4}
              placeholder="What is in your mind?"
              variant="outlined"
            />
            <Grid
              justify="space-between" // Add it here :)
              container
              spacing={2}
              className={classes.actionButtons}
            >
              <Grid item>
                <div>
                  <IconButton color="secondary" aria-label="delete">
                    <PhotoLibraryIcon />
                  </IconButton>
                  <IconButton color="secondary" aria-label="add an alarm">
                    <AttachFileIcon />
                  </IconButton>
                  <IconButton color="primary" aria-label="add to shopping cart">
                    <LinkIcon />
                  </IconButton>
                  <IconButton color="primary" aria-label="add to shopping cart">
                    <EmojiEmotionsIcon />
                  </IconButton>
                </div>
              </Grid>
              <Grid item>
                <div>
                  <Button variant="contained" size="small" color="primary">
                    POST
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
