/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Collapse,
  ListItem,
  ListItemIcon,
  capitalize,
  ListItemText
} from '@material-ui/core';
import { BookOpen } from 'react-feather';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import { isMobile } from 'react-device-detect';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import PdfViewer from 'src/utils/PdfViewer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  dropdown: {
    backgroundColor: 'white',
    padding: theme.spacing(2)
  },

}));

const file = 'https://bmsglobal.s3.ap-south-1.amazonaws.com/uploads/resume/sell+like+crazy+preface.pdf';
// const type = 'pdf';

const ProductCard = ({ className, product, ...rest }) => {
  const classes = useStyles();
  const [openTraining, setOpenTraining] = React.useState(false);

  const handleTrainingClick = () => {
    setOpenTraining(!openTraining);
  };
  return (
    <>
    <ListItem className={classes.dropdown} button onClick={handleTrainingClick}>
      <ListItemIcon>
        <BookOpen />
      </ListItemIcon>
      <ListItemText
        primary={capitalize(product.title)}
      />
      {openTraining ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={openTraining} timeout="auto" unmountOnExit>
    <Card
      className={clsx(classes.root, className)}
      //style={{height:"105vw", display: 'block'}}
      {...rest}
    >
      <CardContent
        style={{padding: '0px'}}
      >
        <Box
          height="600px"
          display="block"
          justifyContent="center"
          mb={3}
        >
          {/* <Avatar
            alt="Product"
            src={product.media}
            variant="square"
          /> */}
          {/* <div style={{ overflow: 'scroll', height: 600 }}> */}
            <PdfViewer file={product.media}/>
          {/* </div> */}
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {product.title}
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
          {product.description}
        </Typography>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      {/* <Box p={2}>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
        >
          <Grid
            className={classes.statsItem}
            item
          >
            <AccessTimeIcon
              className={classes.statsIcon}
              color="action"
            />
            <Typography
              color="textSecondary"
              display="inline"
              variant="body2"
            >
              Updated 2hr ago
            </Typography>
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >
            <GetAppIcon
              className={classes.statsIcon}
              color="action"
            />
            <Typography
              color="textSecondary"
              display="inline"
              variant="body2"
            >
              {product.totalDownloads}
              {' '}
              Downloads
            </Typography>
          </Grid>
        </Grid>
      </Box> */}
    </Card>
    </Collapse>
    </>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;
