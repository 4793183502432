import React from 'react';

const Logo = (props) => {
  return (
    <>
      <img
        alt="Logo"
        src="/static/BMS.svg"
        {...props}
        style={{ height: '50px', width: '50px', filter: 'invert(84%) sepia(2%) saturate(583%) hue-rotate(93deg) brightness(119%) contrast(101%)' }}
      />
    </>
  );
};

export default Logo;
