import React from 'react';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AssignTask from './AssignTask';

const options = [
  'Assign as Task'
];
const ITEM_HEIGHT = 48;

export default function LongMenu(category) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isOpenTask, setIsOpenTask] = React.useState(false);
  // const catId = category.id;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpenTask(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpenTask(true);
  };

  return (
    <div>
      <Fab
        aria-label="more"
        size="small"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Fab>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
            {option}
          </MenuItem>
        ))}
      </Menu>
      {isOpenTask && <AssignTask category={category} isOpen={isOpenTask} />}
    </div>
  );
}
