/* eslint-disable */
import React from 'react';
import Faq from 'react-faq-component';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { BusinessSharp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    heroContent: {
      padding: theme.spacing(8, 0, 6),
    },
}));
const data = {
  title: "FAQ (How it works)",
  rows: [
    {
      title: "What is BMS Global?",
      content: "BMS Global is the online system which helps all business owners to manage their daily stuff in the office like team training, task, lead generation, recruitments, reports etc."
    },
    {
      title: "Who can use BMS?",
      content: "Any types of business owners, who wants to manage the team digitally. Learner can also use BMS for learning and Blogging."
    },
    {
      title: "How can I register / Create my account  on BMS?",
      content: " Step 1: Open registration (sign up) form. <br /> Step 2: Fill details accordingly.<br /> Step 3: Use Referral Id ( Optional).<br /> Step 4: Creat BMS Login Passcode and sumit.<br /> Step 5: Enter OTP of EMAIL / MOBILE accordingly.<br /> Step 6: Congratulations! You are done with Registration . Thank you."
    },
    {
      title:   "How to update daily work in DSR?",
      content: " Step 1: Open Dashboard.<br /> Step 2: Click on Menu Bar top Right corner. <br />Step 3: Choose Daily Report option. <br /> Step 4: Fill the form  ."
    },
    {
      title:  "How to add NEW patners?",
      content: "Step 1: Open Dashboard.<br /> Step 2: Click on Menu Bar top Right corner. <br />Step 3: Choose My Patners option . <br /> Step 4: Click on Add Partner and fill the details accordingly. Thank you.  ."
    },
    {
      title: "How to make Training Liabrary?",
      content: " Step 1: Open Dashboard. <br /> Step 2: Click on Menu Bar top Right corner.<br /> Step 3: Choose  Trainings . Step 5: Click on Add Training in top right corner and fill the details accordingly. Thank you  ."
      
    },
    {
      title:   " How to Assign Task to Patners?",
      content: " Step 1: Open Dashboard. <br /> Step 2: Click on Menu Bar top Right corner. <br /> Step 3: Choose TASK .<br /> Step 4: Click on ADD TASK button Top right corner & fill details accordingly.Thank You."
    },
    {
      title: "How to Check partners List?",
      content: " Step 1: Open Dashboard.<br /> Step 2: Click on Menu Bar top Right corner. <br /> Step 3: Choose MY PARTNERS & check the list.Thank you."
    },
    {
      title: "How to Check Partners Profile Available on BMS?",
      content: " Step 1: Open Dashboard.<br /> Step 2: Click on Menu Bar top Right corner.<br /> Step 3: Choose MY PARTNERS.<br /> Step 4: Seclect the name and click on EYE BUTTON in same line in Right side. Thank You."
    },
    {
      title: " What is tracking system?",
      content: " It will help you to track the activity of your fellow Partners that how they are performing with the give task."
    },
    {
      title: "How can I Track my Patner's Activity/Performance?",
      content: " You can  check your Partners activity by entering to their Profile and clicking on activity option."
    },
    {
      title: "What is Premier & How it works?",
      content: " BMS GLOBAL lets you and your selcted Partners watch and experience a Training video together in real time with Live Tracking of the activity done by the partner during the Permier video. The working of permier is like, You can set a Training video on Premier for the future time and at that time Video will be autometically starts for thr person who is their for the meeting."
    },
    {
      title:  " What is Live Tracking on Premier?",
      content: " Most of time its not easy to see your team watching your provided video properly or not or he/she is playing with time stands by doing forward and seek back or they are playing video in Background and using Social media for fun in their learning time."
    },
    {
      title:   "How to Share & Earn?",
      content: " Step 1: Open Dashboard.<br /> Step 2: Click on Menu Bar top Right corner. <br /> Step 3: Choose Share & Earn . Step 4: Create your Referral Code & Copy it . <br /> Step 5: Share the Link & Referral id . <br /> Step 6: <br /> At the time of Registration Paste your Refrral id."
      
    },
    {
      title:   " How to Withdraw my Earnings on BMS?" ,
      content: " Step 1: Open Dashboard. <br /> Step 2: Click on Menu Bar top Right corner.<br /> Step 3: Choose the Share & Earn.<br /> Step 4: Check your Closing Balance amount & Click on Withdraw my earnings & Fill the form Details accordingly ."
    },
    {
      title:   " How to Coustmize Application form on BMS?" ,
      content: " Step 1: Open Dashboard. <br /> Step 2: Click on Menu Bar top Right corner. <br /> Step 3: Choose Setting > Click on Account > scroll down > Business Tool > Fill details of your chocice > Save Details ."
    },
    {
      title:  " What is the Benifits of Using BMS GLOBAL?",
      content: " Every Entrepreuner can manage their work Easily on fingertips within couple of minutes. You can save your Time and can invest on finding new leads and you will boost your works ."
    },
    {
      title:  " What is Professional Recognization provided to Network by BMS GLOBAL",
      content: "A person working in IT company is Recognized / Respected  as a professional worker in Society. A person working as Accountant is  Recognized / Respected  as a professional worker in Society. Well thats very good but if a single person  is working as a Networker  he/she does not get the same level respect & Recognization in their Own network & in society. Today, We (BMS GLOBAl)  have  taken this initiative to make it clear that Every single Networker / Business owner / Director  gets the Professional  Recognization & Respect in own Network and in society."
    },
    
  ]
}
const styles = {
    // bgColor: 'white',
    titleTextColor: "black",
    // rowTitleColor: "blue",
    rowTitleTextSize: 'large',
    rowContentPaddingLeft: '10px',
    // rowContentColor: 'grey',
    rowContentTextSize: '16px',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};
export default function Detail() {
  const classes = useStyles();

    return (
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
          <Faq
            data={data}
            styles={styles}
            config={config}
          />
        </Container>
    );
}