/* eslint-disable */
import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import WorkApplicationContext from 'src/contexts/WorkApplicationContext';
import { isMobile } from 'react-device-detect';


const useStyles = makeStyles((theme) => ({
    active: {
      backgroundColor: '#3f51b5',
      color: '#fff'
    },
}));
const DateFilter = () => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const workApplicationContext = useContext(WorkApplicationContext);
  const [activeButton, setActiveButton] = useState('');

  const handleDateFilter = (daysToAdd, buttonName) => {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + daysToAdd);
    workApplicationContext.updateDateFilter(newDate);
    setSelectedDate(newDate);
    setActiveButton(buttonName);
    console.log(buttonName);
  };
  const handleReset = (val) => {
    workApplicationContext.updateDateFilter(val);
    setActiveButton('');
    setActiveButton('All');
  }
//   const formatDate = (date) => {
//     const options = { year: 'numeric', month: 'long', day: 'numeric' };
//     return date.toLocaleDateString(undefined, options);
//   };

  return (
    <Grid
      container
      spacing={1} 
      style={{fontSize: isMobile ? '12px' : '14px' }}
    >
      <Grid item>
        <p>Appointment:</p>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          size="small"
          onClick={() => handleDateFilter(0, 'Today')}
          style={{fontSize: isMobile ? '12px' : '14px', height: isMobile ? '25px' : '30px' }}
          className={activeButton === 'Today' ? classes.active : ''}
        >
          Today
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          size="small"
          onClick={() => handleDateFilter(-1, 'Yesterday')}
          style={{fontSize: isMobile ? '12px' : '14px', height: isMobile ? '25px' : '30px' }}
          className={activeButton === 'Yesterday' ? classes.active : ''}
        >
          Yesterday
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          size="small"
          onClick={() => handleDateFilter(1, 'Tomorrow')}
          style={{fontSize: isMobile ? '12px' : '14px', height: isMobile ? '25px' : '30px' }}
          className={activeButton === 'Tomorrow' ? classes.active : ''}
        >
          Tomorrow
        </Button>
      </Grid>
      <Grid item>
        <Button
          style={{fontSize: isMobile ? '12px' : '14px', height: isMobile ? '25px' : '30px' }}
          variant="contained"
          size="small"
          className={activeButton === 'All' ? classes.active : ''}
          onClick={() => handleReset('')}
        >
          All
        </Button>
      </Grid>
    </Grid>
  );
};

export default DateFilter;
