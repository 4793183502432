/* eslint-disable */
import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Paper
} from '@material-ui/core';
import ProfileProgress from './ProfileProgress';
import About from './About';
import NewPostForm from './NewPostForm';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
}));

const ProductList = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4}>
          <ProfileProgress />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <NewPostForm />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <About />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Paper className={classes.paper}>xs=6</Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductList;
