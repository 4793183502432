/*eslint-disable*/

const CONFIG = {
    style: {
        bodyBackgroundColor: "#fafafb",
        bodyColor: "",
        themeBackgroundColor: "#dfa231",
        themeColor: "#ffffff",
        headerBackgroundColor: "#284055",
        headerColor: "#ffffff",
        errorColor: "",
        successColor: "",
        card: {
            padding: "",
            backgroundColor: ""
        }
    },
    jsFile: "",
    data: {
        orderId: "dummyTest3978",
        amount: "3337",
        token: "gndghdgdg43545dvbfagdwwsfsfk",
        tokenType: "TXN_TOKEN",
        userDetail: {
            mobileNumber: "8295021023",
            name: "shivam"
        }
    },
    // handler: {
    //     "notifyMerchant": function (eventName, data) {
    //       console.log("notifyMerchant handler function called");
    //       console.log("eventName => ", eventName);
    //       if(eventName === 'APP_CLOSED'){
            
    //       }
    //       console.log("data => ", data);
    //     }
    // },
    merchant: {
        mid: process.env.REACT_APP_PAYTM_MID,
        name: "BMS GLOBAL",
        logo: "",
        redirect: true
    },
    mapClientMessage: {},
    labels: {},
    payMode: {
        // labels: {},
        // filter: {
        //     exclude: []
        // },
        order: [
            "NB",
            "CARD",
            "LOGIN",
            "UPI",
            "EMI"
        ]
    },
    flow: "DEFAULT"
};

export default CONFIG;