import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  // Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid
} from '@material-ui/core';
import CategoryContext from 'src/contexts/CategoryContext';
import { Search as SearchIcon } from 'react-feather';
import FilterMenu from './FilterMenu';
import Create from './Create';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, field, ...rest }) => {
  const classes = useStyles();
  const categoryContext = useContext(CategoryContext);
  const handleSearch = (e) => {
    const searchStr = e.target.value;
    if (searchStr.length >= 3) {
      categoryContext.setsearchStr(searchStr);
    }
    if (searchStr.length === 0) {
      categoryContext.setsearchStr(searchStr);
    }
  };
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        {/* <Button className={classes.importButton}>
          Import
        </Button>
        <Button className={classes.exportButton}>
          Export
        </Button> */}
        {/* <Button
          color="primary"
          variant="contained"
        >
          Add Folder
        </Button> */}
        {field === 'category' && <Create />}
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <Grid container spacing={1}>
                {field === 'category' && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      onKeyUp={handleSearch}
                      placeholder="Search Category"
                      variant="outlined"
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <FilterMenu fieldval={field} />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string,
};

export default Toolbar;
