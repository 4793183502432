/* eslint-disable */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const PermissionForm = (rowData) => {
//   const userId = {...row};
console.log("rowdata",rowData);
  const [state, setState] = React.useState({
    dsrCreate: true,
    dsrRead: true,
    dsrUpdate: true,
    dsrDelete: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={<Checkbox checked={state.dsrCreate} onChange={handleChange} name="dsrCreate" />}
        label="DSR Create"
      />
      <FormControlLabel
        control={<Checkbox checked={state.dsrRead} onChange={handleChange} name="dsrRead" />}
        label="DSR Read"
      />
      <FormControlLabel
        control={<Checkbox checked={state.dsrUpdate} onChange={handleChange} name="dsrUpdate" />}
        label="DSR Update"
      />
      <FormControlLabel
        control={<Checkbox checked={state.dsrDelete} onChange={handleChange} name="dsrDelete" />}
        label="DSR Delete"
      />
      
      
    </FormGroup>
  );
}

export default PermissionForm;
