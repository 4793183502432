/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles,
  Divider,
  List
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { v4 as uuidv4 } from 'uuid';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';

// import IconDashboard from '@material-ui/icons/Dashboard';
// import IconShoppingCart from '@material-ui/icons/ShoppingCart';
// import IconPeople from '@material-ui/icons/People';
// import IconBarChart from '@material-ui/icons/BarChart';
// import IconLibraryBooks from '@material-ui/icons/LibraryBooks';
// import { io } from 'socket.io-client';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavItem = ({
  className,
  item,
  index,
  icon: Icon,
  ...rest
}) => {
  const classes = useStyles();
  // const socket = io('http://localhost:3001');
  // console.log(socket);
  const location = useLocation();
  const [pathName, setPathName] = useState('');
  const [open, setOpen] = React.useState(false);
  function handleClick() {
    setOpen(!open);
  }
  useEffect(() => {
    console.log(location.pathname, item);
    setPathName(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const renderLinks = () => {
    if (item.nested === 'no') {
      return (
        <ListItem
          key={uuidv4()}
          className={clsx(classes.item, className)}
          style={{backgroundColor: (pathName.includes(item.href))? ('#e6e6e6'):''}}
          disableGutters
          {...rest}
        >
          <Button
            activeclassname={classes.active}
            className={classes.button}
            component={RouterLink}
            to={item.href}
          >
            {Icon && (
              <Icon
                className={classes.icon}
                size="20"
              />
            )}
            <span className={classes.title}>
              {item.title}
            </span>
          </Button>
        </ListItem>
      );
    }
    if (item.nested === 'yes') {
      return (
        <>
          <ListItem
            key={uuidv4()}
            button
            onClick={handleClick}
            className={clsx(classes.item, className)}
            disableGutters
            {...rest}
          >
            <Button
              activeclassname={classes.active}
              className={classes.button}
              key={uuidv4()}
            >
              {Icon && (
                <Icon
                  className={classes.icon}
                  size="20"
                />
              )}
              <span className={classes.title}>
                {item.title}
              </span>
              {open ? <IconExpandLess /> : <IconExpandMore />}
            </Button>
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <List
              component="div"
              disablePadding
              key={uuidv4()}
            >
              {item.nestedPage.map((nestedItem) => (
                <ListItem
                  key={uuidv4()}
                  button
                  className={classes.menuItem}
                  component={RouterLink}
                  to={nestedItem.href}
                  style={{backgroundColor: (pathName.includes(nestedItem.href))? ('#e6e6e6'):''}}
                  onLoad={()=> {
                    if(pathName.includes(nestedItem.href)){
                      handleClick();
                    }}}
                >
                  <ListItemText inset primary={nestedItem.title} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      );
    }
    return false;
  };
  return (
    renderLinks()
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  activeclassname: PropTypes.string,
  item: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.boolean
  ]),
  index: PropTypes.number,
  icon: PropTypes.elementType,
};

export default NavItem;
