/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';

const useStyles = makeStyles(({
  root: {}
}));

const Username = ({ className, ...rest }) => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [usernameExists, setUsernameExists] = useState(false);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  // const [values, setValues] = useState({
  //   password: '',
  //   confirm: ''
  // });
  const handleChange = async (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername);
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/check-username/${newUsername}`);
        setUsernameExists(response.data.exists);
    } catch (error) {
        console.error('Error checking username:', error);
    }
  };

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };

  return (
    <Formik
      initialValues={{
        username: ''
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().max(15).required('username is required')
        .test('is-unique', 'Username already exists', async function(value) {
          if (!value) return true; // Allow empty username
         
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/check-username/${value}`, options);
          return !response.data.exists;
        })
      })}
      onSubmit={(values, { setSubmitting }) => {
        // const AuthTokenObject = inLocalStorageJwt.getAuthToken();
        // const AuthToken = AuthTokenObject.authToken;
        const postData = {
          username: values.username
        };
        console.log(postData);
        // const options = {
        //   headers: {
        //     'Content-Type': 'application/json',
        //     Authorization: `Bearer ${AuthToken}`,
        //   }
        // };
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/update-username`, postData, options)
          .then((res) => {
            const respData = res.data.data;
            console.log(res);
            // console.log(res.data.data.tokens);
              toast.success(`${res.data.message}`, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              if (res.data.status === true) {
                // inLocalStorageJwt.logout();
                 navigate('/app/dashboard', { replace: true });
              }
          })
          .catch((error) => {
            // what now?
            // console.log(error.response.data.message);
            setSubmitting(false);
            // Error
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
              toast.error(`${error.response.data.message}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest
              // in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error);
            }
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
        {...rest}
      >
        <Card>
          <CardHeader
            subheader="Update username"
            title="Username"
          />
          <Divider />
          <CardContent>
            <TextField
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
              fullWidth
              label="Enter username"
              margin="normal"
              name="username"
              id="username"
              onChange={handleChange}
              type="text"
              value={values.username}
              variant="outlined"
            />
            {usernameExists && <p>Username already exists</p>}
          </CardContent>
          <Divider />
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              type="submit"
            >
              Update
            </Button>
          </Box>
        </Card>
      </form>
      )}
    </Formik>
  );
};

Username.propTypes = {
  className: PropTypes.string
};

export default Username;
