/* eslint-disable */
import { useCallback, useState } from 'react';

const useCenteredTree = () => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 8, y: height / 2 });
    }
  }, []);
  return [translate, containerRef];
};
async function lookup(obj, k, children ) {
    for (var key in obj) {
      var value = obj[key];
    //   if (k === key) {
    //     return [k, value];
    //   }
        if (obj[key] === k) {
            obj['children'] = children;
            return obj;
        }
  
      if (typeof(value) === "object" && !Array.isArray(value)) {
        var y = await lookup(value, k, children);
        // console.log('func call');
        if (y && y[0] === k) return y;
      }
      if (Array.isArray(value)) {
        // for..in doesn't work the way you want on arrays in some browsers
        //
        for (var i = 0; i < value.length; ++i) {
          var nodeFound = await lookup(value[i], k, children);
        //   if (x && x[0] === k) return x;
        if(nodeFound && nodeFound['email'] === value[i]['email']) {
            // console.log("matched");
            value[i]['children'] = nodeFound['children'];
            // console.log(value);
            return value;
        }
        }
      }
    }
    return null;
  }
export default {
  useCenteredTree,
  lookup
};
