/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
// import { format } from 'date-fns';
import moment from 'moment-timezone';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import MeetingApiCall from 'src/utils/MeetingApiCall';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Sales = ({
  className, userId, meetingId, ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [participants, setParticipants] = React.useState([]);
  const [data, Setdata] = React.useState();

  React.useEffect(() => {
    async function fetchData() {
      // You can await here
    //   console.log(userId);
      if (!meetingId) {
        return;
      }
      const response = await MeetingApiCall.participantsTrend(meetingId, 15);
      if (response.status === 200 && response.participants) {
        setParticipants(response.participants);
      }
      // ...
    }
    fetchData();
  }, [userId]); // Or [] if effect doesn't need props or state
  React.useEffect(() => {
    let counts = [];
    let dates = [];

    if (participants && participants.length > 0) {
      // Extract dates and counts from participantData
      dates = participants.map((participant) => moment.utc(new Date(participant.date)).tz('Asia/Kolkata').format('DD MMM'));
      counts = participants.map((participant) => participant.count);
    }
    const tempdata = {
      datasets: [
        {
          backgroundColor: colors.indigo[500],
          data: counts,
          label: 'Candidates'
        },
      ],
      labels: dates,
    };
    Setdata(tempdata);
  }, [participants]); // Or [] if effect doesn't need props or state

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [1],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        action={(
          <Button
            endIcon={<ArrowDropDownIcon />}
            size="small"
            variant="text"
          >
            Last 15 Days
          </Button>
        )}
        title="Candidates Statistics"
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          {
            data && (
              <Bar
                data={data}
                options={options}
              />
            )
          }
        </Box>
      </CardContent>
      <Divider />
      {/* <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Overview
        </Button>
      </Box> */}
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string,
  meetingId: PropTypes.string,
};

export default Sales;
