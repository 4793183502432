import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  // Card,
  // CardContent,
  // TextField,
  // InputAdornment,
  // SvgIcon,
  makeStyles
} from '@material-ui/core';
// import BookContext from 'src/contexts/BookContext';
// import { Search as SearchIcon } from 'react-feather';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import CouponForm from './CouponForm';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  // const bookContext = useContext(BookContext);

  // const handleSearch = (e) => {
  //   const searchStr = e.target.value;
  //   bookContext.filterBooks(searchStr);
  // };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCouponCreated = (newCoupon) => {
    console.log('New coupon created:', newCoupon);
    // You can add logic here to update the coupon list or display a message
  };
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
        >
          Add Coupon
        </Button>
      </Box>
      <Box mt={3}>
        {/* <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                onKeyUp={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search coupon"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card> */}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
          {/* <DialogTitle id="form-dialog-title">Coupon Form</DialogTitle> */}
          <DialogContent>
            {/* <DialogContentText>
              Enter Coupon details
            </DialogContentText> */}
            <CouponForm onCouponCreated={handleCouponCreated} handleClose={handleClose} />
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary">
              Subscribe
            </Button>
          </DialogActions> */}
        </Dialog>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
