/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Container,
  makeStyles,
  Link,
  Button,
  Grid
} from '@material-ui/core';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/Visibility';
import Page from 'src/components/Page';
// import DsrList from './DsrList';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { toast } from 'react-toastify';
// import data from './data';
import { NavLink as RouterLink } from 'react-router-dom';
// import { DesktopDatePicker } from '@material-ui/pickers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DeleteIcon from '@material-ui/icons/Delete';
import EjectIcon from '@material-ui/icons/Eject';
import Countdown from 'react-countdown';
import EditIcon from '@material-ui/icons/Edit';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  },
  formExpndCard: {
    minWidth: 275,
    margin: '15px'
  },
  respHeader: {
    margin:'10px',
    padding: '10px'
  },
  dataBlock: {
    marginTop: '10px',
    paddingTop: '10px'
  },
  statsItem: {
    alignItems: 'center',
    display: 'inline'
  },
}));
// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return true;
  } else {
    // Render a countdown
    return (
      <span>
        {days}:{hours}:{minutes}:{seconds}
      </span>
    );
  }
};
const List = () => {
  const classes = useStyles();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  function addExpiryTofollowup(followupTime){
    const fldate = new Date(followupTime);
    return fldate.setHours(fldate.getHours() + 48);
  }
  function checkFOllowupExpiry(flDate) {
    const fldate = new Date(flDate);
    fldate.setHours(fldate.getHours() + 48);
    const nowT = new Date();
    if(fldate.getTime() < nowT.getTime()){
    console.log('true');
      return true;
    }
    console.log('false');
    return false;
  }
  async function deleteUser(userId,page) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
   await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/users/delete-sponsored/${userId}`, options)
      .then((resp) => {
        // console.log(resp);
        toast.success(`${resp.data.message}`, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        // navigate('/app/customers');
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response.data.message}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
      });
    // console.log("here one");
    fetchUsers(currentPage);
    
  }
  function handleDelete(userId) {
    // console.log('id', dsrId);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { console.log('yes'); deleteUser(userId); }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }
  async function addFollowup(userId) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
   await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/add-to-followup/${userId}`, options)
      .then((resp) => {
        // console.log(resp);
        toast.success(`${resp.data.message}`, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        // navigate('/app/customers');
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response.data.message}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
      });
    // console.log("here one");
    fetchUsers(currentPage);
  }
  async function addActive(userId) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
   await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/add-to-active/${userId}`, options)
      .then((resp) => {
        // console.log(resp);
        toast.success(`${resp.data.message}`, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        // navigate('/app/customers');
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response.data.message}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
      });
    // console.log("here one");
    fetchUsers(currentPage);
  }
  function handleFollowup(userId) {
    confirmAlert({
      title: 'Confirm to send in followup list',
      message: 'Revert is possible only after 48 hours. Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { console.log('yes'); addFollowup(userId); }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }
  function handleActive(userId) {
    confirmAlert({
      title: 'Confirm to send in active list',
      message: 'User will be added back to active List. Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { console.log('yes'); addActive(userId); }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }
  
  const columns = [
    {
      name: <b style={{ fontSize: '15px' }}>First Name</b>,
      selector: 'fname',
      sortable: true,
    //   right: true,
    //   cell: (row) => moment(row.date).format('MMM D, YYYY')
    },
    {
      name: <b style={{ fontSize: '15px' }}>Last Name</b>,
      selector: 'lname',
      sortable: true,
      center: true,
    },
    // {
    //   name: 'Email',
    //   selector: 'email',
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'Phone',
    //   selector: 'phone',
    //   sortable: true,
    //   center: true,
    // },
    {
        name: <b style={{ fontSize: '15px' }}>User Category</b>,
        selector: 'is_followup',
        sortable: true,
        center: true,
        cell: (row) => (<div>{ row.is_followup ? 
          (<Typography
            color="textSecondary"
            display="inline"
            variant="body2"
            style={{backgroundColor:'#ff9933', borderRadius:'5px', margin:'1px', padding: '2px', color: 'white'}}
          >
            Followup
          </Typography>)  :
          (<Typography
            color="textSecondary"
            display="inline"
            variant="body2"
            style={{backgroundColor:'#159932', borderRadius:'5px', width:'100%', margin:'1px', padding: '2px', color: 'white'}}
          >
            Active
          </Typography>) 
          } </div>)
      },
      {
        name: <b style={{ fontSize: '15px' }}>Account Status</b>,
        selector: 'is_followup',
        sortable: true,
        center: true,
        cell: (row) => (<div>{ !row.is_verified ? 
          (<Typography
            color="textSecondary"
            display="inline"
            variant="body2"
            style={{backgroundColor:'#ff9933', borderRadius:'5px', margin:'1px', padding: '2px', color: 'white'}}
          >
            Not-Verified
          </Typography>)  :
          (<Typography
            color="textSecondary"
            display="inline"
            variant="body2"
            style={{backgroundColor:'#159932', borderRadius:'5px', width:'100%', margin:'1px', padding: '2px', color: 'white'}}
          >
            Verified
          </Typography>) 
          } </div>)
      },
    {
      name: <b style={{ fontSize: '15px' }}>Action</b>,
      center: true,
      // cell: (row) => <Link color="primary"
      // component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>Edit</Link>
      cell: (row) => (
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            className={classes.statsItem}
          >
            <Tooltip title="View Profile">
              <IconButton color="primary" component={RouterLink} to={`/app/customer/view/${row.id}`}>
                <ViewIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )
    }
  ];
  const fetchUsers = async (page) => {
    const offset = (page - 1) * perPage;
    setLoading(true);
    // const postData = {
    //   createdBy: getLoggedinUserData.id,
    //   skip: offset,
    //   limit: perPage
    // };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    var filters = `?referred_by=${getLoggedinUserData.referral_code}&skip=${offset}&limit=${perPage}&gen=1`;
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users${filters}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data.result);
        setData(res.data.result.users);
        setTotalRows(res.data.result.totalUsers);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    // await fetchUsers(page);
    // setData(response.data.data);
  };

  useEffect(() => {
    fetchUsers(1);
  }, []);
  useEffect(() => {
    fetchUsers(1);
  }, [perPage]);

  const ExpandedRow = (row) => {
    // console.log(row);
    const applicationOb = row.data;
    const bull = <span className={classes.bullet}>•</span>;
    return (<div className={classes.root}>
           <Grid container spacing={3}>
              <Card className={classes.formExpndCard}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th className={classes.respHeader}>Email</Th>
                      <Th className={classes.respHeader}>Phone</Th>
                      {/* <Th className={classes.respHeader}>Account Status</Th> */}
                      <Th className={classes.respHeader}>Type</Th>
                      {/* <Th className={classes.respHeader}>Notes</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td className={classes.respHeader}>{applicationOb.email}</Td>
                      <Td className={classes.respHeader}><a href={`tel:+91-${applicationOb.phone}`}>{applicationOb.phone}</a></Td>
                      {/* <Td className={classes.respHeader}>{ applicationOb.is_verified ? 
                      (<Typography
                        color="textSecondary"
                        display="inline"
                        variant="body2"
                        style={{backgroundColor:'#159932',borderRadius:'5px', margin:'3px', padding: '5px', color: 'white',textAlign:'center'}}
                      >
                        Verified
                      </Typography>)  : (
                        <Typography
                          color="textSecondary"
                          display="inline"
                          variant="body2"
                          style={{backgroundColor:'#ff9933',borderRadius:'5px', margin:'3px', padding: '5px', color: 'white', textAlign:'center'}}
                        >
                          Not-Verified
                        </Typography>
                      )}</Td> */}
                      <Td className={classes.respHeader}>{applicationOb.role === 'paid_user'? 'Premium User':(applicationOb.role==='learner'? 'Learner' : 'Free User')}</Td>
                      {/* <Td className={classes.respHeader}>{applicationOb.notes}</Td> */}
                    </Tr>
                  </Tbody>
                </Table>
              </Card>
           </Grid>
       </div>);
  };
  const tableData = {
    columns,
    data
  };
  return (
      <Card className={classes.dataBlock}>
        {/* <DsrList dsrList={dsrList} /> */}
        <DataTableExtensions {...tableData}>
          <DataTable
            title="Referred List"
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            filter
            paginationServer
            paginationTotalRows={totalRows}
            // selectableRows
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover
            expandableRows
            expandableRowDisabled={row => row.disabled}
            expandableRowsComponent={<ExpandedRow />}
          />
        </DataTableExtensions>
      </Card>
  );
};

export default List;
