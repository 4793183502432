/* eslint-disable */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {
  Copy as CopyIcon,
  Search as SearchIcon
} from 'react-feather';
import { isMobile, isBrowser } from 'react-device-detect';
import WorkApplicationContext from 'src/contexts/WorkApplicationContext';
// import ApiCall from 'src/utils/ApiCall';
import FilterMenu from './FilterMenu';
import DateFilter from './DateFilter';
import MeetingSlotSetting from './MeetingSlotSetting';
import AddApplicantForm from './AddApplicantForm';
import WhatsappQr from './WhatsappQr';
// import ImportButton from './ImportButton';
// import ImportExcelCsv from './ImportExcelCsv';

const useStateRef = require('react-usestateref');

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  statsItem: {
    margin: '5px'
  }
}));

const Toolbar = ({
  className, formId, field, searchStr, ...rest
}) => {
  const classes = useStyles();
  const [copySuccess, setCopySuccess] = useStateRef('Copy Link');
  const workApplicationContext = useContext(WorkApplicationContext);
  const handleImport = (data) => {
    // Handle the imported data here
    console.log('Imported Data:', data);
  };
  function handleCopy() {
    navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/work-application/${formId}`);
    setCopySuccess(' Copied');
    // console.log(formId, copySuccess);
  }
  const handleChange = async (event) => {
    const str = event.target.value;
    if (str.length > 2 || str.length === 0) {
      workApplicationContext.searchList(event.target.value);
    }
  };
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
        spacing={5}
        key={Math.random()}
      >
        {/* <Button className={classes.importButton}>
          Import
        </Button>
        <Button className={classes.exportButton}>
          Export
        </Button> */}
        <Grid
          className={classes.statsItem}
          item
          key={Math.random()}
        >
          <MeetingSlotSetting />
        </Grid>
        {/* <Grid
          className={classes.statsItem}
          item
          key={Math.random()}
        >
          <ImportButton onImport={handleImport}  />
        </Grid> */}
        {isBrowser &&
        (
          <Grid
            className={classes.statsItem}
            item
            key={Math.random()}
          >
            <WhatsappQr  formId={formId}/>
          </Grid>
        )}
        <Grid
          className={classes.statsItem}
          item
          key={Math.random()}
        >
          <Typography
            color="textSecondary"
            display="inline"
            variant="body2"
            key={Math.random()}
            style={{fontSize: isMobile ? '12px' : '14px' }}
          >
            <Button
              onClick={() => handleCopy(formId)}
              color="primary"
              variant="contained"
              key={Math.random()}
              size="small"
              style={{fontSize: isMobile ? '12px' : '14px' }}
            >
              <CopyIcon
                className={classes.statsIcon}
                style={{ height: isMobile ? '12px' : '14px', width: isMobile ? '12px' : '14px', marginRight: '2px'}}
              />
              {copySuccess}
            </Button>
          </Typography>
        </Grid>
        <Grid
          className={classes.statsItem}
          item
          key={Math.random()}
        >
          <Link
            to={`/work-application/${formId}`}
            component={RouterLink}
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              style={{fontSize: isMobile ? '12px' : '14px' }}
            >
              View Form
            </Button>
          </Link>
        </Grid>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    defaultValue={searchStr}
                    InputProps={{
                      style: {fontSize: isMobile ? '12px' : '14px', height: '40px' },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                            style={{ height: isMobile ? '12px' : '14px', width: isMobile ? '12px' : '14px' }}
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    onChange={handleChange}
                    placeholder="Search applicant"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FilterMenu fieldval={field} />
                </Grid>
                <Grid item xs={3}>
                  <AddApplicantForm />
                </Grid>
                <Grid item>
                  <DateFilter />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  formId: PropTypes.string,
  field: PropTypes.string,
  searchStr: PropTypes.string
};

export default Toolbar;
