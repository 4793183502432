import axios from 'axios';
import { toast } from 'react-toastify';
import inLocalStorageJwt from './inLocalStorageJwt';

const createCoupon = async (couponBody) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/coupons/create`, couponBody, options);
    toast.success(`${response.data.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 200, coupon: response.data.coupon, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, coupon: '', message: error.response.data.message };
  }
};

const toggleActiveCoupon = async (couponId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/coupons/toggle-status/${couponId}`, {}, options);
    toast.success(`${response.data.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 200, coupon: response.data.coupon, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, coupon: '', message: error.response.data.message };
  }
};

const validateCoupon = async (code) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/coupons/validate`, { code }, options);
    toast.success(`${response.data.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 200, coupon: response.data.coupon, message: response.data.message };
  } catch (error) {
    // console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, coupon: '', message: error.response.data.message };
  }
};
export default {
  createCoupon,
  toggleActiveCoupon,
  validateCoupon
};
