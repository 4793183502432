/* eslint-disable */
import React, { useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import WorkApplicationContext from 'src/contexts/WorkApplicationContext';
import ApiCall from 'src/utils/ApiCall';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: '14px',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function CustomizedSelects(row) {
  const classes = useStyles();
  const [livestat, setLivestat] = React.useState(row.row.live_status);
  const workApplicationContext = useContext(WorkApplicationContext);
  const handleChange = async (event) => {
    setLivestat(event.target.value);
    const postData = { live_status: event.target.value };
    await ApiCall.updateWorkApplication(row.row.id, postData);
    workApplicationContext.refreshPage();
  };
  return (
    <div>
      <FormControl className={classes.margin} style={{ minWidth: '100%' }}>
        {/* <InputLabel htmlFor="demo-customized-select-native">Age</InputLabel> */}
        <NativeSelect
          id="demo-customized-select-native"
          defaultValue={livestat}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <option aria-label="None" value="" />
          <option value="new">New</option>
          <option value="interested">Interested</option>
          <option value="not_interested">Not Interested</option>
          <option value="irregular">Irregular</option>
          <option value="today_followup">Today Followup</option>
          <option value="invited_for_ppt">Invited for PPT</option>
          <option value="have_to_take_feedback">Have to take Feedback</option>
          <option value="phone_not_picked">Phone Not Picked</option>
        </NativeSelect>
      </FormControl>
    </div>
  );
}
