/* eslint-disable */
import React, { useState, useContext } from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  Grid,
  Typography,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ApiCall from 'src/utils/ApiCall';
import WorkApplicationContext from 'src/contexts/WorkApplicationContext';
// import moment from 'moment';
const handleDefault = (dateVal) => {
  const dateValue = new Date(dateVal);
  const year = dateValue.getFullYear();
  const month = `${dateValue.getMonth() + 1}`.padStart(2, '0');
  const day = `${dateValue.getDate()}`.padStart(2, '0');
  const hours = `${dateValue.getHours()}`.padStart(2, '0'); // Set default hours (e.g., 12 PM)
  const minutes = `${dateValue.getMinutes()}`.padStart(2, '0');
  const dateValueFormatted = `${year}-${month}-${day}T${hours}:${minutes}`;
  return dateValueFormatted;
};
const validationSchema = Yup.object().shape({
  name: Yup.string().max(50, 'Name must be at most 500 characters'),
  email: Yup.string().email('Invalid email'),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
    .required('Phone is required'),
  qualification: Yup.string().max(50, 'qualification must be at most 50 characters'),
  address: Yup.string().max(150, 'address must be at most 150 characters'),
  current_status: Yup.string().max(150, 'current status must be at most 150 characters'),
  conn_time: Yup.string()
    // .test({
    //   message: 'Please enter future time',
    //   test: (date) => {
    //     const g1 = new Date(date);
    //     const g2 = new Date();
    //     const isValid = true;
    //     if (g1.getTime() <= g2.getTime()) {
    //       return !isValid;
    //     }
    //     return isValid;
    //   }
    // })
});

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    color: 'red'
  },
  modalContainer: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%', // Set width to 100%
    [theme.breakpoints.up('sm')]: {
      width: 400, // Set a specific width for larger screens (e.g., tablets, desktops)
    },
  },
}));

const EditFormModal = (params) => {
  const {row, tableFont} = params;
  if (typeof row === 'undefined') {
    return true;
  }
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const workApplicationContext = useContext(WorkApplicationContext);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const initialValues = {
    id: row.id,
    name: row.name ? row.name : '',
    email: row.email ? row.email : '',
    phone: row.phone ? row.phone : '',
    qualification: row.qualification ? row.qualification : '',
    address: row.address ? row.address : '',
    current_status: row.current_status ? row.current_status : '',
    conn_time: row.conn_time ? handleDefault(row.conn_time) : '',
  };

  const handleSubmit = async (values) => {
    console.log('Submitted:', values);
    const resp = await ApiCall.editApplicantBasicDetails(values);
    if (resp.status === 200) {
      handleClose();
      workApplicationContext.refreshPage();
    }
    // Handle submission logic here (e.g., API call)
    // handleClose();
  };

  return (
    <>
      <Tooltip title="Edit">
        <IconButton aria-label="edit" fontSize="large" style={{ color: '#32a8a4' }} onClick={handleOpen}>
          <EditIcon style={{ fontSize: 15}} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        closeAfterTransition={false}
        backdrop="static"
        onClose={() => {}} // Empty function prevents the modal from closing
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   invisible: true // Hide the backdrop
        // }}
      >
        <DialogContent>
        <div className={classes.modalContainer2}>
          <Typography variant="h6">Edit Applicant</Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              handleBlur,
              handleChange,
              values
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="name"
                      label="Name"
                      error={errors.name && touched.name}
                      helperText={touched.name ? errors.name : ''}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="email"
                      label="Email"
                      error={errors.email && touched.email}
                      helperText={touched.email ? errors.email : ''}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="phone"
                      label="Phone"
                      error={errors.phone && touched.phone}
                      helperText={touched.phone ? errors.phone : ''}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="qualification"
                      label="qualification"
                      error={errors.qualification && touched.qualification}
                      helperText={touched.qualification ? errors.qualification : ''}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="address"
                      label="address"
                      error={errors.address && touched.address}
                      helperText={touched.address ? errors.address : ''}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="current_status"
                      label="current status"
                      error={errors.current_status && touched.current_status}
                      helperText={touched.current_status ? errors.current_status : ''}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.conn_time && errors.conn_time)}
                      fullWidth
                      helperText={touched.conn_time && errors.conn_time}
                      label="Right date & time to connect with you."
                      placeholder="You may receive the call at this time from our side."
                      name="conn_time"
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.conn_time}
                    />
                  </Grid>
                  {/* Add other fields similarly */}
                  <Grid item xs={4}>
                    <Button variant="outlined" type="submit" color="primary">Submit</Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button className={classes.closeBtn} variant="outlined" type="button" color="secondary" onClick={handleClose}>Close</Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditFormModal;
