import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    createdAt: '15/06/2021',
    description: 'For self motivation.',
    media: `${process.env.REACT_APP_AWS_SERVER_BUCKET_URL}uploads/books/1._100_ways_to_motivate_yourself.pdf`,
    title: '100 ways to motivate yourself.',
    totalDownloads: '594'
  },
  {
    id: uuid(),
    createdAt: '15/06/2021',
    description: 'Best book for better decisions of life.',
    media: `${process.env.REACT_APP_AWS_SERVER_BUCKET_URL}uploads/books/Boundaries_ When to Say Yes, How to Say No to Take Control of Your Life ( PDFDrive ) (1).pdf`,
    title: 'Boundaries When to Say Yes, How to Say No to Take Control of Your Life',
    totalDownloads: '594'
  },
  {
    id: uuid(),
    createdAt: '15/06/2021',
    description: 'Best book for life improvements and good habits.',
    media: `${process.env.REACT_APP_AWS_SERVER_BUCKET_URL}uploads/books/covey_stephen_-_the_seven_habits_of_highly_effective_people.pdf`,
    title: 'The seven habits of highly effective people.',
    totalDownloads: '594'
  },
  {
    id: uuid(),
    createdAt: '15/06/2021',
    description: 'Its time to update our thinking as Successful people.',
    media: `${process.env.REACT_APP_AWS_SERVER_BUCKET_URL}uploads/books/How Successful People Think_ Change Your Thinking, Change Your Life ( PDFDrive ).pdf`,
    title: 'How Successful People Think Change Your Thinking, Change Your Life.',
    totalDownloads: '594'
  },
  {
    id: uuid(),
    createdAt: '15/06/2021',
    description: 'Learn the ways to win the argument.',
    media: `${process.env.REACT_APP_AWS_SERVER_BUCKET_URL}uploads/books/How to Win Every Argument ( PDFDrive ) (1).pdf`,
    title: 'How to Win Every Argument.',
    totalDownloads: '594'
  },
  {
    id: uuid(),
    createdAt: '15/06/2021',
    description: 'Best book for people skill and communication skills.',
    media: `${process.env.REACT_APP_AWS_SERVER_BUCKET_URL}uploads/books/How To Win Friends and Influence People ( PDFDrive ).pdf`,
    title: 'How To Win Friends and Influence People.',
    totalDownloads: '594'
  },
  {
    id: uuid(),
    createdAt: '15/06/2021',
    description: 'I Am Malala: The Story of the Girl Who Stood Up for Education and was Shot by the Taliban is an autobiographical book by Malala Yousafzai.',
    media: `${process.env.REACT_APP_AWS_SERVER_BUCKET_URL}uploads/books/i-am-malala-pdf-book-by-malala-downloaded-4m-pakistanifun.commalala-yousafzai-christina-lamb.pdf`,
    title: 'I am Malala.',
    totalDownloads: '594'
  }
];
