/* eslint-disable */
import React, { useState, useEffect } from 'react';
// import clsx from 'clsx';
import {
  Box,
  Card,
  Grid,
  NativeSelect,
  FormControl,
  Container,
  makeStyles,
  withStyles,
  InputBase,
  Tooltip,
  Typography,
  Toolbar as ToolTag,
  Drawer as AppDrawer,
  Button
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {
  useSearchParams,
  useNavigate
} from 'react-router-dom';
import Page from 'src/components/Page';
// import DsrList from './DsrList';
import axios from 'axios';
import DataTable from 'react-data-table-component';
// import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';
import moment from 'moment';
import { toast } from 'react-toastify';
// import data from './data';
// import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
// import { NavLink as RouterLink } from 'react-router-dom';
// import { DesktopDatePicker } from '@material-ui/pickers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';
import Exportpdf from 'src/components/PdfTable/Exportpdf';
// import LiveStatus from './LiveStatus';
// import Note from './Note';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
// import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import EditApplicantForm from './EditApplicantForm';
import SendMessage from './SendMessage';
import {AppBar as DrawerBar} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import Fab from '@material-ui/core/Fab';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
// import PdfViewer from 'src/utils/PdfViewer';
import Drawer from './Drawer';
// import { CustomErrorComponent } from 'custom-error';
import Toolbar from './Toolbar';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import WorkApplicationContext from 'src/contexts/WorkApplicationContext';
// import UserCard from 'src/components/Card/UserCard'; // Path to your UserCard component
import {
  ZoomedDataTable,
  DataTableContainer,
  ZoomButton,
} from './DataTableZoomStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  closeButton: {
    float: 'right',
    position: 'absolute',
    right: '5%',
  },
  fullList: {
    width: 'auto',
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formExpndCard: {
    minWidth: 275,
    minWidth: 276,
    margin: theme.spacing(3)
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  respHeader: {
    margin:'10px',
    padding: '10px'
  },
  spacer: {
    marginTop: '10px'
  },
  mainContainer: {
    padding: theme.spacing(0.5)
  },
  statsItem: {
    margin: '5px'
  },
  fab: {
    margin: theme.spacing(1),
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const List = () => {
  const classes = useStyles();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isdrawerFullscreen, setisdrawerFullscreen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(searchParams.get('perPage')?searchParams.get('perPage'):10);
  const [livestatFilter, setLivestatFilter] = useState('');
  const [tableFont, SettableFont] = useState('14px');
  const [filterData, setfilterData] = useState({
    appNote:searchParams.get('appNote')?searchParams.get('appNote'):'',
    liveStatus: searchParams.get('liveStatus')?searchParams.get('liveStatus'):'',
    field: searchParams.get('field')?searchParams.get('field'):'name',
    queryStr:searchParams.get('queryStr')?searchParams.get('queryStr'):'',
    appliDate: searchParams.get('appliDate')?searchParams.get('appliDate'):'',
  });
  const [field, setField] = React.useState(filterData.field);
  const [queryStr, setQueryStr] = React.useState(filterData.queryStr);
  const [appliDate, setAppliDate] = React.useState(filterData.appliDate);
  const [rowData, setrowData] = useState();
  const [showDrawer, setshowDrawer] = useState(false);
  const [drawerWidth, setdrawerWidth] = useState(500);
  const [selectedRows, setSelectedRows] = React.useState([]);
	const [toggleCleared, setToggleCleared] = React.useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [waConnected, setWaConnected] = useState(false);

  // const handleZoomIn = () => {
  //   setZoomLevel(zoomLevel + 0.2);
  // };

  // const handleZoomOut = () => {
  //   setZoomLevel(zoomLevel - 0.2);
  // };
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: showDrawer,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [currentPage, setCurrentPage] = useState(searchParams.get('currentPage')?Number(searchParams.get('currentPage')):1);
  function getDatefromid(id){
    const timestamp = id.toString().substring(0,8);
    const date = new Date( parseInt( timestamp, 16 ) * 1000 );
    return date;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  async function deleteApplication(applicantId,page) {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
   await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/formdata/delete/${applicantId}`, options)
      .then((resp) => {
        // console.log(resp);
        toast.success(`${resp.data.message}`, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        // navigate('/app/customers');
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response.data.message}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
      });
    // console.log("here one");
    fetchApplicant(currentPage, perPage);
  }
  
  const handleLiveStatChange = async (event) => {
    setLivestatFilter(event.target.value);
    setfilterData({...filterData,liveStatus:event.target.value});
  };

  useEffect(() =>{
    // console.log(filterData);
    setSearchParams({perPage: perPage,currentPage: currentPage, ...filterData });
    // setSearchParams({...searchParams,...filterData});
    fetchApplicant(currentPage, perPage);
  },[filterData])
  function handleDelete(userId) {
    // console.log('id', dsrId);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { console.log('yes'); deleteApplication(userId); }
        },
        {
          label: 'No',
          onClick: () => { console.log('no'); }
        }
      ]
    });
  }
/**
 * Function for partner add
 */
const handleAddPartner = (row) => {
  // console.log('id', dsrId);
  confirmAlert({
    title: 'Applicant will be added as your partner.',
    message: 'Are you sure to do this?',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
          // console.log('yes');
          addUserApiCall(row); 
      }},
      {
        label: 'No',
        onClick: () => { console.log('no'); }
      }
    ]
  });
};

const addUserApiCall = (values) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
    const postData = {
      fname: values.name,
      lname: '---',
      email: values.email,
      phone: values.phone,
      role: 'learner',
      is_sponsored: true,
      bms_sponsor: getLoggedinUserData.email,
      referred_by: getLoggedinUserData.referral_code
    };
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
      }
    };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/create`, postData, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data);
        toast.success(`${res.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate('/app/customers', { replace: true });
      })
      .catch((error) => {
        // console.log(error);
        toast.error(`${error.response.data.message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
};
  const conditionalRowStyles = [
    {
      when: row => row.toggleSelected,
      style: {
        backgroundColor: "#e6e6e6",
        userSelect: "none"
      }
    }
  ];

  const handleDrawer = async (row) => {
    // const appdata = await ApiCall.getWorkApplication(row.id);
    // console.log(appdata);
    const updatedData = data.map(item => {
      if (row.id !== item.id) {
        if(item.toggleSelected){
          return {
            ...item,
            toggleSelected: !item.toggleSelected
          };
        }
        return item;
      }
      if(item.toggleSelected){
        return item;
      }
      return {
        ...item,
        toggleSelected: !item.toggleSelected
      };
    });
    setData(updatedData);
    setrowData(row);
    setshowDrawer(true);
    setState({ ...state, 'right': true });
    // toggleDrawer('right', true);
    // return <Drawer />;
  };
  const colMinwidth = isMobile? '50px' : '130px';
  const columns = [
    {
      name: <b style={{ fontSize: tableFont  }}>APPLICATION D&T</b>,
      selector: 'id',
      sortable: true,
      minWidth: colMinwidth,
      onClick: {handleDrawer},
      center: true,
      cell: (row) => (<div style={{fontSize: tableFont}}>{moment(getDatefromid(row.id)).format('MMM Do, YYYY hh:mm A')}</div>),
      conditionalCellStyles: [
        {
            when: row => getDatefromid(row.id) < new Date(),
            style: {
                backgroundColor: '#e6e6e6',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        }]
    },
    {
      name: <b style={{ fontSize: tableFont  }}>APPOINTMENT</b>,
      selector: 'conn_time',
      sortable: true,
      minWidth: colMinwidth,
      onClick: {handleDrawer},
      center: true,
      cell: (row) => (<div style={{fontSize: tableFont}}>{row.conn_time? moment(row.conn_time).format('MMM Do, YYYY hh:mm A'):'NA'}</div>),
      conditionalCellStyles: [
        {
            when: row => new Date(row.conn_time).getTime() > new Date().getTime(),
            style: {
                backgroundColor: '#c2f0c2',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
          when: row => (new Date().getTime() > new Date(row.conn_time).getTime()),
          style: {
              backgroundColor: '#ffb3b3',
              color: 'black',
              '&:hover': {
                  cursor: 'pointer',
              },
          },
      }]
    },
    {
      name: <b style={{ fontSize: tableFont, color:'green' }}>{field.toUpperCase()}</b>,
      selector: field,
      sortable: true,
      minWidth: colMinwidth,
      onClick: {handleDrawer},
      omit: ['email', 'qualification'].indexOf(field) < 0 ? true : false,
      // maxWidth: "90px",
    //   right: true,
      cell: (row) => (<div style={{fontSize:tableFont, color:'green'}}>{row[field]}</div>),
    },
    {
      name: <b style={{ fontSize: tableFont }}>Live Status</b>,
      selector: 'live_status',
      sortable: true,
      minWidth: colMinwidth,
      onClick: {handleDrawer},
      // maxWidth: "90px",
      // right: true,
      cell: (row) => (<div style={{fontSize:tableFont}}>
        {row.note && row.note.length > 0  ? row.note[0].log : row.live_status}
        </div>),
      conditionalCellStyles: [
        {
            when: row => row.live_status === 'new' &&  row.note.length === 0,
            style: {
                // backgroundColor: '#c2f0c2',
                color: 'green',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },]
    },
    {
      name: <b style={{ fontSize: tableFont }}>NAME</b>,
      selector: 'name',
      sortable: true,
      minWidth: colMinwidth,
      onClick: {handleDrawer},
      // maxWidth: "90px",
      // right: true,
      cell: (row) => (<div style={{fontSize:tableFont}}>{row.name ? row.name : 'NA' }</div>)
    },
    {
      name: <b style={{ fontSize: tableFont }}>PHONE</b>,
      selector: 'phone',
      onClick: {handleDrawer},
      sortable: true,
      // minWidth: colMinwidth,
      center: true,
        cell: (row) => (<div style={{fontSize: tableFont}}><a href={`tel:+91-${row.phone}`}>{row.phone}</a> </div>)
    },
    {
      name: <b style={{ fontSize: tableFont }}>ACTION</b>,
      center: false,
      flex:5,
      minWidth: colMinwidth,

      // cell: (row) => <Link color="primary"
      // component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>Edit</Link>
      cell: (row) => (
        <>
          {/* <Link color="primary" size="small" component={RouterLink} to={`/app/dailyReport/update?dsrid=${row.id}`}>
            <IconButton color="primary" size="small" variant="contained">
              <EditIcon />
            </IconButton>
          </Link> */}
          <div>
            {waConnected && <SendMessage row={row} formId={getLoggedinUserData.id} />}
            {row.id && <EditApplicantForm row={row} tableFont={tableFont} />}
            <Tooltip title="Delete">
              <IconButton aria-label="delete" style={{ fontSize: tableFont, color:"#ff0000" }} fontSize="small" onClick={() => { handleDelete(row.id); }}>
                <DeleteIcon style={{ fontSize: tableFont }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="View data">
              <IconButton aria-label="View Data" fontSize="small" style={{color:"#0066ff"}} onClick={() => { handleDrawer(row); }}>
                <VisibilityIcon style={{ fontSize: tableFont }}/> 
              </IconButton>
            </Tooltip>
            {row.email &&
            <Tooltip title="Add Partner">
              <IconButton aria-label="Add Partner" fontSize="small" style={{color:"#009933"}} onClick={() => { handleAddPartner(row); }}>
                <PersonAddIcon style={{ fontSize: tableFont }}/> 
              </IconButton>
            </Tooltip>
            }
          </div>
        </>
      )
    },
    // (!isMobile && !(
    // {
    //   name: <b style={{ fontSize: tableFont }}>Live Status</b>,
    //   selector: 'live_status',
    //   sortable: true,
    //   // minWidth: "200px",
    //   grow:3,
    //   center: false,
    //     cell: (row) => (<div style={{fontSize: tableFont}}><LiveStatus row={row} /></div>)
    // },
    // {
    //   name: <b style={{ fontSize: tableFont }}>Note</b>,
    //   selector: 'note',
    //   sortable: true,
    //   minWidth: "200px",
    //   grow:3,
    //   center: false,
    //     cell: (row) => (<Note row={row}/>)
    // },
    // ))
  ];
  const fetchApplicant = async (page, perPg) => {
    const offset = (page - 1) * perPg;
    setLoading(true);
    const postData = {
      createdBy: getLoggedinUserData.id,
      skip: offset,
      limit: perPg
    };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    var filters = `?skip=${offset}&limit=${perPg}&queryStr=${queryStr}&field=${field}&applidate=${appliDate}`;
    if(filterData.appNote){
      filters+= `&note=${filterData.appNote}`;
    }

    if(filterData.liveStatus){
      filters+= `&live_status=${filterData.liveStatus}`;
    }

    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/formdata/applicant-form-data/${getLoggedinUserData.id}/${filters}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data.result);
        setData(res.data.applicationData.formData);
        setTotalRows(res.data.applicationData.totalformData);
        setLoading(false);
        console.log("waConnected", waConnected);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    setSearchParams({perPage: perPage,currentPage: page, ...filterData });
    fetchApplicant(page, perPage);
  };
  const handleRowSelected = (selRows) => {
		setSelectedRows(selRows.selectedRows);
    console.log(selRows);
	};
  const contextActions = React.useMemo(() => {
		const handleDeleteAll = () => {
      console.log(selectedRows);
			
			if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
				setToggleCleared(!toggleCleared);
        console.log(selectedRows);
				setData(differenceBy(data, selectedRows, 'name'));
        // handleDelete(row.id);
			}
		};

		return (
			<Button key="delete" onClick={handleDeleteAll} style={{ backgroundColor: '#a83236' }}>
				Delete
			</Button>
		);
	}, [data, selectedRows, toggleCleared]);

  const toggleDrawerSize = (siz) => {
    setdrawerWidth(siz);
    setisdrawerFullscreen(!isdrawerFullscreen);
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    // console.log(newPerPage, page);
    setLoading(true);
    setPerPage(newPerPage);
    setCurrentPage(page);
    setSearchParams({perPage: newPerPage,currentPage: page, ...filterData });
    await fetchApplicant(page, newPerPage);
    // setData(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    // searchParams.get('perPage')?setPerPage(searchParams.get('perPage')):'';
    // searchParams.get('currentPage')?setCurrentPage(searchParams.get('currentPage')):'';
  
    // searchParams.get('appNote')?setfilterData({...filterData,appNote:searchParams.get('appNote')}):'';
    // searchParams.get('liveStatus')?setfilterData({...filterData,liveStatus:searchParams.get('liveStatus')}):'';
    SettableFont(isMobile ? '10px' : '14px');
    if(currentPage > 1){
      handlePageChange(currentPage);
    }
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    // fetchApplicant(currentPage, perPage);
    ApiCall.createActivity(getLoggedinUserData.id, 'page_navigation', 'Work-Application', 'Check in to Work Application List page.');
    
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if(rowData && data){
      const foundObject = data.find(obj => obj.id === rowData.id);
      // If foundObject is undefined, the object with the specified ObjectId was not found
      if (foundObject) {
        console.log('Found Object:', foundObject);
        setrowData(foundObject);
      } else {
        console.log('Object not found');
      }
    }
  }, [data]);
  const refreshPage = () => {
    fetchApplicant(currentPage, perPage);
  }
  const searchList = (qstr) => {
    setQueryStr(qstr);
    setfilterData({...filterData,queryStr:qstr});
  }

  const updateFieldSearch = (val) => {
    setField(val);
    setfilterData({...filterData,field:val});
  }
  const updateDateFilter = (val) => {
    setAppliDate(val);
    setfilterData({...filterData,appliDate:val});
  }

  const updateWaConnect = (val) => {
    setWaConnected(val);
  }
  const workapplicationContextVal = {
    refreshPage,
    searchList,
    updateFieldSearch,
    updateDateFilter,
    updateWaConnect
  };
  const list = (anchor) => (
    <div
      role="presentation"
      style={{width: isMobile? windowSize.innerWidth : drawerWidth}}
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <DrawerBar
        style={{width: isMobile? windowSize.innerWidth : drawerWidth}}
      >
        <ToolTag>
          <Typography variant="h5"> Application Details</Typography>
          <IconButton color="inherit" onClick={() => {toggleDrawerSize(700);}} >
            {!isMobile && 
              !isdrawerFullscreen && <FullscreenIcon />
            }
          </IconButton>

          <IconButton color="inherit" onClick={() => {toggleDrawerSize(500);}} >
            {!isMobile && 
              isdrawerFullscreen && <FullscreenExitIcon />
            }
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="close drawer"
            edge="end"
            className={classes.closeButton}
            onClick={()=> {
                // setState({right:false});
                setState({ ...state, [anchor]: false });
                // toggleDrawer(anchor, false)
            }}
            // className={clsx(open && classes.hide)}
          >
            <CloseIcon />
          </IconButton>
        </ToolTag>
      </DrawerBar>

      <main className={classes.content}>
        <div className={classes.toolbar} />
          <Drawer row={rowData} tableFont={tableFont}/>
      </main>
    </div>
  );
  // const ExpandedRow = (row) => {
  //   // console.log(row);
  //   const applicationOb = row.data;
  //   const bull = <span className={classes.bullet}>•</span>;
  //   const file = process.env.REACT_APP_AWS_SERVER_BUCKET_URL + applicationOb.resume_file;
  //   // const type = 'pdf';
   
  //   return (<div className={classes.root}>
  //          {applicationOb.app_mode==='resume' && 
  //          (<div style={{width: isMobile && windowSize.innerWidth}}>
  //             <Grid container spacing={3}>
  //               <Grid item xs={12} sm={12}>
  //                   <Box
  //                     width="100%"
  //                     height="600px"
  //                     display="block"
  //                     justifyContent="center"
  //                     mb={12}
  //                   >
  //                     <PdfViewer file={file}/>
  //                   </Box>
  //               </Grid>
  //             </Grid>
  //          </div>)}
  //          <Grid container spacing={3}>
  //           {/* <BrowserView>
  //             <h1>This is rendered only in browser</h1>
  //           </BrowserView>
  //           <MobileView>
  //             <h1>This is rendered only on mobile</h1>
  //           </MobileView> */}
  //            {/* <Grid item md={6} xs={12}>
  //              <Paper className={classes.paper}><b> Description</b> {applicationOb.description} </Paper>
  //            </Grid> */}
  //            {/* <Card className={classes.formExpndCard}>
  //               <CardContent>
  //                 <Typography className={classes.title} color="textSecondary" gutterBottom>
  //                 <b> Description</b> {applicationOb.description}
  //                 </Typography>
  //                 <Typography variant="h5" component="h2">
  //                   be{bull}nev{bull}o{bull}lent
  //                 </Typography>
  //                 <Typography className={classes.pos} color="textSecondary">
  //                   adjective
  //                 </Typography>
  //                 <Typography variant="body2" component="p">
  //                   well meaning and kindly.
  //                   <br />
  //                   {'"a benevolent smile"'}
  //                 </Typography>
  //               </CardContent>
  //               <CardActions>
  //                 <Button size="small">Learn More</Button>
  //               </CardActions>
  //             </Card> */}
  //             <Card className={classes.formExpndCard}>
  //               <Table
  //                 style={{width: isMobile && windowSize.innerWidth}}
  //               >
  //                 <Thead style={{ fontSize: isMobile && tableFont }}>
  //                   <Tr>
  //                     <Th className={classes.respHeader}>Email</Th>
  //                       <Th className={classes.respHeader}>Qualification</Th>
  //                       <Th className={classes.respHeader}>Country</Th>
  //                       <Th className={classes.respHeader}>State</Th>
  //                       <Th className={classes.respHeader}>Address</Th>
  //                       <Th className={classes.respHeader}>City</Th>
  //                       <Th className={classes.respHeader}>PinCode</Th>
  //                       <Th className={classes.respHeader}>DOB</Th>
  //                   </Tr>
  //                 </Thead>
  //                 <Tbody style={{ fontSize: isMobile && tableFont }}>
  //                   <Tr>
  //                     <Td className={classes.respHeader}>{applicationOb.email}</Td>
  //                     <Td className={classes.respHeader}>{applicationOb.qualification}</Td>
  //                     <Td className={classes.respHeader}>{applicationOb.country}</Td>
  //                     <Td className={classes.respHeader}>{applicationOb.state}</Td>
  //                     <Td className={classes.respHeader}>{applicationOb.address}</Td>
  //                     <Td className={classes.respHeader}>{applicationOb.city}</Td>
  //                     <Td className={classes.respHeader}>{applicationOb.zip}</Td>
  //                     {/* <Td className={classes.respHeader}>{applicationOb.zip}</Td> */}
  //                     <Td className={classes.respHeader}>{moment(applicationOb.dob).format('MMMM Do YYYY')}</Td>
  //                   </Tr>
  //                 </Tbody>
  //               </Table>
  //             </Card>
  //          </Grid>
  //      </div>);
  // };
  const tableData = {
    columns,
    data
  };
  const fontSize = isMobile ? '8px' : '14px';
  const customStyles = {
    table: {
      style: {
        // Add any custom styles for the table as needed
        height: '100%'
      },
    },
    rows: {
      style: {
        fontSize: fontSize,
      },
    },
    cells: {
      style: {
        fontSize: fontSize,
      },
    },
  };
  return (
    <WorkApplicationContext.Provider value={workapplicationContextVal}>
      <Page
        className={classes.root}
        title="Work Application"
      >
      <Container className={classes.mainContainer} maxWidth={false}>
        <Box mt={3}>
          <Toolbar formId={getLoggedinUserData.id} field={field} searchStr={queryStr} />
        </Box>
        {/* <Box mt={3}>
          <UserCard user={user} /> 
        </Box> */}
        <Card className={classes.spacer}>
          {/* <DsrList dsrList={dsrList} /> */}
          <Box 
            display="flex"
            justifyContent="flex-end"
            spacing={5}
            mt={3}
          >
              <Grid
                className={classes.statsItem}
                item
              >
                <FormControl className={classes.margin} style={{ minWidth: 250 }}>
                  {/* <InputLabel htmlFor="demo-customized-select-native">Age</InputLabel> */}
                  <NativeSelect
                    id="demo-customized-select-native"
                    defaultValue={livestatFilter}
                    onChange={handleLiveStatChange}
                    input={<BootstrapInput />}
                  >
                    <option value="" >All</option>
                    <option value="new">New</option>
                    <option value="interested">Interested</option>
                    <option value="not_interested">Not Interested</option>
                    <option value="irregular">Irregular</option>
                    <option value="today_followup">Today Followup</option>
                    <option value="invited_for_ppt">Invited for PPT</option>
                    <option value="have_to_take_feedback">Have to take Feedback</option>
                    <option value="phone_not_picked">Phone Not Picked</option>
                  </NativeSelect>
              </FormControl>
              </Grid>
              <Grid
                item
                className={classes.statsItem}
              >
                <Exportpdf data={data} />
              </Grid>
          </Box>
          {/* <DataTableExtensions {...tableData}> */}
          {/* <ZoomButton onClick={handleZoomIn}>Zoom In</ZoomButton>
          <ZoomButton onClick={handleZoomOut}>Zoom Out</ZoomButton> */}
          {/* <DataTableContainer>
            <ZoomedDataTable zoomLevel={zoomLevel}> */}
              <DataTable
                title="Applicant List"
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                filter
                paginationServer
                paginationPerPage={Number(perPage)}
                currentPage={currentPage}
                paginationTotalRows={totalRows}
                contextActions={contextActions}
                customStyles={customStyles}
                // selectableRows
                clearSelectedRows={toggleCleared}
                onSelectedRowsChange={handleRowSelected}
                onRowClicked={handleDrawer}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover
                conditionalRowStyles={conditionalRowStyles}
                // expandableRows
                expandableRowDisabled={row => row.disabled}
                // expandableRowsComponent={<ExpandedRow />}
              />
              {/* </DataTableExtensions> */}
            {/* </ZoomedDataTable>
          </DataTableContainer> */}
        </Card>
        <div>
          {['right'].map((anchor) => (
            <React.Fragment key={anchor}>
              {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
              <AppDrawer
                anchor={anchor}
                open={state[anchor]}
                // variant="persistent"
                onClose={toggleDrawer(anchor, false)}
                // onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </AppDrawer>
            </React.Fragment>
          ))}
        </div>
      </Container>
    </Page>
    </WorkApplicationContext.Provider>
  );
};

export default List;
