/* eslint-disable */
import React, { useContext } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { isMobile } from 'react-device-detect';
import WorkApplicationContext from 'src/contexts/WorkApplicationContext';

export default function ControlledOpenSelect(fieldval) {
  const [field, setField] = React.useState(fieldval.fieldval); // eslint-disable-line react/destructuring-assignment
  const [open, setOpen] = React.useState(false);
  const workApplicationContext = useContext(WorkApplicationContext);
  const handleChange = (event) => {
    setField(event.target.value);
    workApplicationContext.updateFieldSearch(event.target.value);
    // workApplicationContext.searchList(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Select
      labelId="demo-controlled-open-select-label"
      id="demo-controlled-open-select"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      value={field}
      onChange={handleChange}
      variant="outlined"
      style= {{fontSize: isMobile ? '12px' : '14px', height: '40px' }}
    >
      <MenuItem value="name">Name</MenuItem>
      <MenuItem value="email">Email</MenuItem>
      <MenuItem value="phone">Phone</MenuItem>
      <MenuItem value="qualification">Qualification</MenuItem>
    </Select>
  );
}
