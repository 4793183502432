/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import Button from "src/components/CustomButtons/Button.js";
import Card from "src/components/Card/Card.js";
import CardBody from "src/components/Card/CardBody.js";
import CardFooter from "src/components/Card/CardFooter.js";
import { Typography } from "@material-ui/core";

import styles from "src/assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import Certificate from "src/assets/img/Certificate.jpg";

const useStyles = makeStyles(styles);

export default function TeamSection(course) {
  const classes = useStyles();
  console.log(course);
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section} id="aboutus">
      <h2 className={classes.title}>About {course.name}</h2>
      <h4 className={classes.title}>
       {course.description}
      </h4>
      <div style={{height:'25px', display:'block'}}></div>
      <h2 className={classes.title} >Certifications</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <img width="100%" height="100%" src={Certificate} />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <h2 className={classes.title}>Industry Recognized Certification</h2>
            <Typography className={classes.description}>
              100,000+ companies ask for certificates before hiring every year. So a certificate from BMS Global is important for your growth.
            </Typography>

            <h2 className={classes.title}>Recommendation Letter</h2>
            <Typography className={classes.description}>
              Easy to start with giant Organizations with a valuable recommendation letter.
            </Typography>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
