/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextareaAutosize } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { useSocket } from 'src/contexts/SocketContext';
import CircularProgress from '@material-ui/core/CircularProgress';

const MessageDialog = (params) => {
  const { row, formId } = params;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [sendingMsg, setSendingMsg] = useState(false);

  const [phone, setPhone] = useState(row.phone);
  const [phoneRegistered, setPhoneRegistered] = useState(false);
  const [checkingReg, setcheckingReg] = useState(true);

  const socket = useSocket();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendMessage = () => {
    // Handle sending the message (e.g., API call, etc.)
    setSendingMsg(true);
    // console.log('Sending message:', message);
    socket.emit('messageSend', {message, phone, id: formId});
    // Close the dialog after sending the message
  };
  useEffect(() => {
    if(row.phone) {
      socket.emit('checkPhoneRegisteredWA', {phone, id: formId});
    }
    socket.on("PhoneRegisteredWAResp", (data) => {
        const { phone, isNumberAvailable, id } = data;
        if (id === formId && row.phone === phone) {
            setPhoneRegistered(isNumberAvailable);
        }
        if (id === formId) {
          setcheckingReg(false);
        }
    });

    socket.on("WAmessageSent", (data) => {
        const {sendMessageData, id} =  data;
        if (id === formId) {
            setMessage('');
            setOpen(false);
            setSendingMsg(false);
            console.log(data);
        }
    })
  }, []);
  return (
    <div>
      {checkingReg && (
        <CircularProgress size={24} thickness={4} />
      )}
      {phoneRegistered && (
        <IconButton onClick={handleClickOpen}>
          <SendIcon />
        </IconButton>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send Message</DialogTitle>
        <DialogContent>
          <TextareaAutosize
            minRows={7}
            placeholder="Type your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{ width: '100%', resize: 'none' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendMessage} disabled={sendingMsg} color="primary">
            {sendingMsg? 'Sending' : 'Send'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MessageDialog;
