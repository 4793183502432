/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import { Typography } from '@material-ui/core';
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import InfoArea from "src/components/InfoArea/InfoArea.js";
import Divider from '@material-ui/core/Divider';

import styles from "src/assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>BMS GLOBAL</h2>
          <h2 className={classes.title}>TERMS OF SERVICE</h2>
          <small>Last Modified: March 5, 2021</small>
          <Divider />
          <h5>
          Thanks for using our products and services. The Services are provided by BMS GLOBAL, located at Vishal complex 1st floor, Papri, Mohali (District), Punjab (State), India. </h5>
            <h5>
            By using our Services, you are agreeing to these terms. Please read them carefully.
            Our Services are very diverse, so sometimes additional terms or product requirements (including age requirements) may apply. Additional terms will be available with the relevant Services, and those additional terms become part of your agreement with us if you use those Services.
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Using our Services</h2>
          <h5>You must follow any policies made available to you within the Services.Don’t misuse our Services. For example, don’t interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.</h5>
          <h5>Using our Services does not give you ownership of any intellectual property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services.</h5>
          <h5>Our Services display some content that is not BMS GLOBAL’s. This content is the sole responsibility of the entity that makes it available. We may review content to determine whether it is illegal or violates our policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. But that does not necessarily mean that we review content, so please don’t assume that we do.</h5>
          <h5>In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.</h5>
          <h5>Some of our Services are available on mobile devices. Do not use such Services in a way that distracts you and prevents you from obeying traffic or safety laws.</h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Your BMS GLOBAL Account</h2>
          <h5>
          You may need a BMS GLOBAL Account in order to use our Services. You may create your own BMS GLOBAL Account, or your BMS GLOBAL Account may be assigned to you by an administrator, such as your employer or educational institution. If you are using a BMS GLOBAL Account assigned to you by an administrator, different or additional terms may apply and your administrator may be able to access or disable your account.
          </h5>
          <h5>
          You are responsible for the activity that happens on or through your BMS GLOBAL Account. If you learn of any unauthorized use of BMS GLOBAL Account, follow these instructions.
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Privacy and Copyright Protection</h2>
          <h5>BMS GLOBAL's privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that BMS GLOBAL can use such data in accordance with our privacy policies.</h5>
          <h5>We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to the process set out in the Indian Copyright Law. The Copyright Act 1957 (as amended by the Copyright Amendment Act 2012)</h5>
          <h5>We provide information to help copyright holders manage their intellectual property online. If you think somebody is violating your copyrights and want to notify us, you can report us via abuse report form which is available in the bottom of form page.</h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Your Content in our Services</h2>
          <h5>Some of our Services allow you to upload, submit, store, send or receive content. You retain ownership of any intellectual property rights that you hold in that content. In short, what belongs to you stays yours.</h5>
          <h5>When you upload, submit, store, send or receive content to or through our Services, you give BMS GLOBAL (and those we work with) a worldwide license to use, host, store, reproduce, modify, create derivative works (such as those resulting from translations, adaptations or other changes we make so that your content works better with our Services), communicate, publish, publicly perform, publicly display and distribute such content. The rights you grant in this license are for the limited purpose of operating, promoting, and improving our Services, and to develop new ones. This license continues even if you stop using our Services. Some Services may offer you ways to access and remove content that has been provided to that Service. Also, in some of our Services, there are terms or settings that narrow the scope of our use of the content submitted in those Services. Make sure you have the necessary rights to grant us this license for any content that you submit to our Services.</h5>
          <h5>Our automated systems analyze your content (including emails) to provide you personally relevant product features, such as customized search results, tailored advertising, and spam and malware detection. This analysis occurs as the content is sent, received, and when it is stored.</h5>
          <h5>
          If you have a BMS GLOBAL Account, we may display your Profile name, Profile photo, and actions you take on BMS GLOBAL or on third-party applications connected to your BMS GLOBAL Account in our Services, including displaying in ads and other commercial contexts. We will respect the choices you make to limit sharing or visibility settings in your BMS GLOBAL Account.
          </h5>
          <h5>
          You can find more information about how BMS GLOBAL uses and stores content in the privacy policy or additional terms for particular Services. If you submit feedback or suggestions about our Services, we may use your feedback or suggestions without obligation to you.
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>About Software in our Services</h2>
          <h5>When a Service requires or includes downloadable software, this software may update automatically on your device once a new version or feature is available. Some Services may let you adjust your automatic update settings.</h5>
          <h5>BMS GLOBAL gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you by BMS GLOBAL as part of the Services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by BMS GLOBAL, in the manner permitted by these terms. You may not copy, modify, distribute, sell, or lease any part of our Services or included software, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit those restrictions or you have our written permission.</h5>
          <h5>Open source software is important to us. Some software used in our Services may be offered under an open source license that we will make available to you. There may be provisions in the open source license that expressly override some of these terms.</h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Modifying and Terminating our Services</h2>
          <h5>We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether.</h5>
          <h5>You can stop using our Services at any time, although we’ll be sorry to see you go. BMS GLOBAL may also stop providing Services to you, or add or create new limits to our Services at any time.</h5>
          <h5>We believe that you own your data and preserving your access to such data is important. If we discontinue a Service, where reasonably possible, we will give you reasonable advance notice and a chance to get information out of that Service.</h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Our Warranties and Disclaimers</h2>
          <h5>We provide our Services using a commercially reasonable level of skill and care and we hope that you will enjoy using them. But there are certain things that we don’t promise about our Services.</h5>
          <h5>Other than as expressly set out in these terms or additional terms, neither BMS GLOBAL nor its suppliers or distributors make any specific promises about the Services. For example, we don’t make any commitments about the content within the Services, the specific functions of the Services, or their reliability, availability, or ability to meet your needs. We provide the Services “as is”.</h5>
          <h5>Some jurisdictions provide for certain warranties, like the implied warranty of merchantability, fitness for a particular purpose and non-infringement. To the extent permitted by law, we exclude all warranties.</h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Liability for our Services</h2>
          <h5>When permitted by law, BMS GLOBAL will not be responsible for lost profits, revenues, or data, financial losses or indirect, special, consequential, exemplary, or punitive damages.</h5>
          <h5>To the extent permitted by law, the total liability of BMS GLOBAL for any claims under these terms, including for any implied warranties, is limited to the amount you paid us to use the Services (or, if we choose, to supplying you the Services again).</h5>
          <h5>In all cases, BMS GLOBAL will not be liable for any loss or damage that is not reasonably foreseeable.</h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Business uses of our Services</h2>
          <h5>If you are using our Services on behalf of a business, that business accepts these terms. It will hold harmless and indemnify BMS GLOBAL and its employees from any claim, suit or action arising from or related to the use of the Services or violation of these terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.</h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Subscription Policy</h2>
          <h5>
            This Subscription Policy applies to all the paid products, services, websites and apps offered by bmsglobal.me and their affiliates (collectively "BMS GLOBAL"). We refer to those products, services, websites and apps collectively as the "services" in this policy.
          </h5>
          <Typography className={classes.policyContent} component="h3" variant="h4" align="center" color="textPrimary" gutterBottom>
            1. Subscription
          </Typography>
          <h5 justifyContent="left">
            If you are using our products and services and paying for it periodically then you are using our subscription plans.
          </h5>
          <Typography className={classes.policyContent} component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
        2.1 How can I avail the subscription?
        </Typography>
        <Typography className={classes.policyContent} variant="h5" align="center" color="textSecondary" component="p">
        1. You can choose the plan from "pricing" page according to your need of business. <br />
        2. complete your payment online and enjoy the subscription for particular date and time.<br />
        </Typography>

        <Typography className={classes.policyContent} component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
        2.2 Can I cancel the subscription?
        </Typography>
        <Typography className={classes.policyContent} variant="h5" align="center" color="textSecondary" component="p">
        Yes sure, you can cancel your subscription any time you want, and further, we will not charge without your permission.
        </Typography>
        <Typography className={classes.policyContent} component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
        2.3 Is there any refund on cancellation?
        </Typography>
        <Typography className={classes.policyContent} variant="h5" align="center" color="textSecondary" component="p">
         Here is the use case: <br />
         1. if you have mistakenly bought the subscription and never used the paid services yet, you will get the refund on your cancellation excluding the taxes and charges on your purchase.<br />
         2. If you have bought the subscription and start using it, there is no refund on cancellation.
        </Typography>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Copyrights</h2>
          <h5>You can't store any digital data including texts, graphics, software, images, audios, videos which is not yours and may be a copyright issue in future. If we find any types of copyright claims on your serving content, you are completely responsible for the leagl actions.</h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>About these Terms</h2>
          <h5>We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly. We’ll post notice of modifications to these terms on this page. We’ll post notice of modified additional terms in the applicable Service. Changes will not apply retroactively and will become effective no sooner than fourteen days after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you do not agree to the modified terms for a Service, you should discontinue your use of that Service.</h5>
          <h5>If there is a conflict between these terms and the additional terms, the additional terms will control for that conflict.</h5>
          <h5>These terms control the relationship between BMS GLOBAL and you. They do not create any third party beneficiary rights.</h5>
          <h5>If you do not comply with these terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future).</h5>
          <h5>If it turns out that a particular term is not enforceable, this will not affect any other terms.</h5>
          <h3 className={classes.title}>Thank You!</h3>
        </GridItem>
      </GridContainer>
    </div>
  );
}
