import 'date-fns';
import React, { useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  InputAdornment,
  Typography
} from '@material-ui/core';
import * as Yup from 'yup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  textarea: {
    resize: 'both'
  }
}));
const CreateForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const formikRef = useRef();

  // const phoneRegExp = /^[0-9]{2}[0-9]{8}$/;
  const phoneRegExp = /^[0-9]{10}$/;
  const handleTypeRadChange = (event) => {
    formikRef.current.setFieldValue('role', event.target.value);
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        fname: '',
        lname: '',
        email: '',
        phone: '',
        role: 'user'
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
        fname: Yup.string().max(255).required('First name is required'),
        lname: Yup.string().max(255).required('Last name is required'),
        role: Yup.string().required('Please select the role.')
      })}
      onSubmit={((values, { setSubmitting }) => {
        const AuthTokenObject = inLocalStorageJwt.getAuthToken();
        const AuthToken = AuthTokenObject.authToken;
        const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
        const postData = {
          fname: values.fname,
          lname: values.lname,
          email: values.email,
          phone: values.phone,
          role: values.role,
          is_sponsored: true,
          bms_sponsor: getLoggedinUserData.email,
          referred_by: getLoggedinUserData.referral_code
        };
        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${AuthToken}`,
          }
        };
        // const response = await axios.get(
        //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
        // );
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/create`, postData, options)
          .then(async (res) => {
            // setReportList(res.data.dsreports);
            // console.log(res.data);
            setSubmitting(false);
            toast.success(`${res.data.message}`, {
              position: 'top-right',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            navigate('/app/customers', { replace: true });
          })
          .catch((error) => {
            console.log(error);
            setSubmitting(false);
            toast.error(`${error.response.data.message}`, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader="Enter your user details."
              title="Add your user here."
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                  container
                  // justifyContent="space-around"
                >
                  <TextField
                    error={Boolean(touched.fname && errors.fname)}
                    fullWidth
                    helperText={touched.fname && errors.fname}
                    label="First Name"
                    name="fname"
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                    value={values.fname}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.lname && errors.lname)}
                    helperText={touched.lname && errors.lname}
                    fullWidth
                    label="Last Name"
                    name="lname"
                    onChange={handleChange}
                    required
                    value={values.lname}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    fullWidth
                    label="Email"
                    name="email"
                    multiline
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                    inputProps={{ className: classes.textarea }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    fullWidth
                    label="Phone"
                    name="phone"
                    required
                    onChange={handleChange}
                    value={values.phone}
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Typography>
                    Type of User
                  </Typography>
                  <FormControlLabel
                    checked={values.role === 'user'}
                    value="user"
                    name="user"
                    control={<Radio inputProps={{ 'aria-label': 'B' }} />}
                    label="Free user"
                    onChange={handleTypeRadChange}
                    labelPlacement="start"
                  />
                  {/* <FormControlLabel
                    checked={values.role === 'learner'}
                    value="learner"
                    name="learner"
                    control={<Radio inputProps={{ 'aria-label': 'B' }} />}
                    label="Learner"
                    onChange={handleTypeRadChange}
                    labelPlacement="start"
                  /> */}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Grid
                item
                style={{ margin: '3px' }}
              >
                <Button
                  // color="secondary"
                  // style={{ backgroundColor: '#21b6ae' }}
                  variant="contained"
                  onClick={() => { navigate('/app/customers'); }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                style={{ margin: '3px' }}
              >
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Save details
                </Button>
              </Grid>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

CreateForm.propTypes = {
  className: PropTypes.string
};

export default CreateForm;
