/* eslint-disable */
import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import BookContext from 'src/contexts/BookContext';
import Toolbar from './Toolbar';
import ProductCard from './ProductCard';
// import Scroll from './Scroll';
import data from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const ProductList = () => {
  const classes = useStyles();
  const [products, setProducts] = useState(data);

  const filterBooks = (searchStr) => {
    const itemYouWant = data.filter((book) => {
      if (book.title.toLowerCase().indexOf(searchStr) >= 0) {
          return book;
      }
    });
    setProducts(itemYouWant);
  };
  const bookContextVal = {
    filterBooks
  };
  return (
    <BookContext.Provider value={bookContextVal}>
      <Page
        className={classes.root}
        title="Products"
      >
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <Grid
              container
              spacing={1}
            >
              {products.map((product) => (
                <Grid
                  item
                  key={product.id}
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <ProductCard
                    className={classes.productCard}
                    product={product}
                  />
                  {/* <Scroll /> */}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Page>
    </BookContext.Provider>
  );
};

export default ProductList;
