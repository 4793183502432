import React from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useLocation } from 'react-router-dom';
import CreateForm from './CreateForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const CustomerCreateView = () => {
  const classes = useStyles();
  const query = useQuery();
  const taskId = query.get('taskid');
  return (
    <Page
      className={classes.root}
      title="Your Users"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          <CreateForm taskId={taskId} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerCreateView;
