import 'date-fns';
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import moment from 'moment';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';

// const states = [
//   {
//     value: 'alabama',
//     label: 'Alabama'
//   },
//   {
//     value: 'new-york',
//     label: 'New York'
//   },
//   {
//     value: 'san-francisco',
//     label: 'San Francisco'
//   }
// ];
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  textarea: {
    resize: 'both'
  }
}));
const CreateForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  // const [values, setValues] = useState({
  //   date: '',
  //   no_of_ppt: 0,
  //   ppt_details: '',
  //   no_of_tp: 0, // third parties
  //   tp_details: '',
  //   retailing: '',
  //   recruiment: '',
  //   notes: ''
  // });

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };
  // const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  return (
    <Formik
      initialValues={{
        date: moment(new Date()).format('YYYY-MM-DD'),
        no_of_ppt: 0,
        ppt_details: '',
        no_of_tp: 0, // third parties
        tp_details: '',
        retailing: '',
        recruitment: '',
        notes: ''
      }}
      validationSchema={Yup.object().shape({
        date: Yup.string().required('Date is required'),
        no_of_ppt: Yup.string().required('No. of PPT is required'),
        ppt_details: Yup.string().required('PPT detail is required'),
        no_of_tp: Yup.string().required('No. of third party is required'),
        tp_details: Yup.string().required('Third parties detail is required'),
        retailing: Yup.string().required('Retailing is required'),
        recruitment: Yup.string().required('Recruitment is required'),
        notes: Yup.string().required('Notes is required')
      })}
      onSubmit={((values, { setSubmitting }) => {
        const AuthTokenObject = inLocalStorageJwt.getAuthToken();
        const AuthToken = AuthTokenObject.authToken;
        // const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
        const postData = {
          date: moment(new Date(values.date)).format('YYYY-MM-DD'),
          no_of_ppt: values.no_of_ppt,
          ppt_details: values.ppt_details,
          no_of_tp: values.no_of_tp,
          tp_details: values.tp_details,
          retailing: values.retailing,
          recruitment: values.recruitment,
          notes: values.notes
        };
        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${AuthToken}`,
          }
        };
        // const response = await axios.get(
        //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
        // );
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/dsreports/create`, postData, options)
          .then(async (res) => {
            // setReportList(res.data.dsreports);
            // console.log(res.data);
            setSubmitting(false);
            toast.success(`${res.data.message}`, {
              position: 'top-right',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            navigate('/app/dailyReports', { replace: true });
          })
          .catch((error) => {
            console.log(error);
            setSubmitting(false);
            toast.error(`${error.response.data.message}`, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader="Enter your details."
              title="Your Daily Status Reports."
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    container
                    // justify="space-around"
                  >
                    {/* <TextField
                      error={Boolean(touched.date && errors.date)}
                      fullWidth
                      helperText={touched.date && errors.date}
                      label="Date"
                      name="date"
                      onChange={handleChange}
                      required
                      onBlur={handleBlur}
                      value={values.date}
                      variant="outlined"
                    /> */}
                    <KeyboardDatePicker
                      disableToolbar
                      fullWidth
                      error={Boolean(touched.date && errors.date)}
                      helperText={touched.date && errors.date}
                      label="Date"
                      name="date"
                      variant="inline"
                      disableFuture={Boolean(true)}
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      value={values.date}
                      onChange={(date) => setFieldValue('date', date)}
                      // onChange={handleDateChange}
                      onBlur={handleBlur}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.no_of_ppt && errors.no_of_ppt)}
                    helperText={touched.no_of_ppt && errors.no_of_ppt}
                    fullWidth
                    label="No. of PPT"
                    name="no_of_ppt"
                    onChange={handleChange}
                    required
                    type="number"
                    value={values.no_of_ppt}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.ppt_details && errors.ppt_details)}
                    helperText={touched.ppt_details && errors.ppt_details}
                    fullWidth
                    label="PPT Details"
                    name="ppt_details"
                    multiline
                    onChange={handleChange}
                    required
                    value={values.ppt_details}
                    variant="outlined"
                    inputProps={{ className: classes.textarea }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.no_of_tp && errors.no_of_tp)}
                    helperText={touched.no_of_tp && errors.no_of_tp}
                    fullWidth
                    label="No. Of Third Parties"
                    name="no_of_tp"
                    required
                    onChange={handleChange}
                    type="number"
                    value={values.no_of_tp}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.tp_details && errors.tp_details)}
                    helperText={touched.tp_details && errors.tp_details}
                    fullWidth
                    label="Third Parties Details"
                    name="tp_details"
                    onChange={handleChange}
                    value={values.tp_details}
                    variant="outlined"
                    multiline
                    inputProps={{ className: classes.textarea }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.retailing && errors.retailing)}
                    helperText={touched.retailing && errors.retailing}
                    fullWidth
                    label="Enter Retailing Details"
                    name="retailing"
                    onChange={handleChange}
                    required
                    value={values.retailing}
                    multiline
                    inputProps={{ className: classes.textarea }}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.recruitment && errors.recruitment)}
                    helperText={touched.recruitment && errors.recruitment}
                    fullWidth
                    label="Enter Recruitment Details"
                    name="recruitment"
                    onChange={handleChange}
                    required
                    value={values.recruitment}
                    variant="outlined"
                    multiline
                    inputProps={{ className: classes.textarea }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={touched.notes && errors.notes}
                    fullWidth
                    label="Notes"
                    name="notes"
                    onChange={handleChange}
                    required
                    value={values.notes}
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Select State"
                    name="state"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.state}
                    variant="outlined"
                  >
                    {states.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid> */}
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Grid
                item
                style={{ margin: '3px' }}
              >
                <Button
                  // color="secondary"
                  // style={{ backgroundColor: '#21b6ae' }}
                  variant="contained"
                  onClick={() => { navigate('/app/dailyReports'); }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                style={{ margin: '3px' }}
              >
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Save details
                </Button>
              </Grid>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

CreateForm.propTypes = {
  className: PropTypes.string
};

export default CreateForm;
