import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  makeStyles,
  CardContent,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
// import BreadCrumbs from 'src/components/BreadCrumbs';

import axios from 'axios';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';
// import data from './data';
import TrainingContext from 'src/contexts/TrainingContext';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Toolbar from './Toolbar';
import TrainingCard from './TrainingCard';
import CategoryCard from '../../categories/CategoryListView/CategoryCard';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  emptyMessage: {
    height: 100,
    width: '100%'
  },
  trainingCard: {
    height: '100%'
  },
  mainContainer: {
    padding: theme.spacing(0.5)
  }
}));

const TrainingList = () => {
  const classes = useStyles();
  // const [products] = useState(data);
  const [categories, setCategories] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [strainings, setStrainings] = useState([]);
  const [pageloading, setPageloading] = useState(true);
  const { catId } = useParams();
  // const [urlcatId] = useState(catId);
  // console.log(trainings);
  // console.log(process.env.REACT_APP_API_KEY);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [trainingName, SetTrainingName] = useState('');
  const [paidUsers, SetPaidUsers] = useState([]);
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  const filterTraining = async (filter) => {
    SetTrainingName(filter);
  };

  const fetchPaidDownlines = async () => {
    // setLoading(true);
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    const filters = `?bms_sponsor=${getLoggedinUserData.email}&skip=0&limit=${100}&role=paid_user&gen=1`;
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users${filters}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data.result);
        SetPaidUsers(res.data.result.users);
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCategories = async () => {
    const userData = await ApiCall.getCategories(getLoggedinUserData.id, 'training', trainingName, '', catId);
    setCategories(userData);
  };
  const fetchTrainings = async () => {
    const postData = {
      // name: trainingName,
      createdBy: getLoggedinUserData.id,
      skip: 0,
      limit: 100
    };
    if (trainingName) {
      postData.name = trainingName;
      postData.trainer = trainingName;
      postData.topic = trainingName;
    }
    if (catId) {
      postData.category = catId;
    }
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/trainings/list`, postData, options)
      .then((res) => {
        if (res.data.data) {
          setTrainings(res.data.data.trainings);
          setStrainings(res.data.data.sTrainings);
          setPageloading(false);
          // console.log(strainings);
        }
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCategories();
    fetchTrainings();
    fetchPaidDownlines();
    ApiCall.createActivity(getLoggedinUserData.id, 'page_navigation', 'Training', 'Check in to Training page.');
  }, [catId]);
  useEffect(() => {
    fetchTrainings();
  }, [trainingName]);

  const trainingContextVal = {
    fetchTrainings,
    getCategories,
    filterTraining
  };
  return (
    <TrainingContext.Provider value={trainingContextVal}>
      <Page
        className={classes.root}
        title="Trainings"
      >
        {/* <BreadCrumbs /> */}
        <Container className={classes.mainContainer} maxWidth={false}>
          { !pageloading && <Toolbar trainings={trainings} catid={catId} />}
          { pageloading ? (
            <Box mt={3}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  style={{ textAlign: 'center', paddingLeft: '43%' }}
                >
                  {/* <Loader type="Grid" color="#4051B5" height={80} width={80} /> */}
                  <Loader type="Bars" color="#4051B5" height={80} width={80} />
                  {/* <Loader type="Puff" color="#4051B5" height={80} width={80} /> */}
                  {/* <Loader type="Hearts" color="#E22622" height={80} width={80} /> */}
                  <b>Loading...</b>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              <Box mt={3}>
                <Grid
                  container
                  spacing={1}
                >
                  {trainings && trainings.length > 0 && trainings.map((training) => (
                    <Grid
                      item
                      key={training.id}
                      lg={4}
                      md={6}
                      xs={12}
                    >
                      <TrainingCard
                        className={classes.trainingCard}
                        training={training}
                        paidUsers={paidUsers}
                      />
                    </Grid>
                  ))}
                  {trainings.length === 0 && strainings.length === 0
                  && (
                    <Grid
                      item
                      lg={12}
                      md={6}
                      xs={12}
                    >
                      <Card
                        className={clsx(classes.emptyMessage)}
                      >
                        <CardContent>
                          <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                          >
                            <Typography
                              color="textPrimary"
                              gutterBottom
                              variant="h3"
                            >
                              You have no training yet. Create New one.
                            </Typography>
                            {/* <Typography
                              color="textSecondary"
                              variant="body1"
                            >
                              CITY
                            </Typography> */}
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box mt={3}>
                {
                  strainings && strainings.length > 0
                  && (
                    <Grid
                      container
                      spacing={1}
                    >
                      <Grid
                        item
                        lg={12}
                        md={6}
                        xs={12}
                      >
                        <Card
                          className={clsx(classes.emptyMessage)}
                        >
                          <CardContent>
                            <Box
                              alignItems="center"
                              display="flex"
                              flexDirection="column"
                            >
                              <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h3"
                              >
                                Trainings Added By Your Seniors
                              </Typography>
                              {/* <Typography
                                color="textSecondary"
                                variant="body1"
                              >
                                CITY
                              </Typography> */}
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      {strainings.map((training) => (
                        <Grid
                          item
                          key={training.data.id}
                          lg={4}
                          md={6}
                          xs={12}
                        >
                          <TrainingCard
                            className={classes.trainingCard}
                            training={training}
                            paidUsers={paidUsers}
                          />
                          {/* <TrainingCardV
                            training={training}
                          /> */}
                        </Grid>
                      ))}
                      {strainings.length === 0
                      && (
                        <Grid
                          item
                          lg={12}
                          md={6}
                          xs={12}
                        >
                          <Card
                            className={clsx(classes.emptyMessage)}
                          >
                            <CardContent>
                              <Box
                                alignItems="center"
                                display="flex"
                                flexDirection="column"
                              >
                                <Typography
                                  color="textPrimary"
                                  gutterBottom
                                  variant="h3"
                                >
                                  You have no shared training yet.
                                  You can ask to your seniors for the training.
                                </Typography>
                                {/* <Typography
                                  color="textSecondary"
                                  variant="body1"
                                >
                                  CITY
                                </Typography> */}
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                    </Grid>
                  )
                }
              </Box>
              <Box mt={3}>
                <Grid
                  container
                  spacing={3}
                >
                  {categories && categories.map((category) => (
                    <Grid
                      item
                      key={category.id}
                      lg={4}
                      md={6}
                      xs={12}
                    >
                      <CategoryCard
                        className={classes.productCard}
                        category={category}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Page>
    </TrainingContext.Provider>
  );
};

export default TrainingList;
