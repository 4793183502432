import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TrainingApiCall from 'src/utils/TrainingApiCall';
import Player from './Player';

const Index = () => {
  const { trainingId } = useParams();
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    // Fetch video details including training link as video url from backend
    const fetchVideoDetails = async () => {
      try {
        const resp = await TrainingApiCall.getTraining(trainingId);
        if (resp.status === 200 && resp.training) {
          const data = resp.training;
          setVideoUrl(data.video_link);
        }
      } catch (error) {
        console.error('Error fetching video details:', error);
      }
    };

    fetchVideoDetails();
  }, [trainingId]);

  return (
    <div>
      {videoUrl && <Player videoUrl={videoUrl} />}
    </div>
  );
};

export default Index;
