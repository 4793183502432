import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Box
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import InputIcon from '@material-ui/icons/Input';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';

const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: 64
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const loggedinuser = inLocalStorageJwt.getLoggedinUserData();
  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        {
          loggedinuser && loggedinuser.id
          && (
            <>
              <Box flexGrow={1} />
              <IconButton color="inherit" onClick={() => { inLocalStorageJwt.logout().bind(this); }}>
                <InputIcon />
              </IconButton>
            </>
          )
          }
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
