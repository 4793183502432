/* eslint-disable */
import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField
} from '@material-ui/core';
import { Formik, ErrorMessage } from 'formik';
import axios from 'axios';
import RichTextEditor from './RichTextEditor';
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const BlogForm = () => {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create or update blog using API
    try {
        const rawContent = JSON.stringify(content.getCurrentContent().getPlainText());

        console.log(title, rawContent);
      //await axios.post('your-api-endpoint', { title, content });
      // Handle success, maybe redirect or refresh the blog list
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Page
      className={classes.root}
      title="BlogForm"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
        <Card>
            <CardHeader
              subheader="Write your Blog here"
              title="Blog"
            />
            <Divider />
            <CardContent>
            <Grid
                container
                spacing={1}
            >
                <form onSubmit={handleSubmit}>
                <Grid item xs={8}>
                <label>Title:</label>
                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Training Name"
                    name="name"
                    onChange={handleChange}
                    required
                    onBlur={handleBlur}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={8}>
                {/* Use the RichTextEditor component here */}
                <label>Content:</label>
                <RichTextEditor content={content} setContent={setContent} />
                {/* Pass setContent as a prop to update the content state */}
                </Grid>
                <button type="submit">Submit</button>
                </form>
            </Grid>
            </CardContent>
        </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default BlogForm;

