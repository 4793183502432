/* eslint-disable */
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@material-ui/core';
import AddCandidate from './AddCandidate';
// Previous code for the form component (MyForm) goes here...

const AddCandidateForm = (params) => {
  const { meetingId, slot, title, dropDownlist, refreshList } = params;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    refreshList();
    setOpen(false);
  };

  return (
    <div>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button variant="contained" size="small" style={{ fontSize: '12px', whiteSpace: 'nowrap', display: 'inline-block' }} color="primary" onClick={handleOpen}>
            Add Candidate
          </Button>
        </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
        <DialogTitle id="form-dialog-title">Add Candidate for {title} {slot}</DialogTitle>
        <DialogContent>
          <AddCandidate onClose={handleClose} dropDownlist={dropDownlist} meetingId={meetingId} slot={slot} title={title} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddCandidateForm;
