/* eslint-disable */
import { useState } from "react";
import { TableFlowImporter } from "@tableflow/react";

function MyComponent() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button onClick={() => setIsOpen(true)}>Open TableFlow Importer</button>

      <TableFlowImporter
        hostUrl={"http://localhost:3001/"} // Make sure this matches the TABLEFLOW_WEB_IMPORTER_URL env when setting up your instance
        modalIsOpen={isOpen}
        modalOnCloseTriggered={() => setIsOpen(false)}
        darkMode={true}
        metadata={{"user_id": 1234}} // Pass through user or import info
        onComplete={(data) => console.log(data)}
        template={{
          columns: [
            {
              name: "First Name",
              key: "first_name",
              required: true,
              description: "The first name of the user",
              suggested_mappings: ["First", "Name"],
              data_type: "string",
              validations: [
                {
                  validate: "not_blank",
                  message: "Cell must contain a value",
                },
              ],
            },
            {
              name: "Age",
              data_type: "number",
            },
          ],
        }}
      />
    </>
  );
}

export default MyComponent;