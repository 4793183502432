/* eslint-disable */
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';

const RichTextEditor = ({ content, setContent }) => {
  return (
    <Editor
      editorState={content}
      onEditorStateChange={(editorState) => setContent(editorState)}
    />
  );
};

export default RichTextEditor;

RichTextEditor.propTypes = {
    content: PropTypes.any,
    setContent: PropTypes.func,
};