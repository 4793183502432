/* eslint-disable */

import React from 'react'
import { Link } from "react-router-dom";
import '../Navigation/Navigation.css';

const Navigation = () => {
    return (
        <header className="dropShadow">
            <div className="headerWrapper">
                <div className="headerContainer flex">
                    <div className="headerLogoLinkWrapper">
                        <div className="headerLogoLink">
                        <Link to='/app/dashboard'>
                            <div className="headerLogo flex flex-row">
                                <div className="logoImg">
                                    {/* <img src={cuckoologo} alt="Cuckoo Logo"/> */}
                                    
                                </div>
                                <div className="logoText">
                                RETURN TO OFFICE
                                </div>
                            </div>
                        </Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default Navigation