/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import Button from "src/components/CustomButtons/Button.js";
import Card from "src/components/Card/Card.js";
import CardBody from "src/components/Card/CardBody.js";
import CardFooter from "src/components/Card/CardFooter.js";

import styles from "src/assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import nitish from "src/assets/img/faces/nitish-2.jpeg";
import team2 from "src/assets/img/faces/sachin.jpeg";
import hiredGirl from "src/assets/img/jobhappy.jpeg";
import { Typography } from "@material-ui/core";


const useStyles = makeStyles(styles);

export default function TeamSection(course) {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section} id="aboutus">
      <h2 className={classes.title}>What placement assistance will you receive?</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <h3 className={classes.title}>Free Placement Prep Training</h3>
            <Typography className={classes.description}>
              Learn how to build your resume, make great applications, and crack your interviews.
            </Typography>
            <h3 className={classes.title}>Curated internships & jobs</h3>
            <Typography className={classes.description}>
              Get internships and fresher jobs as per your preference in your inbox.
            </Typography>

            <h3 className={classes.title}>Get highlighted on BMS Global</h3>
            <Typography className={classes.description}>
             Top performers will be highlighted in their internship & job applications on BMS Global.
            </Typography>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <img width="100%" height="100%" src={hiredGirl} />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
