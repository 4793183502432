/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
// import CustomControls from './CustomControls';
import { findDOMNode } from 'react-dom';
import Soundbar from 'src/components/Controls/Soundbar';
// import LiveViewers from 'src/components/Youtube/LiveViewers';

const Player = ({ videoUrl }) => {
//   const playerRef = useRef(null);
  let forwardseek = false;
  const playerRef = React.createRef();
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [seeking, setSeeking] = useState();
  const [seekCheck, setSeekCheck] = useState({
    pause: false,
    buffer: false,
    play: false
  });
  const [playingStatus, SetPlayingStatus] = useState({
    lastPlayed: 0,
    currentPlaying: 0
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [playingPath, SetPlayingPath] = useState([]);
  const [playerObj, setPlayerObj] = React.useState(null);
  const videoId = getYouTubeVideoId(videoUrl);
  const [reactControl, SetReactControl] = useState({
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: true,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false
  });
  const [screenWidth, SetScreenWidth] = React.useState(window.innerWidth);
  const handleCustomFullScreen = () => {
    if (!fullscreenMode){
      // console.log("hdghfhghfshfh");
        findDOMNode(playerRef.current).requestFullscreen().catch(
            (err) => 
            {toast.error("Could not activate full-screen mode :(")}
        );
    }
  }
  const onReady =  (player) => {
    // console.log("loading status", player.getDuration());
    setPlayerObj(player);
  }
  const handlePlayPause = () => {
    SetReactControl({...reactControl, playing: !reactControl.playing });
  };
  const handlePlay = () => {
    SetReactControl({...reactControl, playing: true });
  };

  const handlePause = () => {
    SetReactControl({...reactControl, playing: false });
  };
  const handleMute = () => {
    SetReactControl({...reactControl, muted: true });
  };

  const handleUnmute = () => {
    SetReactControl({...reactControl, muted: false });
  };
  const handleBuffer = () => {
    // console.log("buffer");
    SetPlayingPath(playingPath => [...playingPath, 'buffer']);
    // setSeekCheck({...seekCheck, buffer: true});
  }
  const seekToSeconds = (seconds) => {
    playerObj.seekTo(seconds, 'seconds');
  }
   // volume bar change
  const volumebar = (newValue) => {
    console.log(newValue);
    SetReactControl({...reactControl, volume:newValue/100});
  }
  const handleDuration = (duration) => {
    SetReactControl({...reactControl, duration: duration });
  }
  const handleMuteUnmute = () => {
    SetReactControl({...reactControl, muted: !reactControl.muted });
  };
  const handleProgress = (st) => {
    console.log('playerRef.current:', playerRef.current);
    console.log('playingPath:', playingPath);
    console.log('onProgress', playingStatus);
    // We only want to update time slider if we are not currently seeking
      reactControl.seeking = false;
      // reactControl.forward = false;
      // reactControl.backward = false;
    if (!reactControl.seeking) {
      // console.log(playingPath, playingPath.length);
      const playFlag = playerRef.current.props.playing;
      // console.log("playing:", forwardseek);
      if(playingPath.indexOf('pause') === 0 && playingPath.indexOf('buffer') === 1){
        // SetPlayingStatus({...playingStatus, currentPlaying: st.playedSeconds});
        setSeekCheck({play: false, buffer: false, pause: false});
        // console.log('seeking', true);
        forwardseek = true;
        setSeeking(true);
      } else if (playingPath.indexOf('pause') < 0 && playingPath.indexOf('play') < 0 && playFlag === false){
        // console.log('seeking', true);
        // SetPlayingStatus({...playingStatus, currentPlaying: st.playedSeconds});
        setSeekCheck({play: false, buffer: false, pause: false});
        setSeeking(true);
        forwardseek = false;
      } else if (playingPath.indexOf('pause') === 0 && playingPath.length === 2 ) {
        // SetPlayingStatus({...playingStatus, currentPlaying: st.playedSeconds});
        forwardseek = false;
        setSeekCheck({play: false, buffer: false, pause: true});
        setSeeking(false);
      } else if (playingPath.indexOf('play') > -1 && forwardseek === false) {
        // SetPlayingStatus({...playingStatus, lastPlayed: st.playedSeconds, currentPlaying: st.playedSeconds});
        setSeekCheck({play: true, buffer: false, pause: false});
        setSeeking(false);
      }
      if(playFlag === true){
        // console.log("play----");
        SetPlayingStatus({...playingStatus, lastPlayed: st.playedSeconds, currentPlaying: st.playedSeconds});
      } else if(playFlag === false) {
        // console.log("pause----");
        SetPlayingStatus({...playingStatus, currentPlaying: st.playedSeconds});
      }
      SetReactControl({...reactControl, ...st});
    }
    SetPlayingPath([]);
  }

  const handleEnded = () => {
    SetReactControl({...reactControl, playing: reactControl.loop });
  }
  function getYouTubeVideoId(url) {
    const regex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;
    const match = url.match(regex);
    
    if (match) {
      return match[1];
    } else {
      return null;
    }
  }
  const handleVolumeChange = (e) => {
    const volume = parseFloat(e.target.value);
    if (playerRef.current) {
      playerRef.current.setVolume(volume);
    }
  };
  useEffect(() => {
    SetScreenWidth(window.innerWidth);
  }, [window]);
  return (
    <>
        <div
          onClick={handlePlayPause}
        >
            <div
              style={{
                pointerEvents: 'none',
                height: screenWidth > 600 ? '700px' : '365px'
              }}
            >
              <ReactPlayer
                ref={playerRef}
                url={videoUrl}
                playing={reactControl.playing}
                muted={reactControl.muted}
                volume={reactControl.volume}
                onPause={handlePause}
                onBuffer={handleBuffer}
                onProgress={handleProgress}
                onDuration={handleDuration}
                onEnded={handleEnded}
                onReady={onReady}
                controls={false}
                width="100%"
                height={screenWidth > 600 ? '700px' : '365px'}
                onContextMenu={(e) => { e.preventDefault(); }}
                config={{
                youtube: {
                    playerVars: {
                    modestbranding: 1,
                    },
                },
                }}
              />
            </div>
        </div>
        {/* <CustomControls
            reactControl= {reactControl}
            handlePlayPause= {handlePlayPause}
            handleToggleMuted= {handleMuteUnmute}
            handleCustomFullScreen = {handleCustomFullScreen}
        /> */}
        <Soundbar
            volumebar={volumebar}
            handleMute={handleMuteUnmute}
            handlePlay={handlePlay}
            handlePause={handlePause}
            handleCustomFullScreen={handleCustomFullScreen}
            playingStatus={playingStatus}
            duration={reactControl.duration}
            handleBuffer={handleBuffer}
            seekToSeconds={seekToSeconds}
            reactControl={reactControl}
        />
        {/* <LiveViewers videoId={videoId} /> */}
  </>
  );
};

Player.propTypes = {
  videoUrl: PropTypes.string
};

export default Player;
