/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  makeStyles,
  Link,
  Button
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Page from 'src/components/Page';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import DsrList from './DsrList';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';
import moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
// import data from './data';
import { NavLink as RouterLink } from 'react-router-dom';
// import { DesktopDatePicker } from '@material-ui/pickers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  },
  formExpndCard: {
    minWidth: 275,
    minWidth: 276,
    margin: '15px'
  },
  respHeader: {
    margin:'10px',
    padding: '10px',
    textAlign: 'center'
  },
  statusTaskLbl: {
    padding: '3px',
    borderRadius: '5px',
    color: 'white'
  },
}));
function getDatefromid(id){
  const timestamp = id.toString().substring(0,8);
  const date = new Date( parseInt( timestamp, 16 ) * 1000 );
  return date;
}
const SubOrder = (params) => {
    const { userId } = params;
  const classes = useStyles();
  
  // const [dsrList] = useState(data);
  // const columns = [
  //   {
  //     name: 'First Name',
  //     selector: 'first_name',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Last Name',
  //     selector: 'last_name',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Email',
  //     selector: 'email',
  //     sortable: true,
  //   },
  // ];
  const columns = [
    {
      name: <b style={{ fontSize: '15px' }}>Plan</b>,
      selector: 'plan.name',
      sortable: true,
      center: true,
      cell: (row) => row.plan ? row.plan.name: 'NA'
    },
    {
      name: <b style={{ fontSize: '15px' }}>Date</b>,
      selector: 'orderDetails.txnDate',
      sortable: true,
      center: true,
      cell: (row) => row.id ? moment(getDatefromid(row.id)).format('MMM D, YYYY, hh:mm a') : 'NA'
    },
    {
      name: <b style={{ fontSize: '15px' }}>Item</b>,
      selector: 'itemId',
      sortable: true,
      center: true,
      cell: (row) => row.itemId ? row.itemId.name: 'NA'
    },
    {
      name: <b style={{ fontSize: '15px' }}>Type</b>,
      selector: 'itemType',
      sortable: true,
      center: true,
      cell: (row) => row.itemType ? row.itemType: 'NA'
    },
    {
      name: <b style={{ fontSize: '15px' }}>Payment Gateway</b>,
      selector: 'paymentGateway',
      sortable: true,
      center: true,
      cell: (row) => row.paymentGateway ? row.paymentGateway: 'NA'
    },
    // {
    //   name: 'PPT details',
    //   selector: 'ppt_details',
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'No of Third Parties',
    //   selector: 'no_of_tp',
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'Third parties details',
    //   selector: 'tp_details',
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'Retailing',
    //   selector: 'retailing',
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   name: 'Recruitment',
    //   selector: 'recruitment',
    //   sortable: true,
    //   center: true,
    // },
  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  // const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const fetchOrders = async (page) => {
    const response = await ApiCall.getAllOrders(userId,perPage, page);
    setData(response.orders);
    setTotalRows(response.totalOrders);
  };
  const handlePageChange = (page) => {
    fetchOrders(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    // setData(response.data.data);
    setLoading(false);
  };
  useEffect(() => {
    fetchOrders(1);
  }, [perPage]);
  useEffect(() => {
    // fetchOrders(1);
    // ApiCall.createActivity(getLoggedinUserData.id, 'page_navigation', 'User Reports', 'Check in to User Daily report List page.');
  }, []);
  const ExpandedRow = (row) => {
    // console.log(row);
    const ord = row.data;
    const bull = <span className={classes.bullet}>•</span>;
    return (<div className={classes.root}>
      <Grid container spacing={3}>
              <Card className={classes.formExpndCard}>
              {ord.paymentGateway === 'paytm' && ord.orderDetails &&
                (<Table>
                  <Thead>
                    <Tr>
                      <Th className={classes.respHeader}>Transaction Id</Th>
                      <Th className={classes.respHeader}>Order Id</Th>
                      <Th className={classes.respHeader}>Payment Mode</Th>
                      <Th className={classes.respHeader}>Amount</Th>
                      <Th className={classes.respHeader}>Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td className={classes.respHeader}>{ord.orderDetails ? ord.orderDetails.txnId : ''}</Td>
                      <Td className={classes.respHeader}>{ord.orderDetails ? ord.orderDetails.orderId : ''}</Td>
                      <Td className={classes.respHeader}>{ord.orderDetails ? ord.orderDetails.paymentMode : ''}</Td>
                      <Td className={classes.respHeader}>{ord.orderDetails ? ord.orderDetails.txnAmount : ''} ₹</Td>
                      <Td className={classes.respHeader}>
                        <Typography
                          className={classes.statusTaskLbl}
                          style={{
                          backgroundColor: (ord.orderDetails.resultInfo.resultStatus==='TXN_SUCCESS' ? '#44A048' : '#ff9900')
                          }}
                        >
                          {ord.orderDetails.resultInfo && ord.orderDetails.resultInfo.resultStatus === 'TXN_SUCCESS'? 'Success' : 'Failed'}
                        </Typography>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
                )}
                {ord.paymentGateway === 'razorpay' && ord.orderRpDetails &&
                ( <Table>
                  <Thead>
                    <Tr>
                      <Th className={classes.respHeader}>Status</Th>
                      <Th className={classes.respHeader}>Payment Id</Th>
                      <Th className={classes.respHeader}>Order Id</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td className={classes.respHeader}>Success</Td>
                      <Td className={classes.respHeader}>{ord.orderRpDetails.razorpay_payment_id ? ord.orderRpDetails.razorpay_payment_id : 'NA' }</Td>
                      <Td className={classes.respHeader}>{ord.orderRpDetails.razorpay_order_id ? ord.orderRpDetails.razorpay_order_id : 'NA'}</Td>
                    </Tr>
                  </Tbody>
                  </Table>
                )}
              </Card>
           </Grid>
       </div>);
  };
  const tableData = {
    columns,
    data
  };
  return (
      <Container maxWidth={false}>
        <Box mt={3}>
          {/* <DsrList dsrList={dsrList} /> */}
          <DataTableExtensions {...tableData}>
            <DataTable
              title="Order History"
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              filter
              paginationServer
              paginationTotalRows={totalRows}
              // selectableRows
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover
              expandableRows
              expandableRowDisabled={row => row.disabled}
              expandableRowsComponent={<ExpandedRow />}
            />
          </DataTableExtensions>
        </Box>
      </Container>
  );
};

export default SubOrder;
