/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
// @material-ui/icons
import {
  // Box,
  // Container,
  CardContent,
  Grid,
  // Link,
  TextField,
  // Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';// core components
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import ApiCall from 'src/utils/ApiCall';
// import CustomInput from "src/components/CustomInput/CustomInput.js";
import Button from "src/components/CustomButtons/Button.js";
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from "src/assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import { CheckoutProvider, Checkout } from 'paytm-blink-checkout-react';
import config from 'src/components/paytm/mocks/merchant-config.js';
const useStateRef = require('react-usestateref');
 
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  textarea: {
    resize: 'both'
  },
  errorMessage: {
    color: 'red'
  },
  radioLabel: {
    color: theme.palette.text.secondary
  },
  hideInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    opacity: '0'
  }
}));
const useStyles2 = makeStyles(styles);
// const phoneRegExp = /^[0-9]{2}[0-9]{8}$/;
const phoneRegExp = /^[0-9]{10}$/;

export default function WorkSection() {
  const classes = useStyles();
  const classes2 = useStyles2();
  const formikRef = useRef();
  const navigate = useNavigate();
  const query = useQuery();
  const callBack = query.get('callback');
  const txnAmount = query.get('txnamount');
  const txnId = query.get('txnid');
  const transactionStatus = query.get('status');
  const messageResp = query.get('message');
  const [openBlink, SetOpenBlink] = useStateRef(false);
  // const [callBack, SetCallBack] = useStateRef(query.get('callback'));
  const [isloading, Setisloading] = useStateRef(false);
  //function for app mode selection
  config.handler = {
    "notifyMerchant": function (eventName, data) {
      console.log("notifyMerchant handler function called");
      console.log("eventName => ", eventName);
      if(eventName === 'APP_CLOSED'){
        console.log('closed');
        Setisloading(false);
        ApiCall.deleteALlIncompleteOrder();
      }
      console.log("data => ", data);
    }
  };
  useEffect(() => {
    if (callBack === 'true') {
      if (transactionStatus === 'TXN_SUCCESS') {
        toast.success(`${messageResp}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (transactionStatus === 'TXN_FAILURE') {
        toast.error(`${messageResp}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, []); // Or [] if effect doesn't need props or state
  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center" spacing={5}>
        { (callBack === 'true') ?
        (
          <GridItem xs={12} sm={12} md={8}>
              {transactionStatus === 'TXN_SUCCESS' ?
               <>
                <h2 style={{textAlign:'center'}}>
                  <div style={{color:'green'}}>SUCCESSFUL ONLINE PAYMENT</div>
                  <div style={{color:'green'}}><CheckCircleTwoToneIcon /></div>
                </h2>
                <div style={{color:'black'}} className={classes2.description}>
                  <b>Transaction Id:</b><span style={{color:'green'}}>{txnId}</span><br />
                  <b>Amount:</b><span style={{color:'green'}}>{txnAmount} ₹</span><br />
                  {/* <b>User:</b> <span>{userData.email}</span> */}
                </div>
               </>
               :
               <>
                <h2 style={{textAlign:'center'}}>
                  <div style={{color:'red'}}>
                    PAYMENT FAILURE
                  </div>
                  <div className={classes2.textCenter} style={{color:'red'}}>
                    <CancelTwoToneIcon />
                  </div>
                </h2>
               </>
              }

            <h5 className={classes2.description}>
              {messageResp}
            </h5>
          </GridItem>
        )
        :
        (<GridItem cs={12} sm={12} md={8}>
          <h2 className={classes2.title}> Quick Payment gateway</h2>
            {/* <h4 className={classes2.description}>
              Divide details about your product or agency work into parts. Write a
              few lines about each one and contact us about any further
              collaboration. We will responde get back to you in a couple of
              hours.
            </h4> */}
            <Formik
              innerRef={formikRef}
              initialValues={{
                name: '',
                email: '',
                phone: '',
                amount: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                name: Yup.string().max(255).required('Name is required'),
                phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone is required'),
                amount: Yup.number().required('Kindly enter amount')
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const postData = new FormData();
                postData.append('email', values.email);
                postData.append('name', values.name);
                postData.append('phone', values.phone);
                postData.append('amount', values.amount);
                const options = {
                  headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data',
                  }
                };
  
                SetOpenBlink(false);
                Setisloading(true);
                // alert("We are working on it. please wait. we will update you.");
                // // console.log("api caled", isAuth);
  
                const checksum = await ApiCall.makeQuickPayment(postData);
                const tempData = {
                  orderId: checksum.body.orderId,
                  amount: checksum.body.txnAmount.value,
                  token: checksum.body.txnToken,
                  tokenType: 'TXN_TOKEN',
                  userDetail: checksum.body.userInfo
                };
                config.data = tempData;
                // console.log(checksum);
                SetOpenBlink(true);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Your Name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="name"
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      fullWidth
                      label="Phone"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      error={Boolean(touched.amount && errors.amount)}
                      helperText={touched.amount && errors.amount}
                      fullWidth
                      label="Amount"
                      name="amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amount}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.radioLabel}
                  >
                    Info
                    <HtmlTooltip
                      title={
                        (
                          <>
                            <em>
                              <b>Charges:</b>
                              5% on all transactions will be added for service and other charges.
                            </em>
                          </>
                      )
                    }
                    >
                      <InfoIcon />
                    </HtmlTooltip>
                  </Grid>
                  <GridItem xs={12}>
                    <Button disabled={isSubmitting} style={{width:'100%'}}  type="submit" color="primary">Pay</Button>
                  </GridItem>
                </Grid>
              </CardContent>
            </form>
            )}
            </Formik>
          </GridItem>
          )}
          {
            openBlink === true &&
            (<CheckoutProvider config={config} env={process.env.REACT_APP_PAYTM_ENV}>
              <Checkout />
            </CheckoutProvider>
          )}
      </GridContainer>
    </div>
  );
}
