/* eslint-disable */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  // Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const Groups = ({ className, groupData, ...rest }) => {
  const classes = useStyles();
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <>
            <Grid item key={uuid()}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h3"
              >
                {groupData.gname}
              </Typography>
              <Typography
                color="textPrimary"
                variant="h6"
              >
                {groupData.gdesc}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <PeopleIcon />
              </Avatar>
            </Grid>
          </>
        </Grid>
      </CardContent>
    </Card>
  );
};

Groups.propTypes = {
  className: PropTypes.string,
  groupData: PropTypes.object
};

export default Groups;
