/* eslint-disable */
import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
// import BookContext from 'src/contexts/BookContext';
// import Toolbar from './Toolbar';
// import ProductCard from './ProductCard';
import Banner from './Banner';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const ProductList = () => {
  const classes = useStyles();
  return (

      <Page
        className={classes.root}
        title="Products"
      >
        <Container maxWidth={false}>
          <Banner />
        </Container>
      </Page>
  );
};

export default ProductList;
