/* eslint-disable */
import React, { useContext } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CategoryContext from 'src/contexts/CategoryContext';
// import TrainingContext from 'src/contexts/TrainingContext';

export default function ControlledOpenSelect(fieldval) {
  const [field, setField] = React.useState(fieldval.fieldval);
  const [open, setOpen] = React.useState(false);
  const categoryContext = useContext(CategoryContext);
  // const trainingContext = useContext(TrainingContext);
  // console.log(fieldval);
  const handleChange = (event) => {
    setField(event.target.value);
    categoryContext.updateFieldSearch(event.target.value);
    // trainingContext.updateFieldSearch(event.target.value);
    // workApplicationContext.searchList(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Select
      labelId="demo-controlled-open-select-label"
      id="demo-controlled-open-select"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      defaultValue={field}
      onChange={handleChange}
      variant="outlined"
    >
      <MenuItem value="category">All Folders</MenuItem>
      <MenuItem value="training">All Trainings</MenuItem>
    </Select>
  );
}
