/* eslint-disable */
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ApiCall from 'src/utils/ApiCall';
import WorkApplicationContext from 'src/contexts/WorkApplicationContext';
import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: '100%', // Set width to 100% for full width
    resize: 'none', // Disable textarea resizing
    padding: theme.spacing(1), // Add padding if needed
    border: `1px solid ${theme.palette.divider}`, // Add border if needed
    borderRadius: theme.shape.borderRadius, // Add border radius if needed
    // Add more styles as needed
  },
  margin: {
    margin: theme.spacing(1),
  },
}));
const SaveButton = ({ onClick }) => {
  return (
    <Button variant="contained" size="small" onClick={onClick}>
      Save
    </Button>
  );
};

export default function CustomizedSelects(row) {
  const classes = useStyles();
  const [note, setNote] = React.useState('');
  const workApplicationContext = useContext(WorkApplicationContext);

  const handleChange = async (event) => {
    setNote(event.target.value);
    // const postData = { note: event.target.value };
    // await ApiCall.updateWorkApplication(row.row.id, postData);
  };

  const handleSave = async () => {
    // Add functionality for saving
    console.log('Save button clicked');
    if(note !== ''){
      const postData = { note: note };
      await ApiCall.updateWorkApplication(row.id, postData);
      setNote('');
      workApplicationContext.refreshPage();
    }
  };
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <FormControl className={classes.margin} style={{minWidth: '100%'}}>
            <TextareaAutosize
              minRows={7}
              onChange={handleChange}
              id="standard-basic"
              label="Write Note"
              value={note}
              className={classes.textarea}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.margin}>
            <SaveButton onClick={handleSave} />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}
