/*eslint-disable*/
import React from "react";
// import DeleteIcon from "@material-ui/icons/Delete";
// import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Social from "./Social";


// @material-ui/icons
// import { Apps, CloudDownload } from "@material-ui/icons";

// core components
// import CustomDropdown from "src/components/CustomDropdown/CustomDropdown.js";
import Button from "src/components/CustomButtons/Button.js";
import styles from "src/assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {/* <CustomDropdown
          noLiPadding
          buttonText="Components"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              All components
            </Link>,
            <a
              href="https://creativetimofficial.github.io/material-kit-react/#/documentation?ref=mkr-navbar"
              target="_blank"
              className={classes.dropdownLink}
            >
              Documentation
            </a>
          ]}
        /> */}
      </ListItem>
      <ListItem className={classes.listItem}>
        {/* <Button
          href="https://www.creative-tim.com/product/material-kit-react?ref=mkr-navbar"
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <CloudDownload className={classes.icons} /> Download
        </Button> */}
        <Link to="/login" className={classes.navLink} color="transparent"> <b>Log In</b> </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}
        <Link to="/register" className={classes.navLink} color="transparent"> <b>Sign Up</b> </Link>

        {/* <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/CreativeTim?ref=creativetim"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip> */}
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/pricing" className={classes.navLink} color="transparent"> <b>Pricing</b> </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/solutions" className={classes.navLink} color="transparent"> <b>Solutions</b> </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/faq" className={classes.navLink} color="transparent"> <b>FAQ</b> </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/testimonies" className={classes.navLink} color="transparent"> <b>Testimonies</b> </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/industrial-trainings" className={classes.navLink} color="transparent"> <b>Ind-Training</b> </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
          <Social />
      </ListItem>
    </List>
  );
}
