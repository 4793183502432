/* eslint-disable */
// SocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize the Socket.IO client
    const socket = io(process.env.REACT_APP_SERVER_BASE_URL, {
      transports: ['websocket', 'polling'], // Add the transport methods you want to use
    }); // Replace with your Socket.IO server URL

    setSocket(socket);

    return () => {
      socket.disconnect(); // Disconnect the socket when the component unmounts
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
