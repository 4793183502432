/* eslint-disable */
import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import VerifyPhoneView from  'src/views/auth/VerifyPhoneView';

export default function FormDialog(params) {
  const [open, setOpen] = React.useState(false);
  const [isOtpSent, SetisOtpSent] = React.useState(false);
  // const [user, SetUser] = React.useState({name:'',phone:'',token:''});
  const phoneRegExp = /^[0-9]{10}$/;
  const formikRef = useRef();
  const [user, SetUser] = React.useState({name:'',phone:'',token:''});
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        style={{'height': '50px', 'width':'100%', 'backgroundColor': '#3399ff'}}
        onClick={handleClickOpen}
      >
        Login with mobile (Fastest)
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {!isOtpSent ?
          (<Formik
            initialValues={{
              name: '',
              phone: ''
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(100).required('Name is required'),
              phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              const postData = {
                fname: values.name,
                phone: String(values.phone),
              };
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                }
              };
              axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/register-with-only-phone`, postData, options)
                .then((res) => {
                  const respData = res.data;
                  // console.log(respData);
                  // console.log(res.data.data.tokens);
                  if (respData.status === true) {
                    // alert(respData.tokens.access);
                    SetUser(respData.user);
                    SetisOtpSent(true);
                    toast.success(`${respData.message}`, {
                      position: 'top-right',
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                })
                .catch((error) => {
                  // what now?
                  // console.log(error.response.data.message);
                  setSubmitting(false);
                  // Error
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error(`${error.response.data.message}`, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest
                    // in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error);
                  }
                });
              // navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
               <>
                <DialogTitle id="form-dialog-title">Authenticate</DialogTitle>
                  <form onSubmit={handleSubmit}>
                  <DialogContent>
                    <DialogContentText>
                      To watch the content you have to verify Its you.
                    </DialogContentText>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Name"
                          type="text"
                          fullWidth
                        />
                        <TextField
                          error={Boolean(touched.phone && errors.phone)}
                          helperText={touched.phone && errors.phone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.phone}
                          margin="dense"
                          id="phone"
                          label="Mobile Number"
                          type="number"
                          fullWidth
                        />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                      Cancel
                  </Button>
                  <Button disabled={isSubmitting} type="submit" color="primary">
                      Next
                  </Button>
                </DialogActions>
                </form>
              </>
             )}
          </Formik>):
        (
          <VerifyPhoneView user={user} redirectToPublic={params.redirectToPublic} />
        )}
      </Dialog>
    </div>
  );
}
