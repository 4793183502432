/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import moment from 'moment';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';

import { useParams, useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import moment from 'moment';
import ViewersTable from 'src/components/DataTable/ViewersTable';
import DailyReport from 'src/components/DataTable/DailyReport';
import CustomerList from 'src/components/DataTable/CustomersList';
// import Calling from 'src/components/Calling/Calling';
import SubscriptionOrder from 'src/components/DataTable/SubscriptionOrder';
import Tree from 'react-d3-tree';
import { browserHistory } from 'react-router';

// import placeholder from '../src/static/images/avatars/avatar_6.png';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Grid,
  IconButton
} from '@material-ui/core';
import UserContext from 'src/contexts/UserContext';

const placeholder = '/static/images/avatars/user-logo-avtar.png';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  hideInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    opacity: '0'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  tableHd: {
      marginLeft: '10px'
  }
}));
const containerStyles = {
    width: "100vw",
    height: "100vh"
  };
const Profile = ({ className, trainingData, ...rest }) => {
  const classes = useStyles();
//   const userContext = useContext(UserContext);
  const location = useLocation();

  let { uid } = useParams();
  const [userId, SetuserId] = useState(uid);
//   let pathname = window.location.pathname;
  const [pathname, SetPathname] = useState(window.location.pathname);
  const loggidinUser = inLocalStorageJwt.getLoggedinUserData();
//   const [isDpSet, SetIsDpSet] = useState(!!loggidinUser.profile_pic);
  const [expandedActi, setExpandedActi] = React.useState(true);
  const handleExpandClickActi = () => {
    setExpandedActi(!expandedActi);
  };
  const [teamChart, SetTeamChart] = useState({});
  const nodeSize = { x: 200, y: 200 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: 20 };

  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardActions
        style={{backgroundColor: expandedActi? '#f2f2f2':'white'}}
        disableSpacing
        onClick={handleExpandClickActi}
      >
        <Typography
            color="textPrimary"
            gutterBottom
            variant="h4"
            className={classes.tableHd}
        >
            {/* {ismyProfilePage === true ? 'Your ':(customerData && customerData.fname)+'\'s '} */}
            Viewers
        </Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expandedActi,
          })}
          onClick={handleExpandClickActi}
          aria-expanded={expandedActi}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expandedActi} timeout="auto" unmountOnExit>
        <CardContent>
          <ViewersTable trainingId={trainingData.id} />
        </CardContent>
      </Collapse>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  trainingData: PropTypes.object
};

export default Profile;
