/*eslint-disable*/
import React, { useEffect, useState } from 'react';
// import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import InfoIcon from '@material-ui/icons/Info';
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import ApiCall from 'src/utils/ApiCall';
import Divider from '@material-ui/core/Divider';
import { toast } from 'react-toastify';
import axios from 'axios';
import PgList from './PgList'; // Assuming PaymentDialog component is in a file named PgList.js
import { useNavigate, useLocation } from 'react-router-dom';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import { CheckoutProvider, Checkout } from 'paytm-blink-checkout-react';
import config from 'src/components/paytm/mocks/merchant-config.js';
// import PaymentContext from 'src/contexts/PaymentContext';
// import PaytmBlink from 'src/components/paytm/app';

const useStateRef = require('react-usestateref');
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" component={RouterLink} to="/">
//         BMS GLOBAL
//       </Link>
//       {' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

// const tiers = [
//   {
//     title: 'Free',
//     price: '0',
//     description: ['10 users included', '2 GB of storage', 'Help center access', 'Email support'],
//     buttonText: 'Sign up for free',
//     buttonVariant: 'outlined',
//   },
//   {
//     title: 'Pro',
//     subheader: 'Most popular',
//     price: '15',
//     description: [
//       '20 users included',
//       '10 GB of storage',
//       'Help center access',
//       'Priority email support',
//     ],
//     buttonText: 'Get started',
//     buttonVariant: 'contained',
//   },
//   {
//     title: 'Enterprise',
//     price: '30',
//     description: [
//       '50 users included',
//       '30 GB of storage',
//       'Help center access',
//       'Phone & email support',
//     ],
//     buttonText: 'Contact us',
//     buttonVariant: 'outlined',
//   },
// ];
// const footers = [
//   {
//     title: 'Company',
//     description: ['Team', 'History', 'Contact us', 'Locations'],
//   },
//   {
//     title: 'Features',
//     description:['Cool stuff','Random feature','Team feature', 'Developer stuff', 'Another one'],
//   },
//   {
//     title: 'Resources',
//     description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
//   },
//   {
//     title: 'Legal',
//     description: ['Privacy policy', 'Terms of use'],
//   },
// ];

export default function Pricing() {
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();
  const callBack = query.get('callback');
  const transactionStatus = query.get('status');
  const messageResp = query.get('message');
  const [pricingData, SetPricingData] = useStateRef([]);
  const [openBlink, SetOpenBlink] = useStateRef(false);
  const [isloading, Setisloading] = useStateRef(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPlan, setselectedPlan] = useState();
  const [amount, setAmount] = useState(0); 
  const perdayCost = (days, price, customers) => {
    const perdayCost = price/(days * customers);
    return perdayCost.toFixed(2);
  };
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    }
  }))(Tooltip);
  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}
  config.handler = {
    "notifyMerchant": function (eventName, data) {
      console.log("notifyMerchant handler function called");
      console.log("eventName => ", eventName);
      if(eventName === 'APP_CLOSED'){
        console.log('closed');
        Setisloading(false);
        ApiCall.deleteALlIncompleteOrder();
      }
      console.log("data => ", data);
    }
  };
  const options1 = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const handleOpenDialog = (plan) => {
    setAmount(plan.price);
    setOpenDialog(true);
    setselectedPlan(plan.id);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    async function getPricing() {
      // SetPricingData({ id: trainingId });
      // console.log(tiers);
      const query = "?type=pro";
      await axios.get(`${process.env.REACT_APP_API_BASE_URL}/plans/get-all${query}`, options1)
        .then(async (res) => {
          // console.log(res.data.result);
          SetPricingData(res.data.result);
          // console.log(pricingData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getPricing();

    if (callBack === 'true') {
      if (transactionStatus === 'TXN_SUCCESS') {
        toast.success(`${messageResp}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (transactionStatus === 'TXN_FAILURE') {
        toast.error(`${messageResp}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, []); // Or [] if effect doesn't need props or state

  return (
    <>
      <CssBaseline />
      {/* <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            Company name
          </Typography>
          <nav>
            <Link variant="button" color="textPrimary" href="#" className={classes.link}>
              Features
            </Link>
            <Link variant="button" color="textPrimary" href="#" className={classes.link}>
              Enterprise
            </Link>
            <Link variant="button" color="textPrimary" href="#" className={classes.link}>
              Support
            </Link>
          </nav>
          <Button href="#" color="primary" variant="outlined" className={classes.link}>
            Login
          </Button>
        </Toolbar>
      </AppBar> */}
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Pricing For Virtual Office/Personal Assistant
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          For India
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {pricingData.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.name} xs={12} sm={tier.name === 'Enterprise' ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={tier.name}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography key={Math.random()} component="h2" variant="h3" color="textPrimary" style={{ textDecoration: "line-through", color:'red'}}>
                      ₹
                      {tier.oldprice}&nbsp;
                    </Typography>
                    {
                      tier.is_offer ?
                      (
                        tier.offer.map((ofr) => (
                          <Typography key={Math.random()} component="h2" variant="h6" color="textPrimary" style={{color: 'green'}}>
                          Discount {ofr.discPer}%&nbsp;
                        </Typography>
                        ))
                      ):(
                        <Typography key={Math.random()} component="h2" variant="h3" color="textPrimary" style={{color: 'green'}}>
                          ₹
                          {tier.price}
                        </Typography>
                      )
                    }
                    
                    <Typography variant="h6" color="textSecondary">
                      {` for ${tier.days} days`}
                    </Typography>
                  </div>
                  <ul>
                    <Typography component="li" variant="subtitle1" align="center" key={makeid(5)}>
                      {`${tier.features[0].phoneNoti} Phone Notification`}
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center" key={makeid(5)}>
                      {`${tier.features[0].emailNoti} Email Notification`}
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center" key={makeid(5)}>
                      {`${tier.features[0].task} Tasks`}
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center" key={makeid(5)}>
                      {`${tier.features[0].customers} Active Customers`}
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center" key={makeid(5)}>
                      <b>{`${tier.features[0].premiere} Premiere Participants`}</b>
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center" key={makeid(5)}>
                      {`${capitalize(tier.features[0].appliForm)} Application Forms`}
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center" key={makeid(5)}>
                      {`${capitalize(tier.features[0].trainings)} Trainings Library`}
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center" key={makeid(5)}>
                      {`${capitalize(tier.features[0].dailyReports)} Daily Reports`}
                    </Typography>
                    <Typography component="li" variant="subtitle1" align="center" key={makeid(5)}>
                      5% tax and other charges applied on all purchase. 
                    </Typography>
                  </ul>
                  <Divider />
                  <Grid container>
                    <Grid item>
                    <Typography variant="subtitle1" align="left" key={makeid(5)} style={{color: 'green', fontSize: '14px'}}>
                      Per person Cost Perday:
                      <HtmlTooltip
                      title={
                        (
                          <>
                            <em>
                              <b>Per Day Cost:</b>
                              {tier.price} ₹ /{tier.days} days X ({tier.features[0].customers} + 1 (You))
                            </em>
                          </>
                      )
                    }
                    >
                      <InfoIcon />
                      </HtmlTooltip> &nbsp; {perdayCost(tier.days, tier.price, tier.features[0].customers)} ₹
                    </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  {
                    tier.name !== 'PRE-BMS-PRO-MAX' && tier.is_active == true
                    && (
                      <>
                        <Button
                          fullWidth
                          variant="contained"
                          disabled={isloading}
                          color="primary"
                          onClick={() => handleOpenDialog(tier)}
                        >
                          {isloading? 'Loading': 'Avail'}
                        </Button>
                      </>
                      // <Button
                      //   fullWidth
                      //   variant="contained"
                      //   disabled={isloading}
                      //   onClick={async () => {
                      //     SetOpenBlink(false);
                      //     Setisloading(true);
                      //     // alert("We are working on it. please wait. we will update you.");
                      //     const isAuth = await inLocalStorageJwt.checkAuth();
                      //     // // console.log("api caled", isAuth);
                      //     if (!isAuth) {
                      //       // alert("login before buying.");
                      //       toast.info('Please login before placing your order.', {
                      //         position: 'top-right',
                      //         autoClose: 5000,
                      //         hideProgressBar: false,
                      //         closeOnClick: true,
                      //         pauseOnHover: true,
                      //         draggable: true,
                      //         progress: undefined,
                      //       });
                      //       navigate('/login?redirect=pricing');
                      //       return;
                      //     }
                      //     const checksum = await ApiCall.makePurchase(tier.id);
                      //     const tempData = {
                      //       orderId: checksum.body.orderId,
                      //       amount: checksum.body.txnAmount.value,
                      //       token: checksum.body.txnToken,
                      //       tokenType: 'TXN_TOKEN',
                      //       userDetail: checksum.body.userInfo
                      //     };
                      //     config.data = tempData;
                      //     // console.log(checksum);
                      //     SetOpenBlink(true);
                      //   }}
                      //   color="primary"
                      // >
                      //   {isloading? 'Loading': 'Avail'}
                      // </Button>
                    )
                  }
                  {
                    tier.name === 'PRE-BMS-PRO-MAX'
                    && (
                      <Button fullWidth color="primary">
                        ONLY FOR PRE BOOKING USERS
                      </Button>
                    )
                  }
                  {
                    tier.is_active === false
                    && (
                      <Button fullWidth color="primary">
                        {tier.status}
                      </Button>
                    )
                  }
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        {
          openBlink === true &&
          (<CheckoutProvider config={config} env={process.env.REACT_APP_PAYTM_ENV}>
            <Checkout />
          </CheckoutProvider>
          )}
        <PgList
          open={openDialog}
          handleClose={handleCloseDialog}
          amount={parseInt(amount)}
          planId={selectedPlan}
        />
      </Container>
      {/* Footer */}
      {/* <Container maxWidth="md" component="footer" className={classes.footer}> */}
        {/* <Grid container spacing={4} justify="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid> */}
        {/* <Box mt={5}>
          {/* <Copyright /> */}
          {/* <Typography className={classes.policyContent} component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
            Currently working On Payment gateways, Please keep watching.
          </Typography>
        </Box> */}
      {/* </Container> */}
      {/* End footer */}
    </>
  );
}
