import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
} from '@material-ui/core';
import LiveViewersTable from 'src/components/DataTable/LiveViewersTable';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Sales = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="Live Trainings"
      />
      <Divider />
      <CardContent>
        <Box
          // height={400}
          position="relative"
        >
          <LiveViewersTable />
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string
};

export default Sales;
