/* eslint-disable */
import React, { useRef, useState, useEffect, useContext } from 'react';
import {
    Button,
    Grid,
    makeStyles,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Box,
    IconButton
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import EditIcon from '@material-ui/icons/Edit';
const useStateRef = require('react-usestateref');
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      }
    },
    textarea: {
      resize: 'both'
    },
    radioLabel: {
      margin: theme.spacing(1)
    },
    errorMessage: {
      color: 'red'
    },
    hideInput: {
      cursor: 'pointer',
      position: 'absolute',
      top: '0',
      bottom: '0',
      right: '0',
      left: '0',
      width: '100%',
      opacity: '0'
    },
    previewThumbnail: {
      height: '200px',
      width: '200px'
    }
  }));
export default function FormDialog({ className,fetchGrpMembers, groupId, ...rest }) {
  const [open, setOpen] = React.useState(false);
  const formikRef = useRef();
  const classes = useStyles();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const navigate = useNavigate();
  const [groupData, SetGroupData] = useStateRef({});
  const [usersOpt, SetUsersOpt] = useStateRef([]);
  const [userIds, SetUserIds] = useStateRef([]);

  const [thumbnailSrc, setThumbnailSrc] = useState();
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const fetchGroupMembers =  async (page, perPage) => {
    const offset = (page - 1) * perPage;
    setLoading(true);
    const postData = {
      createdBy: getLoggedinUserData.id,
      skip: offset,
      limit: perPage
    };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    var filters = `?groupid=${groupId}&skip=${offset}&limit=${perPage}`;
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/group-members${filters}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        setGroupMemberData(res.data.data.groupMembers);
        setTotalRows(res.data.data.totalGroupMembers);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    };
  const handleUpdate = async (grp) => {
    setOpen(true);
    SetGroupData({ id: grp });
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/groups/view/${grp}`, options)
          .then(async (res) => {
            // console.log(res.data.task);
            SetGroupData(res.data.group);
            // console.log(trainingData);
          })
          .catch((error) => {
            console.log(error);
          });
  };
  useEffect(() => {
    if (groupData.id && formikRef.current) {
      formikRef.current.setFieldValue('gname', groupData.gname ? groupData.gname : '', false);
      formikRef.current.setFieldValue('gdesc', groupData.gdesc ? groupData.gdesc : '', false);
    }
  },[groupData]);
  const handleClose = () => {
    setOpen(false);
  };
  const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

  return (
    <div>
      {
        groupId && 
        (
          <Button size="small" style={{ fontSize: isMobile? '12px' : '14px' }} variant="contained" color="primary" onClick={handleClickOpen}>
            Add Member
          </Button>
        )
      }
      
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
            <Formik
              innerRef={formikRef}
              initialValues={{
                userid: '',
                roleingrp: '',
                groupid: groupId
            }}
              validationSchema={Yup.object().shape({
                userid: Yup.string().max(255).required('User is required'),
                roleingrp: Yup.string().max(255).required('role is required'),
                groupid: Yup.mixed(),
            })}
              onSubmit={((values, { setSubmitting }) => {
                const postData = new FormData();
                postData.append('userid', values.userid); 
                postData.append('roleingrp', values.roleingrp);
                // postData.append('groupid', values.groupid);
                // if (values.glogo) postData.append('glogo', values.glogo);

                // const response = await axios.get(
                //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
                // );
                if (groupid) {
                  axios.patch(`${process.env.REACT_APP_API_BASE_URL}/groups/update/${groupId}`, postData, options)
                    .then(async (res) => {
                    // setReportList(res.data.dsreports);
                    // console.log(res.data);
                    setSubmitting(false);
                    toast.success(`${res.data.message}`, {
                        position: 'top-right',
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // navigate('/app/groups', { replace: true });
                    setOpen(false);
                    fetchGrpMembers(1, 10);
                    })
                    .catch((error) => {
                    console.log(error);
                    setSubmitting(false);
                    toast.error(`${error.response.data.message}`, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    });
                } else {
                 axios.post(`${process.env.REACT_APP_API_BASE_URL}/groups/members/create`, postData, options)
                    .then(async (res) => {
                    // setReportList(res.data.dsreports);
                    // console.log(res.data);
                    setSubmitting(false);
                    toast.success(`${res.data.message}`, {
                        position: 'top-right',
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setOpen(false);
                    fetchGrpMembers(1, 10);
                    })
                    .catch((error) => {
                    console.log(error);
                    setSubmitting(false);
                    toast.error(`${error.response.data.message}`, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    });
                }
            })}
            >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                setFieldValue,
                values
            }) => (
                <form
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  noValidate
                  className={clsx(classes.root, className)}
                  {...rest}
                >
                <Card>
                  <CardHeader
                    subheader="Enter name of the person."
                    title="New Member Form"
                  />
                    <Divider />
                    <CardContent>
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                            item
                            md={12}
                            xs={12}
                            container
                            // justify="space-around"
                        >
                          <Autocomplete
                            multiple
                            id="user-drop-down"
                            name="userIds"
                            fullWidth
                            // onChange={handleChange}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                values.userIds = newValue.value;
                                SetUserIds(newValue);
                              } else {
                                values.type = '';
                              }
                              // console.log(values.type);
                            }}
                            onBlur={handleBlur}
                            autoHighlight
                            value={userIds}
                            options={usersOpt}
                            getOptionLabel={(option) => option.name || ''}
                            getOptionSelected={(option) => option.name || ''}
                            renderInput={(params) => (
                              <TextField
                                onChange={(e) => {
                                  console.log(e.target.value);
                                }}
                                error={Boolean(touched.userIds && errors.userIds)}
                                helperText={touched.userIds && errors.userIds}
                                {...params}
                                label="Search User name"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                    </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                    display="flex"
                    justifyContent="flex-end"
                    p={2}
                    >
                    <Grid
                        item
                        style={{ margin: '3px' }}
                    >
                      <Button
                        // color="secondary"
                        // style={{ backgroundColor: '#21b6ae' }}
                        variant="contained"
                        onClick={() => { navigate('/app/groups'); setOpen(false);  }}
                        >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                        item
                        style={{ margin: '3px' }}
                    >
                        <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        >
                        Save details
                        </Button>
                    </Grid>
                    </Box>
                </Card>
                </form>
            )}
            </Formik>
          </DialogContent>
      </Dialog>
    </div>
  );
}
