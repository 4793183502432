// GroupPage.js
import React, { useEffect, useState } from 'react';
import { Button, Typography, Container } from '@material-ui/core';
import { useParams } from 'react-router-dom'; // Import useParams hook
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import GroupMemberApiCall from 'src/utils/GroupMemberApiCall';

const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();

const GroupPage = () => {
  const { groupId } = useParams(); // Get the groupId from URL params
  const [groupData, setGroupData] = useState();
  const userId = getLoggedinUserData.id;
  const groupDetails = async () => {
    // get the group details
    console.log('fetching group details', groupId);
    const resp = await GroupMemberApiCall.groupDetails(groupId);
    if (resp.status === 200) {
      setGroupData(resp.group);
    }
  };
  const handleJoin = async () => {
    // Add logic to handle joining the group
    if (userId) {
      console.log('Join button clicked', groupId, userId);
      try {
        const resp = await GroupMemberApiCall.addJoinMemberRequest(groupId);
        console.log('group member created', resp);
        if (resp.status === 200 && resp.groupmember) {
          console.log(resp.groupmember);
        }
      } catch (error) {
        console.error('Error creating coupon:', error);
        // Handle error
      } finally {
        // setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    // run on page load
    groupDetails();
  }, [groupId]);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" style={{ marginTop: '20px', paddingTop: '10px' }} gutterBottom>
        {groupData && groupData.gname}
      </Typography>
      <Typography variant="h6" align="center" style={{ margin: '10px' }} gutterBottom>
        {groupData && groupData.gdesc}
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleJoin}>
          Join Group
        </Button>
      </div>
    </Container>
  );
};

export default GroupPage;
