import React, { useContext } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  // Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
// import Tooltip from '@material-ui/core/Tooltip';
// import {
//   // Send as SendOtpIcon,
// } from 'react-feather';
import Page from 'src/components/Page';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import AppContext from 'src/components/AppContext';
import UserContext from 'src/contexts/UserContext';
import TrainingContext from 'src/contexts/TrainingContext';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }
const VerifyemailPhoneView = (params) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user, redirectToPublic } = params;
  const query = useQuery();
  const phoneNum = query.get('phoneNum') ? query.get('phoneNum') : user.phone;
  const uName = query.get('name') ? query.get('name') : user.fname;
  const userContext = useContext(UserContext);
  const loginContext = useContext(AppContext);
  // const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  // const AuthToken = AuthTokenObject.authToken;
  const [secondsPhone, setSecondsPhone] = React.useState(120);
  const [resendPhoneCount, SetResendPhoneCount] = React.useState(0);
  const trainingContext = useContext(TrainingContext);
  const options = {
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${AuthToken}`,
    }
  };
  React.useEffect(() => {
    if (secondsPhone > 0) {
      setTimeout(() => setSecondsPhone(secondsPhone - 1), 1000);
    } else {
      setSecondsPhone('Resend Phone OTP');
    }
  });
  const reSendPhoneOtp = () => {
    SetResendPhoneCount(resendPhoneCount + 1);
    setSecondsPhone(120);
    // console.log('send phone otp', resendPhoneCount);
    const otpData = {
      phone: phoneNum
    };
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/resend-phone-otp-after-auth`, otpData, options)
      .then((res) => {
        // console.log(res);
        toast.success(`${res.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // React.useEffect(() => {
  //   console.log(resendEmailCount);
  //   if(resendEmailCount > 3) {
  //   }
  // }, [resendEmailCount]);
  return (
    <Page
      className={classes.root}
      title="Verify Phone"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              phone_otp: '',
            }}
            validationSchema={
              Yup.object().shape({
                phone_otp: Yup.number().typeError('OTP must be number.')
                  .required('OTP is required'),
              })
            }
            onSubmit={(values, { setSubmitting }) => {
              const postData = {
                phone_otp: values.phone_otp,
                phone: phoneNum
              };
              axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/verify-phone`, postData, options)
                .then((res) => {
                  const respData = res.data.data;
                  // console.log(respData, redirectToPublic);
                  // console.log(res.data.data.tokens);
                  if (respData.tokens) {
                    // localStorage.setItem('bms_user', JSON.stringify(res.data));
                    const accessToken = respData.tokens.access.token;
                    const accessTokenExpiry = respData.tokens.access.expires;
                    inLocalStorageJwt.setAuthToken(accessToken, accessTokenExpiry);
                    const refreshToken = respData.tokens.refresh.token;
                    const refreshTokenExpiry = respData.tokens.refresh.expires;
                    inLocalStorageJwt.setRefreshToken(refreshToken, refreshTokenExpiry);
                    userContext.userData = respData.user;
                    inLocalStorageJwt.setLoggedinUserData(respData.user);
                    loginContext.updateAuth(true);
                    toast.success(`${res.data.message}`, {
                      position: 'top-right',
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    if (redirectToPublic) {
                      trainingContext.setShowAuth(false);
                      navigate(redirectToPublic, { replace: true });
                      return;
                    }
                    if (res.data.status === true) {
                      navigate('/app/dashboard', { replace: true });
                    }
                  }
                })
                .catch((error) => {
                  // what now?
                  // console.log(error.response.data.message);
                  setSubmitting(false);
                  // Error
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error(`${error.response.data.message}`, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest
                    // in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error);
                  }
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    {`Hi ${uName}, Please Verify your phone number`}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Please check your messages to get the otp
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.phone_otp && errors.phone_otp)}
                  fullWidth
                  helperText={touched.phone_otp && errors.phone_otp}
                  label={`Enter OTP for  ${phoneNum}`}
                  margin="normal"
                  name="phone_otp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone_otp}
                  variant="outlined"
                />
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {/* Not received the otp?
                  {' '} */}
                  {
                    resendPhoneCount < 3
                    && (
                      <Link
                        to="/"
                        component={RouterLink}
                        // className="disabledCursor"
                        cursor="pointer"
                        onClick={(event) => {
                          event.preventDefault();
                          if (secondsPhone === 'Resend Phone OTP') {
                            reSendPhoneOtp();
                          }
                        }}
                      >
                        {secondsPhone}
                        {secondsPhone > 0 ? ' seconds' : ''}
                      </Link>
                    )
                  }
                  {
                    resendPhoneCount === 3
                    && (
                      <small>Maximum Limit Reached, Please try after some time.</small>
                    )
                  }
                </Typography>
                {/* <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box> */}
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Verify Now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default VerifyemailPhoneView;
