import 'date-fns';
import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStateRef = require('react-usestateref');

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  textarea: {
    resize: 'both'
  },
  radioLabel: {
    margin: theme.spacing(1)
  }
}));
const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const CreateForm = ({ className, taskId, ...rest }) => {
  const classes = useStyles();
  const formikRef = useRef();
  const navigate = useNavigate();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const [taskarray, SetTaskarray] = useState([]);
  const [usersArray, SetusersArray] = useState([]);
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [taskData, SetTaskData] = useStateRef({});
  const [options2, SetOptions2] = useStateRef([]);
  const [task, SetTask] = useStateRef({});
  const [userval, SetUserval] = useStateRef({});

  // const [isNotify, setIsNotify] = React.useState('no');
  // const [selectedDate, handleDateChange] = useState(new Date());
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const trainingTypeArr = [
    { title: 'Training' },
  ];
  async function handletypeNameChange(typeName) {
    formikRef.current.setFieldValue('typeName', typeName);
    SetTaskarray([]);
    if (typeName === 'Training') {
      const postData = {
        createdBy: getLoggedinUserData.id,
        skip: 0,
        limit: 100
      };
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/trainings/list`, postData, options)
        .then((res) => {
          // SetTaskarray(res.data.data.trainings);
          // const sTrainings = res.data.data.sTrainings;
          const tempArr = [];
          res.data.data.sTrainings.forEach((item) => {
            if (item.share === true) {
              tempArr.push(item.data);
            }
          });
          const combinedB = res.data.data.trainings.concat(tempArr);
          SetTaskarray(combinedB);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const fetchUsers = async (page) => {
    const perPage = 100;
    const offset = (page - 1) * perPage;
    // setLoading(true);
    // const postData = {
    //   createdBy: getLoggedinUserData.id,
    //   skip: offset,
    //   limit: perPage
    // };
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    const filters = `?bms_sponsor=${getLoggedinUserData.email}&skip=${offset}&limit=${perPage}&is_followup=${false}&gen=1`;
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users${filters}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        SetusersArray(res.data.result.users);
        // console.log(usersArray);
        // setTotalRows(res.data.result.totalUsers);
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    async function getTask() {
      if (taskId) {
        SetTaskData({ id: taskId });
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/tasks/view/${taskId}`, options)
          .then(async (res) => {
            // console.log(res.data.task);
            SetTaskData(res.data.task);
            // console.log(trainingData);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    getTask();
    fetchUsers(1);
  }, []);

  const handleRadChange = (event) => {
    if (event.target.value === 'no') {
      formikRef.current.setFieldValue('emailNotify', false);
    }
    if (event.target.value === 'yes') {
      formikRef.current.setFieldValue('emailNotify', true);
    }
    formikRef.current.setFieldValue('isNotify', event.target.value, false);
  };

  useEffect(() => {
    SetOptions2(taskarray && taskarray.map((task1) => (
      { name: task1.name, value: task1.id, category: task1.category.name }
    )));
  }, [taskarray]);

  useEffect(() => {
    if (taskData.id && formikRef.current) {
      formikRef.current.setFieldValue('title', taskData.title ? taskData.title : '', false);
      formikRef.current.setFieldValue('typeName', taskData.typeName ? taskData.typeName : '', false);
      handletypeNameChange(taskData.typeName);
      formikRef.current.setFieldValue('type', taskData.type ? taskData.type.id : '', false);
      SetTask(taskData.type ? { name: taskData.type.name, value: taskData.type.id } : {});
      SetUserval(taskData.user ? { name: taskData.user.fname, userid: taskData.user.id } : {});
      formikRef.current.setFieldValue('user', taskData.user ? taskData.user.id : '', false);
      formikRef.current.setFieldValue('deadline', taskData.deadline ? new Date(taskData.deadline) : new Date(), false);
    }
  }, [taskData]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        title: '',
        typeName: '',
        type: '',
        user: '',
        deadline: new Date(),
        status: 'pending',
        isNotify: 'no',
        emailNotify: true,
        phoneNotify: false
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255).required('Title is required'),
        typeName: Yup.string().max(255).required('type Name is required'),
        type: Yup.string().max(255).required('Type is required'),
        user: Yup.string().max(255).required('User is required'),
        deadline: Yup.date().required('Deadline is required')
          .test('testTime', 'Please enter future time.', (date) => {
            // your logic
            const currentTime = new Date().getTime();
            // console.log(date.getTime(), currentTime);
            if (date.getTime() > currentTime) {
              // errors.deadline = 'please select only future date and time.';
              return date;
            }
            return false;
          })
      })}
      onSubmit={((values, { setSubmitting }) => {
        const postData = {
          title: values.title,
          typeName: values.typeName,
          type: values.type,
          user: values.user,
          deadline: values.deadline,
          status: values.status,
          isNotify: values.isNotify,
          phoneNotify: values.isNotify === 'yes' ? values.phoneNotify : false,
          emailNotify: values.isNotify === 'yes' ? values.emailNotify : false
        };
        // const response = await axios.get(
        //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
        // );
        if (taskId) {
          axios.patch(`${process.env.REACT_APP_API_BASE_URL}/tasks/update/${taskId}`, postData, options)
            .then(async (res) => {
              // setReportList(res.data.dsreports);
              // console.log(res.data);
              setSubmitting(false);
              toast.success(`${res.data.message}`, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              navigate('/app/tasks', { replace: true });
            })
            .catch((error) => {
              console.log(error);
              setSubmitting(false);
              toast.error(`${error.response.data.message}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        } else {
          axios.post(`${process.env.REACT_APP_API_BASE_URL}/tasks/create`, postData, options)
            .then(async (res) => {
              // setReportList(res.data.dsreports);
              // console.log(res.data);
              setSubmitting(false);
              toast.success(`${res.data.message}`, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              navigate('/app/tasks', { replace: true });
            })
            .catch((error) => {
              console.log(error);
              setSubmitting(false);
              toast.error(`${error.response.data.message}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        }
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        setFieldValue,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader="Enter your task details."
              title="Assign the task here."
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                  container
                  // justify="space-around"
                >
                  <TextField
                    error={Boolean(touched.title && errors.title)}
                    fullWidth
                    helperText={touched.title && errors.title}
                    label="Task Title"
                    name="title"
                    onChange={handleChange}
                    required
                    type="text"
                    onBlur={handleBlur}
                    value={values.title}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  {/* <Autocomplete
                    id="combo-box-demo"
                    options={trainingTypeArr}
                    getOptionLabel={(option) => option.title}
                    // onChange={handleChange}
                    onChange={(event) => {
                      setFieldValue('typeName', event.target.value);
                    }}
                    onInputChange={(event) => {
                      setFieldValue('typeName', event.target.value);
                    }}
                    // style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.typeName && errors.typeName)}
                        helperText={touched.typeName && errors.typeName}
                        fullWidth
                        label="Task Type"
                        name="typeName"
                        required
                        value={values.typeName}
                        variant="outlined"
                      />
                    )}
                  /> */}
                  <TextField
                    error={Boolean(touched.typeName && errors.typeName)}
                    helperText={touched.typeName && errors.typeName}
                    fullWidth
                    label="Select task type"
                    name="typeName"
                    // onClick={handleCountryClick}
                    onChange={(event) => { handletypeNameChange(event.target.value); }}
                    onBlur={handleBlur}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.typeName}
                    variant="outlined"
                  >
                    <option value="" key="" />
                    {trainingTypeArr.map((trainingType) => (
                      <option
                        key={trainingType.title}
                        value={trainingType.title}
                      >
                        {trainingType.title}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                {/* <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                    fullWidth
                    label="Select task"
                    name="type"
                    // onClick={handleCountryClick}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.type}
                    variant="outlined"
                  >
                    <option value="" key="" />
                    {taskarray && taskarray.map((task) => (
                      <option
                        key={task.id}
                        value={task.id}
                      >
                        {task.name}
                      </option>
                    ))}
                  </TextField>
                </Grid> */}

                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Autocomplete
                    id="task-drop-down"
                    name="type"
                    fullWidth
                    // onChange={handleChange}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        values.type = newValue.value;
                        SetTask(newValue);
                      } else {
                        values.type = '';
                      }
                      // console.log(values.type);
                    }}
                    onBlur={handleBlur}
                    autoHighlight
                    value={task}
                    options={options2}
                    groupBy={(option) => (<i><b>{option.category.toUpperCase()}</b></i>)}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option) => option.name || ''}
                    renderInput={(params) => (
                      <TextField
                        error={Boolean(touched.type && errors.type)}
                        helperText={touched.type && errors.type}
                        {...params}
                        label="Select Task"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                {/* <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.user && errors.user)}
                    helperText={touched.user && errors.user}
                    fullWidth
                    label="Select User"
                    name="user"
                    // onClick={handleCountryClick}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.user}
                    variant="outlined"
                  >
                    <option value="" key="" />
                    {usersArray.map((user) => (
                      <option
                        key={user.email}
                        value={user.id}
                      >
                        {user.fname}
                      </option>
                    ))}
                  </TextField>
                </Grid> */}
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Autocomplete
                    id="user-drop-down"
                    name="user"
                    fullWidth
                    onChange={(event, newValue) => {
                      if (newValue) {
                        values.user = newValue.userid;
                        SetUserval(newValue);
                      } else {
                        values.user = '';
                        SetUserval({});
                      }
                      // console.log(values.type);
                    }}
                    onBlur={handleBlur}
                    autoHighlight
                    value={userval}
                    options={usersArray.map((user) => (
                      { name: user.fname, userid: user.id }
                    ))}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option) => option.name || ''}
                    renderInput={(params) => (
                      <TextField
                        error={Boolean(touched.user && errors.user)}
                        helperText={touched.user && errors.user}
                        {...params}
                        label="Select User"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  {/* <TextField
                    error={Boolean(touched.deadline && errors.deadline)}
                    helperText={touched.deadline && errors.deadline}
                    fullWidth
                    id="task_deadline"
                    label="Task Deadline"
                    type="datetime-local"
                    defaultValue="2017-05-24T10:30"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.deadline}
                    variant="outlined"
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      disablePast
                      onChange={(date) => {
                        setFieldValue('deadline', date, errors.deadline);
                      }}
                      error={Boolean(touched.deadline && errors.deadline)}
                      helperText={touched.deadline && errors.deadline}
                      fullWidth
                      label="Task Deadline"
                      name="deadline"
                      required
                      value={values.deadline}
                      variant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.radioLabel}
                  >
                    Do you want to send the notification?
                  </Grid>
                  <FormControlLabel
                    checked={values.isNotify === 'no'}
                    value="no"
                    control={<Radio inputProps={{ 'aria-label': 'B' }} />}
                    label="No"
                    onChange={handleRadChange}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    checked={values.isNotify === 'yes'}
                    value="yes"
                    control={<GreenRadio inputProps={{ 'aria-label': 'C' }} />}
                    label="Yes"
                    onChange={handleRadChange}
                    labelPlacement="start"
                  />
                </Grid>
                {
                  values.isNotify && values.isNotify === 'yes'
                  && (
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <div className={classes.radioLabel}>
                        <FormControlLabel
                          control={<Checkbox checked={values.emailNotify} onChange={handleChange} name="emailNotify" />}
                          label="Email Notification"
                        />
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={values.phoneNotify}
                              onChange={handleChange}
                              name="phoneNotify"
                              color="primary"
                            />
                          )}
                          label="Phone Notification"
                        />
                      </div>
                    </Grid>
                  )
                }
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Grid
                item
                style={{ margin: '3px' }}
              >
                <Button
                  // color="secondary"
                  // style={{ backgroundColor: '#21b6ae' }}
                  variant="contained"
                  onClick={() => { navigate('/app/tasks'); }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                style={{ margin: '3px' }}
              >
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Save details
                </Button>
              </Grid>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

CreateForm.propTypes = {
  className: PropTypes.string,
  taskId: PropTypes.string
};

export default CreateForm;
